<div class="fab-buttons profile-settings-page">
  <fab *ngIf="!editMode" [title]="'profile.button_edit'" [icon]="'edit'" [style]="'group'" (click)="edit()"></fab>
  <fab *ngIf="editMode" [title]="'profile.button_save'" [icon]="'save'" [style]='getFabStyle()' (click)="save()"></fab>
</div>
<div class="desktop-width-limiter page-padding profile-settings-page">
  <profile-subnav [activeItem]="'profile-settings'"></profile-subnav>
  <mat-card class="widget" >
    <div class="settings-info">
      <h1 class="pageTitle">{{'profile_settings.my_selected_location' | translate}}</h1>
      <span *ngIf="!editMode">{{getLocationInfo()}}</span>
      <location-search-field [data]="userProfile.location" (locationChanged)="onCustomLocationChanged($event)" *ngIf="editMode"></location-search-field>
    </div>
    <div class="azure-map">
      <azure-map #mapelement>
      </azure-map>
    </div>
  </mat-card>
</div>
