import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { StreappEvent } from '../models/streappevent.model';
import { StreApperInfo } from '../models/streapper-info.model';
import { EventType } from '../modules/create-event/new-event-form/_models/eventtype.model';
import { SasResult } from '../models/sas-result.model';
import { AppConfigService } from './app-config.service';
import eventtypes from '../../assets/data/eventtypes.json';
import { makeStateKey, StateKey, TransferState } from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { Upload } from '../models/upload';
import { Observable, catchError, scan, throwError } from 'rxjs';
import {
  HttpEvent,
  HttpEventType,
  HttpResponse,
  HttpProgressEvent,
} from '@angular/common/http';

function isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
  return event.type === HttpEventType.Response;
}

function isHttpProgressEvent(
  event: HttpEvent<unknown>
): event is HttpProgressEvent {
  return (
    event.type === HttpEventType.DownloadProgress ||
    event.type === HttpEventType.UploadProgress
  );
}

import { Ad, Promo } from '../models/ad.model';
import { MediaType } from '../models/mediaitem.model';

export const LIVE_EVENTS = makeStateKey<Array<StreappEvent>>('live-events');
export const ADS = makeStateKey<Array<Ad>>('ads');
export const PROMOS = makeStateKey<Array<Promo>>('promos');

@Injectable({
  providedIn: 'root',
})
export class StreappEventsService {
  private isServer = false;

  private baseApiUrl: string;
  private baseAuthenticatedApiUrl: string;
  private functionsUrl: string;
  private selectedEvent: StreappEvent;

  constructor(
    private httpService: HttpClient,
    private configService: AppConfigService,
    private tstate: TransferState,
    @Inject(PLATFORM_ID) platformId: Object
  ) {
    this.baseApiUrl = this.configService.getConfig().baseApiUrl;
    this.functionsUrl = this.configService.getConfig().functionsUrl;
    this.baseAuthenticatedApiUrl = `${this.baseApiUrl}Auth/`;
    this.isServer = isPlatformServer(platformId);
  }

  getLiveEvents(
    key: StateKey<Array<StreappEvent>>,
    filter: string,
    token: string
  ): Promise<Array<StreappEvent>> {
    if (this.tstate.hasKey(key) && !token) {
      return new Promise((resolve, reject) => {
        const data = this.tstate.get<Array<StreappEvent>>(key, null);
        this.tstate.remove(key);
        resolve(data);
      });
    } else {
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };

      if (!!token) {
        httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: token,
          }),
        };
      }

      return new Promise((resolve, reject) => {
        this.httpService
          .get(
            `${
              !!token ? this.baseAuthenticatedApiUrl : this.baseApiUrl
            }Event/Live${!!filter ? '?filter=' + filter : ''}`,
            httpOptions
          )
          .subscribe(
            (result: Array<StreappEvent>) => {
              if (this.isServer) {
                this.tstate.set(key, result);
              }
              resolve(result);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
  }

  getMyEvents(token: string): Promise<Array<StreappEvent>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      }),
    };

    return new Promise((resolve, reject) => {
      this.httpService.get(`${this.baseApiUrl}Event/My`, httpOptions).subscribe(
        (result: Array<StreappEvent>) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getReporterEvents(id: string): Promise<Array<StreappEvent>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return new Promise((resolve, reject) => {
      this.httpService
        .get(`${this.baseApiUrl}Event/Reporter?id=${id}`, httpOptions)
        .subscribe(
          (result: Array<StreappEvent>) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getLocalEvents(
    filter: string,
    lat: number,
    long: number,
    radius: number,
    token: string
  ): Promise<Array<StreappEvent>> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    if (!!token) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: token,
        }),
      };
    }
    return new Promise((resolve, reject) => {
      this.httpService
        .get(
          `${
            !!token ? this.baseAuthenticatedApiUrl : this.baseApiUrl
          }Event/Local?filter=${filter}&longitude=${long}&latitude=${lat}&distanceInKm=${radius}`,
          httpOptions
        )
        .subscribe(
          (result: Array<StreappEvent>) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getMapEvents(
    filter: string,
    lat: number,
    long: number,
    radius: number,
    token: string
  ): Promise<Array<StreappEvent>> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    if (!!token) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: token,
        }),
      };
    }

    return new Promise((resolve, reject) => {
      this.httpService
        .get(
          `${
            !!token ? this.baseAuthenticatedApiUrl : this.baseApiUrl
          }Event/Map?filter=${filter}&longitude=${long}&latitude=${lat}&distanceInKm=${radius}`,
          httpOptions
        )
        .subscribe(
          (result: Array<StreappEvent>) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getEventsByUrl(
    url: string,
    filter: string,
    token: string,
    lat: number = 0,
    long: number = 0,
    radius: number = 10
  ): Promise<Array<StreappEvent>> {
    if (url.indexOf('lokaal') > -1) {
      return this.getLocalEvents(filter, lat, long, radius, token);
    } else if (url.indexOf('kaart') > -1) {
      return this.getMapEvents(filter, lat, long, radius, token);
    } else {
      return this.getLiveEvents(LIVE_EVENTS, filter, token);
    }
  }

  getEventById(id: string, token: string): Promise<StreappEvent> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    if (!!token) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: token,
        }),
      };
    }

    return new Promise((resolve, reject) => {
      this.httpService
        .get(
          `${
            !!token ? this.baseAuthenticatedApiUrl : this.baseApiUrl
          }Event/${id}`,
          httpOptions
        )
        .subscribe(
          (result: StreappEvent) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getNearbyEvents(
    longitute: number,
    latitude: number,
    token: string,
    distanceInMeters: number = 500
  ): Promise<Array<StreappEvent>> {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    if (!!token) {
      httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: token,
        }),
      };
    }
    return new Promise((resolve, reject) => {
      this.httpService
        .get(
          `${this.baseApiUrl}Event/NearbyEvents?longitude=${longitute}&latitude=${latitude}&distanceInMeters=${distanceInMeters}`,
          httpOptions
        )
        .subscribe(
          (result: Array<StreappEvent>) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getSimilarEvents(
    eventId: string,
    mainCategory: string,
    subCategory: string,
    longitute: number,
    latitude: number,
    distanceInMeters: number = 1000
  ): Promise<Array<StreappEvent>> {
    return new Promise((resolve, reject) => {
      this.httpService
        .get(
          `${this.baseApiUrl}Event/SimilarEvents?eventId=${eventId}&mainCategory=${mainCategory}&subCategory=${subCategory}&longitude=${longitute}&latitude=${latitude}&distanceInMeters=${distanceInMeters}`
        )
        .subscribe(
          (result: Array<StreappEvent>) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  setSelectedEvent(event: StreappEvent): void {
    this.selectedEvent = event;
  }

  getSelectedEvent(): StreappEvent {
    return this.selectedEvent;
  }

  createStreappEvent(formData, token): Promise<string> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });

      this.httpService
        .post(`${this.baseApiUrl}Event`, formData, {
          headers: headers,
          responseType: 'text',
        })
        .subscribe((result: string) => {
          resolve(result);
        }),
        (error) => {
          reject(error);
        };
    });
  }

  completeStreappEvent(formData, token): Promise<void> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });

      this.httpService
        .put(`${this.baseApiUrl}Event`, formData, {
          headers: headers,
          responseType: 'text',
        })
        .subscribe(() => {
          resolve();
        }),
        (error) => {
          reject(error);
        };
    });
  }

  initStreappEvent(formData, token): Promise<string> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });

      this.httpService
        .post(`${this.baseApiUrl}Event/Init`, formData, {
          headers: headers,
          responseType: 'text',
        })
        .subscribe((result: string) => {
          resolve(result);
        }),
        (error) => {
          reject(error);
        };
    });
  }

  createAd(formData, token): Promise<string> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });

      this.httpService
        .post(`${this.baseApiUrl}Ad`, formData, {
          headers: headers,
          responseType: 'text',
        })
        .subscribe((result: string) => {
          resolve(result);
        }),
        (error) => {
          reject(error);
        };
    });
  }

  createPromo(formData, token): Promise<string> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });

      this.httpService
        .post(`${this.baseApiUrl}Ad/Promo`, formData, {
          headers: headers,
          responseType: 'text',
        })
        .subscribe((result: string) => {
          resolve(result);
        }),
        (error) => {
          reject(error);
        };
    });
  }

  getAds(key: StateKey<any>): Promise<Array<any>> {
    if (this.tstate.hasKey(key)) {
      return new Promise((resolve, reject) => {
        const data = this.tstate.get<any>(key, null);
        this.tstate.remove(key);
        resolve(data);
      });
    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };

      return new Promise((resolve, reject) => {
        this.httpService.get(`${this.baseApiUrl}Ad`, httpOptions).subscribe(
          (result: Array<any>) => {
            if (this.isServer) {
              this.tstate.set(key, result);
            }
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
      });
    }
  }

  getMyAds(token: string): Promise<Array<any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      }),
    };

    return new Promise((resolve, reject) => {
      this.httpService.get(`${this.baseApiUrl}Ad/my`, httpOptions).subscribe(
        (result: Array<any>) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getPromos(key: StateKey<any>): Promise<Array<any>> {
    if (this.tstate.hasKey(key)) {
      return new Promise((resolve, reject) => {
        const data = this.tstate.get<any>(key, null);
        this.tstate.remove(key);
        resolve(data);
      });
    } else {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      };

      return new Promise((resolve, reject) => {
        this.httpService
          .get(`${this.baseApiUrl}Ad/Promo`, httpOptions)
          .subscribe(
            (result: Array<any>) => {
              if (this.isServer) {
                this.tstate.set(key, result);
              }
              resolve(result);
            },
            (error) => {
              reject(error);
            }
          );
      });
    }
  }

  getPromo(id: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return new Promise((resolve, reject) => {
      this.httpService
        .get(`${this.baseApiUrl}Ad/Promo/${id}`, httpOptions)
        .subscribe(
          (result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getMyPromos(token: string): Promise<Array<any>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      }),
    };

    return new Promise((resolve, reject) => {
      this.httpService
        .get(`${this.baseApiUrl}Ad/promo/my`, httpOptions)
        .subscribe(
          (result: Array<any>) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  checkIfAlreadySignedUp(id: string, token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });

      this.httpService
        .get(`${this.baseApiUrl}Ad/Promo/CheckSignup?id=${id}`, {
          headers: headers,
          responseType: 'text',
        })
        .subscribe((result: string) => {
          resolve(result.toLowerCase() == 'true');
        }),
        (error) => {
          reject(error);
        };
    });
  }

  cancelSignupPromo(id: string, token: string): Promise<string> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });

      this.httpService
        .post(
          `${this.baseApiUrl}Ad/Promo/CancelSignup?id=${id}`,
          {},
          { headers: headers, responseType: 'text' }
        )
        .subscribe((result: string) => {
          resolve(result);
        }),
        (error) => {
          reject(error);
        };
    });
  }

  signupPromo(id: string, token: string): Promise<string> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });

      this.httpService
        .post(
          `${this.baseApiUrl}Ad/Promo/Signup?id=${id}`,
          {},
          { headers: headers, responseType: 'text' }
        )
        .subscribe((result: string) => {
          resolve(result);
        }),
        (error) => {
          reject(error);
        };
    });
  }

  uploadVideo(
    formData,
    price,
    eventID,
    token,
    uploadRestriction
  ): Observable<Upload> {
    // Headers
    const headers = new HttpHeaders({
      Accept: 'text/plain',
      Authorization: token,
    });

    const initialState: Upload = { state: 'PENDING', progress: 0 };
    const calculateState = (
      upload: Upload,
      event: HttpEvent<unknown>
    ): Upload => {
      if (isHttpProgressEvent(event)) {
        return {
          progress: event.total
            ? Math.round((100 * event.loaded) / event.total)
            : upload.progress,
          state: 'IN_PROGRESS',
        };
      }
      if (isHttpResponse(event)) {
        return {
          progress: 100,
          body: event.body as string,
          state: 'DONE',
        };
      }
      return upload;
    };

    return this.httpService
      .post(
        `${this.functionsUrl}/api/UploadVideo?EventId=${eventID}&MediaItemPrice=${price}&UploadRestriction=${uploadRestriction}`,
        formData,
        {
          headers: headers,
          responseType: 'text',
          reportProgress: true,
          observe: 'events',
        }
      )
      .pipe(scan(calculateState, initialState));
  }

  uploadImage(
    formData,
    price,
    eventID,
    token,
    uploadRestriction
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        Accept: 'text/plain',
        Authorization: token,
      });

      try {
        this.httpService
          .post(
            `${this.functionsUrl}/api/UploadImage?EventId=${eventID}&MediaItemPrice=${price}&UploadRestriction=${uploadRestriction}`,
            formData,
            { headers: headers, responseType: 'text' }
          )
          .subscribe(
            (result: string) => {
              resolve(result);
            },
            (error) => {
              reject(error);
            }
          );
      } catch (error) {
        console.log('service error ' + error);
        reject(error);
      }
    });
  }

  uploadAdImage(formData, token): Promise<string> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        Accept: 'text/plain',
        Authorization: token,
      });

      this.httpService
        .post(`${this.functionsUrl}/api/UploadAdImage`, formData, {
          headers: headers,
          responseType: 'text',
        })
        .subscribe((result: string) => {
          resolve(result);
        }),
        (error) => {
          reject(error);
        };
    });
  }

  getUploadSasUrl(token: string, mediaType: MediaType, highImpact: boolean, streAppId: string, extension: string, shopOnly:boolean, price: string): Promise<SasResult> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        Accept: 'text/plain',
        Authorization: token,
      });

      this.httpService
        .get<SasResult>(`${this.baseApiUrl}api/upload/SAS?mediaType=${mediaType}&highImpact=${highImpact}&streAppId=${streAppId}&extension=${extension}&shopOnly=${shopOnly}&price=${price}`, {
          headers: headers,
        })
        .subscribe((result: SasResult) => {
          resolve(result);
        }),
        (error) => {
          reject(error);
        };
    });
  }

  updateEvent(eventId: string, formData, token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: token,
      });

      this.httpService
        .put(`${this.baseApiUrl}Event/update/${eventId}`, formData, {
          headers: headers,
          responseType: 'text',
        })
        .subscribe((result) => {
          resolve();
        }),
        (error) => {
          reject(error);
        };
    });
  }

  sendContact(formData): Promise<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return new Promise((resolve, reject) => {
      this.httpService
        .post(`${this.baseApiUrl}contact`, formData, httpOptions)
        .subscribe((result: string) => {
          resolve(result);
        }),
        (error) => {
          reject(error);
        };
    });
  }

  sendFeedback(formData): Promise<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return new Promise((resolve, reject) => {
      this.httpService
        .post(`${this.baseApiUrl}contact/feedback`, formData, httpOptions)
        .subscribe((result: string) => {
          resolve(result);
        }),
        (error) => {
          reject(error);
        };
    });
  }

  getStreapperInfo(email: string): Promise<StreApperInfo> {
    return new Promise((resolve, reject) => {
      this.httpService
        .get(`${this.baseApiUrl}account/info?email=${email}`)
        .subscribe(
          (result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getStreapperInfoId(id: string): Promise<StreApperInfo> {
    return new Promise((resolve, reject) => {
      this.httpService
        .get(`${this.baseApiUrl}account/info-id?id=${id}`)
        .subscribe(
          (result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getStreAppReporters(): Promise<StreApperInfo[]> {
    return new Promise((resolve, reject) => {
      this.httpService.get(`${this.baseApiUrl}account/reporters`).subscribe(
        (result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  getEventTypes(): Promise<EventType[]> {
    return new Promise((resolve) => {
      resolve(eventtypes);
    });
  }
}
