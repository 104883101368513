import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { StreappEvent } from '../../../models/streappevent.model';
import { HostListener } from '@angular/core';
import { StreAppDialogComponent } from 'src/app/components/streapp-dialog/streapp-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { BaseOverviewLoader } from '../overview-base/overview-base';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-overview-list',
  templateUrl: './overview-list.component.html',
  styleUrls: ['./overview-list.component.scss']
})
export class OverviewListComponent extends BaseOverviewLoader implements OnInit, OnDestroy, AfterViewInit {  
  currentWindowWidth: number = 1920;
  hasScrolled: boolean = false;
  pageTitle: string = 'Live';
  showLocationFeatures: boolean = false;
  
  @HostListener("window:scroll", []) onScroll(): void { 
    this.hasScrolled = window.pageYOffset > 0;
  }

  @HostListener("window:resize", []) onResize(): void { 
    this.currentWindowWidth = window.innerWidth;
  }

   async ngOnInit(): Promise<void> {
    if (isPlatformBrowser(this._platformId)) {
      this.currentWindowWidth = window.innerWidth;
    }
    
    if (this.router.url.indexOf('lokaal') > -1) {
      if (isPlatformBrowser(this._platformId)) {
        this.pageTitle = 'Lokaal';
        this.showLocationFeatures = true;
        this.checkForKnownLocation();
      }
    } else {
      this.pageTitle = 'Live';
      await this.loadAddsAndEvents();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      try {
        this.allEvents.forEach((event: StreappEvent, index) => {
          if (index % 5 === 0 && index > 0) {
            (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
          }
        });
      } catch (e) {
        console.error("ads", e);
      }
    }, 900);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  navigateToEvent(event: StreappEvent): void {
    this.dialog.open<StreAppDialogComponent, StreappEvent>(StreAppDialogComponent, {
      data: event
    }).afterClosed().pipe(takeUntil(this.destroy$)).subscribe((data: boolean) => {
      if (data) { 
        this.router.navigate(['/detail', event.partitionKey]);
      }
    });
  }

  loadItemsForMyLocation(): void {
    // Update map location and retrieve new events for this location
    this.mapLocation = {
      long: this.currentLocation.long,
      lat: this.currentLocation.lat
    };
    this.loadAddsAndEvents();
  }

  loadItemsForNewLocation(location: string): void {
    if (!!location) {
      // Update map location and retrieve new events for this location
      this.mapLocation = {
        long: +location.split(';')[0],
        lat: +location.split(';')[1]
      };
      this.loadAddsAndEvents();
    }
  }

}

