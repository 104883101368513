<div class="mobile-choice-dialog">
  <div class="title">
    <img height="50" src="./assets/images/streapp_met_r.svg" [title]="'general.home_navigation'| translate" class="nav-logo-name" [alt]="'app_title' | translate | titlecase">
  </div>
  <div class="description">
    <div class="image-links">
      <img (click)="openAppleStore()" src="./assets/images/mobile/Apple-store.png" [title]="'general.home_navigation'| translate" class="nav-logo-name" [alt]="'app_title' | translate | titlecase">
      <img (click)="openGoogleStore()" src="./assets/images/mobile/Google-store.png" [title]="'general.home_navigation'| translate" class="nav-logo-name" [alt]="'app_title' | translate | titlecase">
    </div>
    <div class="description-block">
      <div class="description-title">
        Beschikbaarheid
      </div>
      <div class="text">
        StreApp is te gebruiken als smartphone applicatie en momenteel alleen in Nederland beschikbaar. Ga naar de AppStore of Google Play om StreApp te installeren op jouw smartphone.
      </div>
    </div>
    <div class="description-block">
      <div class="description-title">
        Devices
      </div>
      <div class="text">
        StreApp is ook als website beschikbaar op desktop en laptop. Onze website (www.streapp.com) is op de smartphone echter minder goed te gebruiken; installeer daarom onze app. Wil je StreApp toch via de website bekijken op jouw smartphone klik dan op onderstaande button:
      </div>
      <div class="continue-button">
        <button class="proceed-button" mat-raised-button [title]="'Naar website'" (click)="continueToSite()">
          Naar website
        </button>
      </div>
    </div>
  </div>
</div>
