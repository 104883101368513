<div class="desktop-width-limiter newevent-page page-padding">
  <mat-stepper [linear]="true" orientation="horizontal" #stepper>
    <ng-template matStepperIcon="edit" let-index="index">
      {{ index + 1 }}
    </ng-template>
    <mat-step [completed]="preUploadedMediaItems.length > 0">
      <ng-template matStepLabel>{{
        "new_event.step_media_upload.label" | translate
      }}</ng-template>
      <h2 *ngIf="isReporter">
        {{ "new_event.step_media_upload.streapp_title" | translate }}
      </h2>
      <!-- File drop veld -->
      <div class="upload-field">
        <div
          class="image-description"
          *ngIf="!!linkedEvent"
          [innerHTML]="'new_event.step4.description_comment' | translate"
        ></div>
        <ngx-file-drop
          (onFileDrop)="dropped($event, 'none')"
          [multiple]="true"
          [accept]="'video/*,image/*'"
          class="size-m file-drop"
          [disabled]="preUploadedMediaItems.length >= 10"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
          >
            <div
              class="file-selector"
              *ngIf="preUploadedMediaItems.length < 10"
              (click)="openFileSelector()"
            >
              <span class="upload-field-text">{{
                "new_event.step4.dropzone_label" | translate
              }}</span>
            </div>
            <div
              class="max-files-selected"
              *ngIf="preUploadedMediaItems.length >= 10"
            >
              <span class="dz-btn">{{
                "new_event.step4.dropzone_full_label" | translate
              }}</span>
            </div>
          </ng-template>
        </ngx-file-drop>
        <!-- File counter with maximum of 10 files -->
        <div class="totalFilesUploaded">
          {{ preUploadedMediaItems.length }} / 10
        </div>
      </div>
      <div class="preview-content">
        <div class="uploadDescriptionField">
          {{ "new_event.step_media_upload.upload_description" | translate }}
        </div>
        <!-- Filepreview Holders -->
        <div class="preview-holder">
          <div
            class="preview"
            *ngFor="let item of numSequence(10); let i = index"
          >
            <ng-container *ngIf="preUploadedMediaItems[i]">
              <img
                class="preview-image-holder"
                [src]="preUploadedMediaItems[i].previewFile"
                onerror="this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='"
                alt="uploaded-image"
              />
              <div class="darkenImage"></div>
              <span
                class="remove-item"
                typeof="button"
                (click)="removeFile(i, 'none')"
                [title]="'general.remove' | translate | titlecase"
              >
                <mat-icon>delete</mat-icon>
              </span>
            </ng-container>
            <ng-container *ngIf="!preUploadedMediaItems[i]">
              <img
                class="preview-image-holder-empty"
                src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              />
            </ng-container>
          </div>
        </div>
      </div>
      <!-- Filepreview Holders Shop-only -->
      <ng-container *ngIf="isReporter">
        <h2 class="shop-only-content-title">
          {{ "new_event.step_media_upload.shop_only_title" | translate }}
        </h2>
        <!-- File drop veld -->
        <div class="upload-field">
          <div
            class="image-description"
            *ngIf="!!linkedEvent"
            [innerHTML]="'new_event.step4.description_comment' | translate"
          ></div>
          <ngx-file-drop
            (onFileDrop)="dropped($event, 'shop')"
            [multiple]="true"
            [accept]="'video/*,image/*'"
            class="size-m file-drop"
            [disabled]="preUploadedShopMediaItems.length >= 10"
          >
            <ng-template
              ngx-file-drop-content-tmp
              let-openFileSelector="openFileSelector"
            >
              <div
                class="file-selector"
                *ngIf="preUploadedShopMediaItems.length < 10"
                (click)="openFileSelector()"
              >
                <span class="upload-field-text">{{
                  "new_event.step4.dropzone_label" | translate
                }}</span>
              </div>
              <div
                class="max-files-selected"
                *ngIf="preUploadedShopMediaItems.length >= 10"
              >
                <span class="dz-btn">{{
                  "new_event.step4.dropzone_full_label" | translate
                }}</span>
              </div>
            </ng-template>
          </ngx-file-drop>
          <!-- File counter with maximum of 10 files -->
          <div class="totalFilesUploaded">
            {{ preUploadedShopMediaItems.length }} / 10
          </div>
        </div>
        <div class="preview-content">
          <div class="uploadDescriptionField">
            {{
              "new_event.step_media_upload.upload_description_shop" | translate
            }}
          </div>
          <div class="preview-holder">
            <div
              class="preview"
              *ngFor="let item of numSequence(10); let i = index"
            >
              <ng-container *ngIf="preUploadedShopMediaItems[i]">
                <img
                  class="preview-image-holder"
                  [src]="preUploadedShopMediaItems[i].previewFile"
                  onerror="this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='"
                  alt="uploaded-image"
                />
                <div class="darkenImage"></div>
                <span
                  class="remove-item"
                  typeof="button"
                  (click)="removeFile(i, 'shop')"
                  [title]="'general.remove' | translate | titlecase"
                >
                  <mat-icon>delete</mat-icon>
                </span>
              </ng-container>
              <ng-container *ngIf="!preUploadedShopMediaItems[i]">
                <img
                  class="preview-image-holder-empty"
                  src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                />
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="button-actions">
        <button
          class="primary-button nextStep"
          mat-raised-button
          matStepperNext
          [disabled]="preUploadedMediaItems.length == 0"
        >
          {{ "general.next" | translate | titlecase }}
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </mat-step>
    <mat-step [completed]="!!selectedLatLng">
      <ng-template matStepLabel>{{
        "new_event.step3.label" | translate
      }}</ng-template>

      <div class="step3">
        <div class="location-selector size-m">
          <location-selector-azure
            (mapClicked)="setLocation($event)"
          ></location-selector-azure>
        </div>

        <div class="button-actions">
          <button class="previousStep" mat-stroked-button matStepperPrevious>
            <mat-icon>keyboard_arrow_left</mat-icon>
            {{ "general.previous" | translate | titlecase }}
          </button>
          <button
            class="primary-button nextStep"
            mat-raised-button
            (click)="checkSimilarEvents()"
            [disabled]="!selectedLatLng"
          >
            {{ "general.next" | translate | titlecase }}
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
    </mat-step>
    <mat-step [completed]="!!selectedEvent">
      <ng-template matStepLabel>{{
        "new_event.step1.label" | translate
      }}</ng-template>

      <div class="input-select-type">
        <ng-container *ngFor="let eventType of availableEventTypes">
          <event-type-selector
            [eventType]="eventType"
            [selected]="eventType === selectedEvent"
            (selectedEventType)="selectEventType($event)"
          ></event-type-selector>
        </ng-container>
      </div>
      <div class="button-actions">
        <button class="previousStep" mat-stroked-button matStepperPrevious>
          <mat-icon>keyboard_arrow_left</mat-icon>
          {{ "general.previous" | translate | titlecase }}
        </button>
      </div>
    </mat-step>
    <mat-step [completed]="!!selectedSubEvent">
      <ng-template matStepLabel>{{
        "new_event.step2.label" | translate
      }}</ng-template>

      <div class="input-select-type" *ngIf="!!selectedEvent">
        <ng-container *ngFor="let eventType of selectedEvent.children">
          <event-type-selector
            [eventType]="eventType"
            [selected]="eventType === selectedSubEvent"
            (selectedEventType)="selectSubEventType($event)"
          ></event-type-selector>
        </ng-container>
      </div>
      <div class="button-actions step3">
        <button class="previousStep" mat-stroked-button matStepperPrevious>
          <mat-icon>keyboard_arrow_left</mat-icon>
          {{ "general.previous" | translate | titlecase }}
        </button>
      </div>
    </mat-step>
    <ng-container *ngIf="isReporter">
      <mat-step>
        <ng-template matStepLabel>{{
          "new_event.step_price_select.label" | translate
        }}</ng-template>
        <!-- All uploaded files with prices/suggested prices -->
        <h2 class="price-title">
          {{ "new_event.step_media_upload.streapp_title" | translate }}
        </h2>
        <div class="price-suggestion-holder" *ngIf="!!selectedSubEvent">
          <div
            class="price-suggestion-item"
            *ngFor="let item of preUploadedMediaItems; let i = index"
            [ngStyle]="{ 'background-image': 'url(' + item.previewFile + ')' }"
            (click)="openPriceSelectionWindow(i, 'none')"
          >
            <button
              class="price-suggestion-button"
              type="button"
              mat-raised-button
              (click)="openPriceSelectionWindow(i, 'none')"
            >
              <span *ngIf="!item.priceChanged"
                >{{
                  "new_event.step_price_select.suggestion_label" | translate
                }}
                {{ item.suggestedPrice }} <b>€</b></span
              >
              <span *ngIf="item.priceChanged"> {{ item.price }} <b>€</b></span>
            </button>
          </div>
          <div class="advice-description">
            {{ "new_event.step_price_select.advice_description" | translate }}
          </div>
        </div>
        <ng-container *ngIf="preUploadedShopMediaItems.length > 0">
          <h2 class="price-title shop-only-content-title">
            {{ "new_event.step_media_upload.shop_only_title" | translate }}
          </h2>
          <div class="price-suggestion-holder">
            <!-- Shop only items -->
            <div
              class="price-suggestion-item"
              *ngFor="let item of preUploadedShopMediaItems; let i = index"
              [ngStyle]="{
                'background-image': 'url(' + item.previewFile + ')'
              }"
              (click)="openPriceSelectionWindow(i, 'shop')"
            >
              <button
                class="price-suggestion-button"
                type="button"
                mat-raised-button
                (click)="openPriceSelectionWindow(i, 'shop')"
              >
                <span *ngIf="!item.priceChanged"
                  >{{
                    "new_event.step_price_select.suggestion_label" | translate
                  }}
                  {{ item.suggestedPrice }} <b>€</b></span
                >
                <span *ngIf="item.priceChanged">
                  {{ item.price }} <b>€</b></span
                >
              </button>
            </div>
            <div class="advice-description">
              {{ "new_event.step_price_select.advice_shop" | translate }}
            </div>
          </div>
        </ng-container>
        <div class="button-actions">
          <button class="previousStep" mat-stroked-button matStepperPrevious>
            <mat-icon>keyboard_arrow_left</mat-icon>
            {{ "general.previous" | translate | titlecase }}
          </button>
          <button
            class="primary-button nextStep confirm"
            mat-raised-button
            matStepperNext
            [disabled]="preUploadedMediaItems.length == 0"
          >
            {{ "general.confirm" | translate | titlecase }}
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </mat-step>
    </ng-container>
    <mat-step [stepControl]="streappFormGroup">
      <form [formGroup]="streappFormGroup">
        <ng-template matStepLabel>{{
          "new_event.step4.label" | translate
        }}</ng-template>
        <div class="step4">
          <ng-container *ngIf="!linkedEvent">
            <h2>{{ "new_event.step4.data_header" | translate }}</h2>
            <!-- Titel veld -->
            <mat-form-field appearance="outline" class="size-s">
              <mat-label>{{
                "new_event.step4.titel_label" | translate
              }}</mat-label>
              <input
                id="title"
                #titel
                matInput
                formControlName="titel"
                [placeholder]="'new_event.step4.titel_placeholder' | translate"
                required
              />
              <mat-hint align="end"
                >{{ titel.value?.length || 0 }} / 150</mat-hint
              >
              <mat-error>{{
                "new_event.step4.titel_validation" | translate
              }}</mat-error>
            </mat-form-field>
          </ng-container>

          <!-- Beschrijving veld -->
          <mat-form-field appearance="outline" class="size-m">
            <mat-label>{{
              "new_event.step4.description_label" | translate
            }}</mat-label>
            <textarea
              id="description"
              #description
              required
              maxlength="5000"
              autosize
              rows="3"
              minRows="3"
              maxRows="10"
              formControlName="beschrijving"
              matInput
              [placeholder]="
                'new_event.step4.description_placeholder' | translate
              "
            ></textarea>
            <mat-hint align="end"
              >{{ description.value?.length || 0 }} / 5000</mat-hint
            >
            <mat-error>{{
              "new_event.step4.description_validation" | translate
            }}</mat-error>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            class="size-s"
            *ngIf="!linkedEvent"
          >
            <mat-label>{{
              "new_event.step4.date_label" | translate
            }}</mat-label>
            <input
              [min]="minDate"
              [max]="maxDate"
              type="datetime-local"
              matInput
              name="datumTijd"
              formControlName="datumTijd"
              required
            />
            <mat-error>{{
              "new_event.step4.date_validation" | translate
            }}</mat-error>
          </mat-form-field>

          <!-- Tags veld -->
          <mat-form-field
            appearance="outline"
            class="size-s"
            *ngIf="!linkedEvent"
          >
            <mat-label>{{
              "new_event.step4.tags_label" | translate
            }}</mat-label>
            <input
              id="tags"
              #tags
              matInput
              formControlName="tags"
              [placeholder]="'new_event.step4.tags_placeholder' | translate"
            />
            <mat-hint align="end">{{ tags.value?.length || 0 }} / 200</mat-hint>
            <mat-error>{{
              "new_event.step4.tags_validation" | translate
            }}</mat-error>
          </mat-form-field>

          <h2>{{ "new_event.step4.heftigEvent_header" | translate }}</h2>
          <mat-form-field appearance="outline" class="size-xs">
            <mat-select
              id="adultContent"
              formControlName="heftigEvent"
              [placeholder]="
                'new_event.step4.heftigEvent_placeholder' | translate
              "
              required
            >
              <mat-option [value]="'ja'"> Ja </mat-option>
              <mat-option [value]="'nee'"> Nee </mat-option>
            </mat-select>
            <mat-error>{{
              "new_event.step4.heftigEvent_validation" | translate
            }}</mat-error>
          </mat-form-field>

          <ng-container *ngIf="showAdditionalQuestions">
            <h2>{{ "new_event.step4.gewonden_header" | translate }}</h2>
            <mat-form-field appearance="outline" class="size-xs">
              <mat-select
                id="wounded"
                formControlName="gewonden"
                (selectionChange)="sendWoundedData($event.value)"
                [placeholder]="
                  'new_event.step4.gewonden_placeholder' | translate
                "
                required
              >
                <mat-option [value]="'ja'"> Ja </mat-option>
                <mat-option [value]="'nee'"> Nee </mat-option>
                <mat-option [value]="'onbekend'"> Weet ik niet </mat-option>
              </mat-select>
              <mat-error>{{
                "new_event.step4.gewonden_validation" | translate
              }}</mat-error>
            </mat-form-field>

            <h2>
              {{ "new_event.step4.hulpdiensten_header" | translate }}
              <button
                type="button"
                mat-icon-button
                color="primary"
                [matTooltip]="'new_event.step4.hulpdiensten_help' | translate"
                matTooltipPosition="above"
              >
                <mat-icon>info_outline</mat-icon>
              </button>
            </h2>
            <mat-form-field appearance="outline" class="size-xs">
              <mat-select
                id="emergencyServices"
                formControlName="hulpdiensten"
                (selectionChange)="sendEmergencyServicesData($event.value)"
                [placeholder]="
                  'new_event.step4.hulpdiensten_placeholder' | translate
                "
                required
              >
                <mat-option [value]="'ja'"> Ja </mat-option>
                <mat-option [value]="'nee'"> Nee </mat-option>
              </mat-select>
              <mat-error>{{
                "new_event.step4.hulpdiensten_validation" | translate
              }}</mat-error>
            </mat-form-field>
            <h3
              class="warning-hulpdiensten"
              *ngIf="streappFormGroup.get('hulpdiensten')?.value === 'nee'"
            >
              {{ "new_event.step4.hulpdiensten_warning" | translate }}
            </h3>
          </ng-container>
          <div class="button-actions">
            <button class="previousStep" mat-stroked-button matStepperPrevious>
              <mat-icon>keyboard_arrow_left</mat-icon>
              {{ "general.previous" | translate | titlecase }}
            </button>
            <button
              class="primary-button nextStep"
              mat-raised-button
              matStepperNext
              [disabled]="!streappFormGroup.valid && finishedUploading"
            >
              {{ "general.next" | translate | titlecase }}
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{
        "new_event.step5.label" | translate
      }}</ng-template>
      <div class="step5">
        <div class="linked-event" *ngIf="!!linkedEvent">
          <span class="linked-label">{{
            "new_event.step5.linked_event_label" | translate
          }}</span>
          <overview-card
            [event]="linkedEvent"
            [navigateToEventOnClick]="false"
          ></overview-card>
        </div>
        <div class="row" *ngIf="!linkedEvent">
          <div class="col-2 label">
            {{ "new_event.step4.titel_label" | translate }}:
          </div>
          <div class="col-10">{{ streappFormGroup.get("titel")?.value }}</div>
        </div>
        <div class="row">
          <div class="col-2 label">
            {{ "new_event.step4.description_label" | translate }}:
          </div>
          <div class="col-10">
            {{ streappFormGroup.get("beschrijving")?.value }}
          </div>
        </div>
        <div class="row">
          <div class="col-2 label">
            {{ "new_event.step4.selected_file" | translate }}:
          </div>
          <div class="col-10">
            <div class="files-selected-summary">
              <div
                class="single-file"
                *ngFor="let item of preUploadedMediaItems; let i = index"
              >
                <span class="upload-item-label">{{
                  item.file.relativePath
                }}</span>
                <mat-spinner
                  [diameter]="25"
                  [mode]="'determinate'"
                  [value]="item.progress"
                  *ngIf="!!item.progress && !item.finished"
                >
                </mat-spinner>
                <mat-icon class="error" *ngIf="item.hasError"
                  >report_problem
                </mat-icon>
                <mat-icon *ngIf="item.finished">
                  check_circle_outline
                </mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          *ngIf="isReporter && preUploadedShopMediaItems.length > 0"
        >
          <div class="col-2 label">
            {{ "new_event.step4.selected_file_shop_only" | translate }}:
          </div>
          <div class="col-10">
            <div class="files-selected-summary">
              <div
                class="single-file"
                *ngFor="let item of preUploadedShopMediaItems; let i = index"
              >
                <span class="upload-item-label">{{
                  item.file.relativePath
                }}</span>
                <mat-spinner
                  [diameter]="25"
                  [mode]="'determinate'"
                  [value]="item.progress"
                  *ngIf="!!item.progress && !item.finished"
                >
                </mat-spinner>
                <mat-icon class="error" *ngIf="item.hasError">
                  report_problem
                </mat-icon>
                <mat-icon *ngIf="item.finished">
                  check_circle_outline
                </mat-icon>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="!linkedEvent">
          <div class="col-2 label">
            {{ "new_event.step4.date_label" | translate }}:
          </div>
          <div class="col-10">
            {{ streappFormGroup.get("datumTijd")?.value | dateToDescription }}
          </div>
        </div>
        <div class="row" *ngIf="!linkedEvent">
          <div class="col-2 label">
            {{ "new_event.step4.tags_label" | translate }}:
          </div>
          <div class="col-10">{{ streappFormGroup.get("tags")?.value }}</div>
        </div>
        <div class="row" *ngIf="!linkedEvent">
          <div class="col-2 label">
            {{ "new_event.step3.location_label" | translate }}:
          </div>
          <div class="col-10">
            {{ streappFormGroup.get("location")?.value }}
          </div>
        </div>
        <div class="row">
          <div class="col-2 label">
            {{ "new_event.step4.heftigEvent_header" | translate }}:
          </div>
          <div class="col-10">
            {{ streappFormGroup.get("heftigEvent")?.value }}
          </div>
        </div>
        <ng-container *ngIf="showAdditionalQuestions">
          <div class="row">
            <div class="col-2 label">
              {{ "new_event.step4.gewonden_header" | translate }}:
            </div>
            <div class="col-10">
              {{ streappFormGroup.get("gewonden")?.value }}
            </div>
          </div>
          <div class="row">
            <div class="col-2 label">
              {{ "new_event.step4.hulpdiensten_header" | translate }}:
            </div>
            <div class="col-10">
              {{ streappFormGroup.get("hulpdiensten")?.value }}
            </div>
          </div>
        </ng-container>

        <div class="button-actions">
          <button
            class="previousStep"
            mat-stroked-button
            matStepperPrevious
            *ngIf="!publishing"
          >
            <mat-icon>keyboard_arrow_left</mat-icon>
            {{ "general.previous" | translate | titlecase }}
          </button>
          <button
            id="submit"
            mat-raised-button
            (click)="createEvent()"
            class="primary-button"
            [disabled]="!streappFormGroup.valid || publishing"
            *ngIf="!publishing"
          >
            {{ "new_event.step5.button_publish" | translate | titlecase }}
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
          <span *ngIf="publishing" class="publishing-text">
            <mat-progress-spinner
              class="loading-spinner"
              [diameter]="30"
              mode="indeterminate"
            ></mat-progress-spinner>
            <span
              >{{ "new_event.step5.uploading" | translate }}
              {{ getProgress() }}</span
            >
          </span>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
