export class TypeCheckingUtility {
  public static isString(value: any): boolean {
    return typeof value === 'string';
  }

  public static isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  public static isObject(value: any): boolean {
    return typeof value === 'object';
  }
}
