import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  isMobileView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isTabletView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isDesktopView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  

  constructor(private readonly breakpointObserver: BreakpointObserver,) {
    this.breakpointObserver
      .observe(['(max-width: 767px)'])
      .subscribe((state: BreakpointState) => {
        this.isMobileView.next(state.matches);
      });

    this.breakpointObserver
      .observe(['(min-width: 768px) and (max-width: 1199px)'])
      .subscribe((state: BreakpointState) => {
        this.isTabletView.next(state.matches);
      });

    this.breakpointObserver
      .observe(['(min-width: 1200px)'])
      .subscribe((state: BreakpointState) => {
        this.isDesktopView.next(state.matches);
      });
  }

  get isAndroidDevice(): boolean {
    return /Android|webOS|BlackBerry|IEMobile/i.test(navigator.userAgent);
  }

}
