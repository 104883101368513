import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'add-content-button',
  templateUrl: './add-content-button.component.html',
  styleUrls: ['./add-content-button.component.scss'],
})
export class AddContentButtonComponent implements OnInit {
  @Input() eventId: string;
  @Input() userId: string;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {}

  openAddContentPage(): void {
    this.router.navigate(['/add-content', this.eventId]);
  }
}
