import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { P2000Melding } from '../../../models/p2000.model';
import { AuthService } from '../../../services/auth.service';
import { HelpBoxService } from '../../../services/help-box.service';
import { P2000Service } from '../../../services/p2000.service';

@Component({
  selector: 'app-p2000-detail-page',
  templateUrl: './p2000-detail-page.component.html',
  styleUrls: ['./p2000-detail-page.component.scss']
})
export class P2000DetailPageComponent implements OnInit {
  p2000Melding: P2000Melding;
  loggedIn: boolean;

  constructor(private readonly router: Router,
    private readonly authService: AuthService,
    private readonly helpBox: HelpBoxService,
    private readonly translate: TranslateService,
    private readonly p2000Service: P2000Service) { }

  ngOnInit(): void {

    this.p2000Melding = this.p2000Service.selectedItem;
    if (!this.p2000Melding) {
      // No item selected, possible refresh on this page
      // Redirect to 112-meldingen overview
      this.router.navigate(['112']);
      return;
    }

    this.loggedIn = this.authService.isAuthenticated();
  }

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  dialogOpen() {
    this.translate.get('help.new-event.disabled').subscribe(text => {
      this.helpBox.openBox(text);
    })
  }
}
