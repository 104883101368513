<div class="dialog">
    <h2 mat-dialog-title class="header">
      <span class="title">{{ 'contact.title' | translate }}</span>
      <button class="close-ref" mat-icon-button (click)="close()" [title]="'general.close' | translate | titlecase">
        <mat-icon>clear</mat-icon>
      </button>
    </h2>
    <div mat-dialog-content>
        <div class="content-title">
            <mat-icon>edit</mat-icon><h3>{{ 'contact.dialog_content_title' | translate }}</h3>
        </div>
        <div class="description">
            {{ 'contact.dialog_description' | translate }}
        </div>
        <form [formGroup]="contactFormGroup">
            <div class="userinfo">
                <mat-form-field [ngClass]="{'hidden': nameFilledOnInit}" appearance="outline" class="size-s">
                <mat-label>{{ "contact.name_label" | translate }}</mat-label>
                <input
                    #name
                    matInput
                    formControlName="name"
                    [placeholder]="'contact.name_placeholder' | translate"
                    required
                    maxlength="150"
                />
                <mat-hint align="end">{{ name.value?.length || 0 }} / 150</mat-hint>
                <mat-error>{{ "contact.name_validation" | translate }}</mat-error>
                </mat-form-field>
        
                <mat-form-field [ngClass]="{'hidden': emailFilledOnInit}" appearance="outline" class="size-s">
                <mat-label>{{ "contact.email_label" | translate }}</mat-label>
                <input
                    #email
                    matInput
                    formControlName="email"
                    [placeholder]="'contact.email_placeholder' | translate"
                    required
                />
                <mat-hint align="end">{{ email.value?.length || 0 }} / 150</mat-hint>
                <mat-error>{{ "contact.email_validation" | translate }}</mat-error>
                </mat-form-field>
            </div>
            <div class="message-field">
                <mat-form-field appearance="outline" class="size-m">
                <mat-label>{{ "contact.message_label" | translate }}</mat-label>
                <textarea
                    #message
                    required
                    maxlength="500"
                    autosize
                    rows="3"
                    minRows="3"
                    maxRows="10"
                    formControlName="message"
                    matInput
                    [placeholder]="'contact.message_placeholder' | translate"
                ></textarea>
                <mat-hint align="end"
                    >{{ message.value?.length || 0 }} / 500</mat-hint
                >
                <mat-error>{{ "contact.message_validation" | translate }}</mat-error>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div mat-dialog-actions class="dialog-buttons-end">
      <button class="close-ref cancel" mat-flat-button color="primary" [title]="'general.cancel' | translate | titlecase" (click)="close()">
        {{ 'general.cancel' | translate}}
      </button>
      <button class="close-ref confirm" mat-flat-button color="accent" [disabled]="!contactFormGroup.get('message').value" [title]="'contact.button_send' | translate | titlecase" (click)="sendContact()">
        {{ 'contact.button_send' | translate }}
      </button>
    </div>
</div>