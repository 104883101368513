import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { StreApperInfo } from 'src/app/models/streapper-info.model';
import { StreappEvent } from 'src/app/models/streappevent.model';
import { StreappEventsService } from 'src/app/services/streapp-events.service';
import { AuthService } from '../../../services/auth.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReportingDialogComponent } from 'src/app/components/reporting-button/reporting-dialog/reporting-dialog.component';
import { SimpleTextDialogComponent } from 'src/app/components/simple-text-dialog/simple-text-dialog.component';
import { SimpleTextDialogData } from 'src/app/components/simple-text-dialog/models/simple-text-dialog-data.model';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit, OnDestroy {
  loading: boolean = true;
  loadingEvents: boolean = true;
  error: boolean = false;
  errorText: string;
  profile: StreApperInfo;
  events: Array<StreappEvent> = [];
  userId: string;

  private readonly destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly streappService: StreappEventsService,
    private readonly route: ActivatedRoute,
    private readonly domSanitizer: DomSanitizer,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(takeUntil(this.destroy$))
      .subscribe((params) => {
        this.userId = params['id'];
        this.getStreapperInfoId(this.userId);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getStreapperInfoId(id: string) {
    this.streappService
      .getStreapperInfoId(id)
      .then((data: StreApperInfo) => {
        this.profile = data;
        this.streappService
          .getReporterEvents(id)
          .then((data: Array<StreappEvent>) => {
            this.events = data;
          })
          .catch((error) => {
            this.errorText = error;
          })
          .finally(() => {
            this.loadingEvents = false;
          });
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  getProfilePicture() {
    if (this.profile.picture) {
      if (this.profile.picture?.startsWith('http')) {
        return this.profile.picture;
      }

      return this.domSanitizer.bypassSecurityTrustUrl(
        'data:image/*;base64,' + this.profile.picture
      );
    }
  }

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }
}
