<ng-container *ngIf="!!data">
    <h2 mat-dialog-title class="primary">
        <div class="profile-box">
            <img *ngIf="reporter" class="profile-image" src="/assets/images/profile-dialog-reporter.png" />
            <img *ngIf="!reporter" class="profile-image" src="/assets/images/profile-dialog-streapper.png" />

            <img class="profile-photo" [src]="getProfilePicture()" />
            <button class="close-ref" mat-icon-button mat-dialog-close [title]="'general.close' | translate | titlecase">
                <mat-icon>clear</mat-icon>
            </button>
        </div>
        
    </h2>
    <div class="profile-content" mat-dialog-content>
        <div *ngIf="data.createdBy">
            <div> {{ 'profile-dialog.name' | translate }}  {{ name }}</div>
            <div> {{ 'profile-dialog.amount-streapps' | translate }}  {{ amount }}</div>
        </div>
        <div *ngIf="!data.createdBy">
            {{ 'profile-dialog.anonymous' | translate }}
        </div>
    </div>
</ng-container>
