<mat-toolbar>
  <button mat-button class="menu-item" [class.active]="activeItem === 'my'" routerLink="/my" title="{{ 'my.hover_title' | translate }}">
    <h1>{{ 'my.title' | translate }}</h1>
  </button>
  <button mat-button class="menu-item" [class.active]="activeItem === 'profile'" routerLink="/profile" title="{{ 'profile.hover_title' | translate }}">
    <h1>{{ 'profile.title' | translate }}</h1>
  </button>
  <button mat-button class="menu-item" [class.active]="activeItem === 'profile-settings'" routerLink="/profile-settings" title="{{ 'profile.profile_settings_label' | translate }}">
    <h1>{{ 'profile.profile_settings_label' | translate }}</h1>
  </button>
</mat-toolbar>
