import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { first } from 'rxjs/operators';
import { Ad } from 'src/app/models/ad.model';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';

@Component({
  selector: 'app-create-ad',
  templateUrl: './create-ad.component.html',
  styleUrls: ['./create-ad.component.scss']
})
export class CreateAdComponent implements OnInit {

  @ViewChild('stepper') matStepper: MatStepper;
  
  loading: boolean = true;
  errorText: string;
  selectedAdType: string = '';
  streappFormGroup: UntypedFormGroup;
  publishing: boolean = false;
  public files: NgxFileDropEntry[] = [];
  public pagefiles: NgxFileDropEntry[] = [];

  private selectedLatLng: any;

  constructor(private _formBuilder: UntypedFormBuilder,
    private readonly streappService: StreappEventsService,
    private readonly authService: AuthService,
    private readonly router: Router) { }

  ngOnInit(): void {
    this.streappFormGroup = this._formBuilder.group({});
  }

  selectAdType(adType: string): void {
    this.selectedAdType = adType;

    if (adType == 'ad' || adType == 'ad-silver'){
      this.streappFormGroup = this._formBuilder.group({
        file: ['', Validators.required],
        title: ['', [Validators.required, Validators.maxLength(150)]],
        description: ['', [Validators.required, Validators.maxLength(500)]],
        url: ['', [Validators.required, Validators.maxLength(1000)]],
        publishstart: ['', [Validators.required]],
        publishstarttime: [''],
        publishend: ['', [Validators.required]],
        publishendtime: [''],
        location: ['']
      });
    }else if (adType == 'promo'){
      this.streappFormGroup = this._formBuilder.group({
        file: ['', Validators.required],
        title: ['', [Validators.required, Validators.maxLength(150)]],
        description: ['', [Validators.required, Validators.maxLength(500)]],
        url: ['', [Validators.required, Validators.maxLength(1000)]],
        publishstart: ['', [Validators.required]],
        publishstarttime: [''],
        publishend: ['', [Validators.required]],
        publishendtime: [''],
        pagetitle: ['', [Validators.required, Validators.maxLength(150)]],
        pagedescription: ['', [Validators.required, Validators.maxLength(1500)]],
        pagebutton: ['', [Validators.required, Validators.maxLength(150)]],
        signup: [false],
        signupdescription: ['', [Validators.required, Validators.maxLength(1500)]],
        location: ['']
      });
    }
    this.matStepper.selected.completed = true;
    this.matStepper.next();
  }

  next() {
    this.matStepper.selected.completed = true;
    this.matStepper.next();
  }

  public dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        this.files.push(droppedFile);
        this.streappFormGroup.controls['file'].setValue(droppedFile.relativePath);
      } 
    }
  }

  removeFile(i: number): void {
    this.files.splice(i, 1);
    this.imageSource = '';
  }

  public droppedPage(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        this.pagefiles.push(droppedFile);
        this.streappFormGroup.controls['pagefile'].setValue(droppedFile.relativePath);
      } 
    }
  }

  removeFilePage(i: number): void {
    this.pagefiles.splice(i, 1);
  }

  get previewAd(): any {
    return {
      'title': this.streappFormGroup.get("title").value,
      'description': this.streappFormGroup.get("description").value,
      'url': this.streappFormGroup.get("url").value,
      'imageUrl': this.getImagePath()
    }
  }

  imageSource: any;
  get previewPromo(): any {
    var endtime = this.streappFormGroup.get("publishendtime").value.split(':');
    var enddate = new Date(this.streappFormGroup.get("publishend").value);
    enddate.setHours(endtime[0]);
    enddate.setMinutes(endtime[1]);

    return {
      'title': this.streappFormGroup.get("title").value,
      'description': this.streappFormGroup.get("description").value,
      'url': this.streappFormGroup.get("url").value,
      'imageUrl': this.getImagePath(),
      'publishEnd': enddate
    }
  }

  private getImagePath(): any {
    if(this.imageSource) {
      return this.imageSource;
    }
    if(this.files.length > 0) {

      var firstFile = this.files[0];
      const fileEntry = firstFile.fileEntry as FileSystemFileEntry;
      const reader = new FileReader();
      fileEntry.file((file: File) => {     
        reader.readAsDataURL(file);
            reader.onload = () => {
              this.imageSource = reader.result
              return this.imageSource;
            };
      });
    } else {
      return '';
    }
  }

  createAd() {
    this.publishing = true;

    var firstFile = this.files[0];
    const fileEntry = firstFile.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {     

      var starttime = this.streappFormGroup.get("publishstarttime").value.split(':');
      var startdate = new Date(this.streappFormGroup.get("publishstart").value);
      startdate.setHours(starttime[0]);
      startdate.setMinutes(starttime[1]);

      var endtime = this.streappFormGroup.get("publishendtime").value.split(':');
      var enddate = new Date(this.streappFormGroup.get("publishend").value);
      enddate.setHours(endtime[0]);
      enddate.setMinutes(endtime[1]);

      var adData: Ad = {
        'rowKey': this.streappFormGroup.get("title").value,
        'description': this.streappFormGroup.get("description").value,
        'publishStart': startdate,
        'publishEnd': enddate,
        'url': this.streappFormGroup.get("url").value,
        'latitude': this.selectedLatLng ? this.selectedLatLng[1] : 0,
        'longtitude': this.selectedLatLng ? this.selectedLatLng[0] : 0,
        'imageUrl': ''
      };

      const formData = new FormData();
      formData.append('imageStream', file, firstFile.relativePath);
      this.streappService.uploadAdImage(formData, this.authService.authorizationHeaderValue).then((data) => {
        adData.imageUrl = data;
        this.streappService.createAd(JSON.stringify(adData), this.authService.authorizationHeaderValue).then((eventID) => {
          this.publishing = false;
          this.router.navigate(['ads']);
        });
      }).catch((error) => {
          console.log(error);
        });
      });
  }

  createPromo() {
    this.publishing = true;

    var firstFile = this.files[0];
    const fileEntry = firstFile.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {     

      var starttime = this.streappFormGroup.get("publishstarttime").value.split(':');
      var startdate = new Date(this.streappFormGroup.get("publishstart").value);
      startdate.setHours(starttime[0]);
      startdate.setMinutes(starttime[1]);

      var endtime = this.streappFormGroup.get("publishendtime").value.split(':');
      var enddate = new Date(this.streappFormGroup.get("publishend").value);
      enddate.setHours(endtime[0]);
      enddate.setMinutes(endtime[1]);

      var promoData = {
        'RowKey': this.streappFormGroup.get("title").value,
        'Description': this.streappFormGroup.get("description").value,
        'PublishStart': startdate,
        'PublishEnd': enddate,
        'Url': this.streappFormGroup.get("url").value,
        'ImageUrl': '',
        'PageTitle': this.streappFormGroup.get("pagetitle").value,
        'PageDescription': this.streappFormGroup.get("pagedescription").value,
        'PageButton': this.streappFormGroup.get("pagebutton").value,
        'Signup': this.streappFormGroup.get("signup").value,
        'SignupDescription': this.streappFormGroup.get("signupdescription").value,
        'Latitude': this.selectedLatLng ? this.selectedLatLng[1] : 0,
        'Longtitude': this.selectedLatLng ? this.selectedLatLng[0] : 0,
        'PageImageUrl': ''
      };

      let formData = new FormData();
      formData.append('imageStream', file, firstFile.relativePath);
      this.streappService.uploadAdImage(formData, this.authService.authorizationHeaderValue).then((data) => {
        promoData.ImageUrl = data;

        var firstPageFile = this.pagefiles[0];
        const pageFileEntry = firstPageFile.fileEntry as FileSystemFileEntry;
        pageFileEntry.file((file: File) => {     
          formData = new FormData();
          formData.append('imageStream', file, firstPageFile.relativePath);
          this.streappService.uploadAdImage(formData, this.authService.authorizationHeaderValue).then((data) => {
            promoData.PageImageUrl = data;

            console.log(JSON.stringify(promoData));
            this.streappService.createPromo(JSON.stringify(promoData), this.authService.authorizationHeaderValue).then((eventID) => {
              this.publishing = false;
              this.router.navigate(['promos']);
            });
          });
        });
      }).catch((error) => {
          console.log(error);
        });
      });
  }

  setLocation(location: any) {
    this.streappFormGroup.get('location').setValue(`${location[1]} , ${location[0]}`);
    this.selectedLatLng = location;
  }

}
