<div
  class="desktop-width-limiter overview-page page-padding"
  [class.d-none]="isServer"
>
  <overview-filters
    (filterChanged)="loadStreappEvents($event)"
    (changeLocationClicked)="openSettingsDialog()"
    (myLocationClicked)="loadItemsForMyLocation()"
    (newLocationSelected)="loadItemsForNewLocation($event)"
    [showLocationSelector]="showLocationFeatures"
    [showSearch]="showLocationFeatures"
  ></overview-filters>
  <ng-container *ngIf="!loading && !errorText && allEvents.length > 0">
    <div class="row overview-items" *ngIf="!loading">
      <!-- Promo -->
      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 col-xxxl-4 overview-item-alternate"
        *ngIf="promo"
      >
        <promo-card [promo]="promo"></promo-card>
      </div>
      <ng-container *ngFor="let event of allEvents; let i = index">
        <!-- Ads -->
        <div
          class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 col-xxxl-2 overview-item-alternate"
          *ngIf="allAds.length > 0 && !!allAdIndex[i]"
        >
          <ad-card-v2 [ad]="allAdIndex[i]"></ad-card-v2>
        </div>
        <!-- Goolge Ads -->
         <div *ngIf="i % 5 === 0 && i > 0" class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 col-xxxl-2" style="align-content: center; max-height: 350px !important;">
          <ins class="adsbygoogle "
            style="display:block"
            data-ad-client="ca-pub-1807328328271021"
            data-ad-slot="1071743474"
            data-ad-format="rectangle, horizontal"
            data-full-width-responsive="true"></ins>
        </div>
        <!-- Streapps -->
        <div
          class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 col-xxxl-2 overview-item-alternate"
        >
          <overview-card-alternate-version
            [event]="event"
          ></overview-card-alternate-version>
        </div>
      </ng-container>
      <div class="noMoreEvents">
        <span class="noMoreEventsLabel" *ngIf="currentWindowWidth > 768">{{
          "page.noMoreEvents" | translate
        }}</span>
        <span
          class="noMoreEventsMobilePadding"
          *ngIf="currentWindowWidth <= 768"
        ></span>
      </div>
    </div>
    <scroll-up *ngIf="hasScrolled && currentWindowWidth > 768"></scroll-up>
  </ng-container>

  <ng-container *ngIf="!loading && !errorText && allEvents.length === 0">
    <div class="errorText" *ngIf="!showLocationFeatures">
      {{ "page.noVideos" | translate }}
    </div>
    <div class="errorText" *ngIf="showLocationFeatures">
      {{ "page.noVideosLocation" | translate }}
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <div class="loading">
      <mat-progress-spinner
        class="loading-spinner"
        [diameter]="250"
        mode="indeterminate"
        color="primary"
      >
      </mat-progress-spinner>
      <div class="loading-text"><h1>Starten...</h1></div>
    </div>
  </ng-container>

  <ng-container *ngIf="errorText">
    <div class="errorText">
      {{ "page.error" | translate }}
    </div>
  </ng-container>
</div>
