<div class="desktop-width-limiter overview-page page-padding" [class.d-none]="isServer">
  <div class="region-selector">
    <mat-form-field appearance="fill">
      <mat-label>{{'page.selectRegion' | translate}}</mat-label>
      <mat-select [(value)]="selectedRegion" (selectionChange)="loadNewRegion()">
        <mat-option>{{'page.selectRegion' | translate}}</mat-option>
        <mat-option value="Amsterdam-Amstelland">Amsterdam-Amstelland</mat-option>
        <mat-option value="Brabant-Zuidoost">Brabant-Zuidoost</mat-option>
        <mat-option value="Midden- en West Brabant">Midden- en West Brabant</mat-option>
        <mat-option value="Brabant-Noord">Brabant-Noord</mat-option>
        <mat-option value="Drenthe">Drenthe</mat-option>
        <mat-option value="Flevoland">Flevoland</mat-option>
        <mat-option value="Fryslân">Fryslân</mat-option>
        <mat-option value="Gelderland Midden">Gelderland Midden</mat-option>
        <mat-option value="Gelderland Zuid">Gelderland Zuid</mat-option>
        <mat-option value="Gooi en Vechtstreek">Gooi en Vechtstreek</mat-option>
        <mat-option value="Groningen">Groningen</mat-option>
        <mat-option value="Haaglanden">Haaglanden</mat-option>
        <mat-option value="Hollands Midden">Hollands Midden</mat-option>
        <mat-option value="IJsselland">IJsselland</mat-option>
        <mat-option value="Kennemerland">Kennemerland</mat-option>
        <mat-option value="Limburg-Noord">Limburg-Noord</mat-option>
        <mat-option value="Limburg-Zuid">Limburg-Zuid</mat-option>
        <mat-option value="Noord-Holland Noord">Noord-Holland Noord</mat-option>
        <mat-option value="Noord- en Oost-Gelderland">Noord- en Oost-Gelderland</mat-option>
        <mat-option value="Rotterdam-Rijnmond">Rotterdam-Rijnmond</mat-option>
        <mat-option value="Twente">Twente</mat-option>
        <mat-option value="Utrecht">Utrecht</mat-option>
        <mat-option value="Zaanstreek Waterland">Zaanstreek Waterland</mat-option>
        <mat-option value="Zeeland">Zeeland</mat-option>
        <mat-option value="Zuid-Holland Zuid">Zuid-Holland Zuid</mat-option>
      </mat-select>
    </mat-form-field>
  </div> 

  <ng-container *ngIf="!loading && !errorText && all112Events.length > 0">
    <div class="row overview-items">
      <ng-container *ngFor="let event of all112Events; let i = index">
        <div class="col-xs-12 col-sm-6 col-lg-6 col-xl-4 col-xxl-4 col-xxxl-3 overview-item" *ngIf="allAds.length > 0 && allAdIndex.includes(i)">
          <ad-card [ad]="getNextAd()"></ad-card>
        </div>
        <div class="col-xs-12 col-sm-6 col-lg-6 col-xl-4 col-xxl-4 col-xxxl-3 overview-item">
          <p2000-card [P2000Melding]="event"></p2000-card>
        </div>
      </ng-container>
      <div class="noMoreEvents">
        <span class="noMoreEventsLabel" *ngIf="!isMobileView">{{ 'page.noMore112Events' | translate }}</span>
        <span class="noMoreEventsMobilePadding" *ngIf="isMobileView"></span>
      </div>
    </div>
    <scroll-up *ngIf="hasScrolled && !isMobileView"></scroll-up>
  </ng-container>

  <ng-container *ngIf="!loading && !errorText && all112Events.length === 0">
    <div class="errorText" *ngIf="selectedRegion !== ''">
      {{'page.no112MessagesLocation' | translate}}
    </div>
    <div class="errorText" *ngIf="selectedRegion === ''">
      {{'page.no112RegionSelected' | translate}}
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <div class="loading">
      <mat-progress-spinner class="loading-spinner" [diameter]="250" mode="indeterminate" color="primary">
      </mat-progress-spinner>
      <div class="loading-text"><h1>Meldingen laden...</h1></div>
    </div>
  </ng-container>

  <ng-container *ngIf="errorText">
    <div class="errorText">
      {{'page.112error' | translate}}
    </div>
  </ng-container>
</div>
