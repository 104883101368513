import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';

@Component({
  selector: 'app-promo-overview',
  templateUrl: './promo-overview.component.html',
  styleUrls: ['./promo-overview.component.scss']
})
export class PromoOverviewComponent implements OnInit {

  myPromos: any;
  errorText: string;
  loading: boolean = true;

  constructor(private readonly streappService: StreappEventsService,
    private readonly authService: AuthService) { }

  ngOnInit(): void {
    this.streappService.getMyPromos(this.authService.authorizationHeaderValue).then((data) => {
      this.myPromos = data;
    }).catch((error) => {
      this.errorText = error;
    }).finally(() => {
      this.loading = false;
    });
  }

}
