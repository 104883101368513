import { Component, Input } from '@angular/core';

@Component({
  selector: 'profile-subnav',
  templateUrl: './profile-subnav.component.html',
  styleUrls: ['./profile-subnav.component.scss']
})
export class ProfileSubnavComponent {
  @Input() activeItem: string;

  constructor() { }
}
