<form [formGroup]="locationFormGroup">
  <div>
    <mat-form-field appearance="outline" class="">
      <mat-label>{{'location_settings.location_title' | translate}}</mat-label>
      <input #customLocation matInput formControlName="customLocation" [placeholder]="'location_settings.location_placeholder' | translate"
             [matAutocomplete]="auto">
    </mat-form-field>

    <mat-autocomplete #auto="matAutocomplete" [panelWidth]="'fit'" (optionSelected)="selectOption($event)" [displayWith]="displayFn.bind(this)">
      <mat-option *ngFor="let option of availableOptions" [value]="option.value">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
  </div>
</form>
