import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { DeviceCheckUtility, DeviceOS } from '../utilities/device-check';

@Injectable({
  providedIn: 'root'
})
export class HelpBoxService {

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector) {
  }

  boxHtml: string = '<div id="helpBox" class="helpBox"><div class="help-content">{0}</div></div>';
  boxHtmlWithApp: string = '<div id="helpBox" class="helpBox"><div class="help-content"><div class="text">{0}</div><div class="app-buttons">{1}</div></div></div>';

  openBox(html: string, showAppButtons: boolean = false) {
    var htmlElement = new DOMParser().parseFromString(this.boxHtml.replace('{0}', html), 'text/html').body.firstChild;

    if (showAppButtons) {
      if (DeviceCheckUtility.getMobileOperatingSystem() == DeviceOS.iOS ||
        DeviceCheckUtility.getMobileOperatingSystem() == DeviceOS.Android) {

        if (DeviceCheckUtility.getMobileOperatingSystem() == DeviceOS.iOS) {
          htmlElement = new DOMParser().parseFromString(this.boxHtmlWithApp.replace('{0}', html)
            .replace('{1}', '<a href="https://apps.apple.com/us/app/streapp/id1548648213" target="_about">Open de app</a>'), 'text/html').body.firstChild;

        }
        if (DeviceCheckUtility.getMobileOperatingSystem() == DeviceOS.Android) {
          htmlElement = new DOMParser().parseFromString(this.boxHtmlWithApp.replace('{0}', html)
            .replace('{1}', '<a href="https://play.google.com/store/apps/details?id=nl.streapp&gl=NL" target="_about">Open de app</a>'), 'text/html').body.firstChild;
        }
      }
    }

    document.body.append(htmlElement)

    document.onclick = function (event) {
      if (event.target == htmlElement) {
        document.body.removeChild(htmlElement);
      }
    }
  }

  openOpenApp(url: string, os: DeviceOS) {
    var htmlElement = new DOMParser().parseFromString(
      '<div id="appBox" class="appBox"></div>'
      , 'text/html').body.firstChild;

    var htmlElement2 = new DOMParser().parseFromString(
      '<div class="appBox-content"></div>'
      , 'text/html').body.firstChild;

    if (os == DeviceOS.Android) {
      var htmlElement3 = new DOMParser().parseFromString(
        '<a href="' + url + '">Open deze StreApp in de app</a>'
        , 'text/html').body;
      htmlElement2.appendChild(htmlElement3);

    } else if (os == DeviceOS.iOS) {
      var htmlElement3 = new DOMParser().parseFromString(
        '<a href="' + url + '">Open deze StreApp in de app</a>'
        , 'text/html').body;
      htmlElement3.onclick = function (event) {
        window.location.href = url;
        setTimeout(() => {
          window.location.href = 'https://apps.apple.com/us/app/streapp/id1548648213';
        }, 2000);
      };

      htmlElement2.appendChild(htmlElement3);
    }

    htmlElement.appendChild(htmlElement2);

    document.body.append(htmlElement)

    document.onclick = function (event) {
      if (event.target == htmlElement) {
        document.body.removeChild(htmlElement);
      }
    }
  }
}
