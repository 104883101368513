<div class="dialog settings">
  <h2 mat-dialog-title class="header">
    {{'location_required.title' | translate}}
    <button class="close-ref" mat-icon-button mat-dialog-close [title]="'general.close' | translate | titlecase">
      <mat-icon>clear</mat-icon>
    </button>
  </h2>
  <div mat-dialog-content>
    <div class="title">
      <span>{{'location_required.introduction' | translate }}</span>
      <span class="text2">{{'location_required.text_continued' | translate }}</span>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-buttons">
    <button class="close-ref" mat-flat-button mat-dialog-close [title]="'general.cancel' | translate">
      {{'general.cancel' | translate}}
    </button>
    <button class="close-ref" mat-flat-button [title]="'location_required.button_voerin' | translate"
            [color]="'primary'" (click)="save()">
      {{'location_required.button_voerin' | translate}}
    </button>
  </div>
</div>
