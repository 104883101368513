import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { StreappEventsService } from 'src/app/services/streapp-events.service';
import { Subject } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {
  videoUrl: string;
  thumbnailUrl: string;
  isReporter: boolean;;

  loading: boolean = true;
  error: boolean = false;

  private readonly destroy$: Subject<boolean> = new Subject();

  constructor(private readonly streappService: StreappEventsService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService) { }

  ngOnInit(): void {
    this.getEventIdAndLoadEvent();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getEventIdAndLoadEvent(): void {
    this.route.queryParams.pipe(
      takeUntil(this.destroy$)
    ).subscribe(params => {
      this.videoUrl = params['url'];
      this.thumbnailUrl = params['thumbnail'];
      this.isReporter = params['isReporter'] === 'True';
      this.loading = false;
    });
  }

}
