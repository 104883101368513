import { Pipe, PipeTransform } from '@angular/core';
import { TypeCheckingUtility } from '../utilities/type-checking-util';

@Pipe({
  name: 'secondsToFullTime'
})
export class SecondsToFullTimePipe implements PipeTransform {
  transform(value: number): string {
    if (!TypeCheckingUtility.isNumber(value) || value < 0) {
      return '00:00';
    }

    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60) + (hours * 60);
    const seconds: number = Math.floor(value % 60);

    return `${this.padTime(minutes)}:${this.padTime(seconds)}`;
  }

  padTime(t: number): string {
    return t < 10 ? `0${t}` : t.toString();
  }
}
