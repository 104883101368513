<ng-container *ngIf="relatedByCategory?.length > 0 || relatedByDistance?.length > 0">
  <div class="relatedEvents">
    <!-- StreApps in de buurt-->
    <div class="subEvents" *ngIf="relatedByDistance?.length > 0">
        <h1 class="title"><span>{{'page.detail.related_event.title_distance' | translate}}</span></h1>
          <a *ngFor="let relatedEvent of relatedByDistance;" routerLink="/detail/{{relatedEvent.partitionKey}}" class="cardLink">
            <image-block 
                        [thumbnail]="relatedEvent.thumbnail"
                        [hoverText]="relatedEvent.title"
                        [category]="relatedEvent.category"
                        [created]="relatedEvent.created"
                      ></image-block>
         </a>
    </div>

    <!-- StreApps van dezelfde categorie -->
    <div class="subEvents" *ngIf="relatedByCategory?.length > 0">
        <h1 class="title"><span>{{'page.detail.related_event.title_category' | translate}}</span></h1>
        <a *ngFor="let relatedEvent of relatedByCategory;" routerLink="/detail/{{relatedEvent.partitionKey}}" class="cardLink">
          <image-block 
                      [thumbnail]="relatedEvent.thumbnail"
                      [hoverText]="relatedEvent.title"
                      [category]="relatedEvent.category"
                      [created]="relatedEvent.created"
                    ></image-block>
        </a>
    </div>
  </div>
</ng-container>
