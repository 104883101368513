<a *ngIf="!!ad" href="{{ ad.url }}" target="_blank" class="ad">
  <mat-card class="widget ad-widget" [title]="ad.title">
    <div class="ad-info">
      <div class="video-preview">
        <img
          Class="photo"
          [src]="
            ad.imageUrl ? ad.imageUrl : './assets/images/fallback-thumb.png'
          "
          [alt]="ad.title"
          onerror="this.onerror = null; this.src = './assets/images/fallback-thumb.png';"
        />
      </div>
      <div class="bottom">
        <div class="video-attributes">
          <h1 class="ellipsified">{{ ad.title }}</h1>
          <div class="secondary-text description ellipsified ellipsified-3">
            {{ ad.description }}
          </div>
        </div>
      </div>
      <div class="actions">
        <div class="streapp-label">
          <span>Advertentie</span>
        </div>
      </div>
    </div>
  </mat-card>
</a>
