<div class="desktop-width-limiter detail-page page-padding">
  <ng-container *ngIf="!loading && !error && profile">
    <mat-card class="widget">
      <h1 class="pageTitle">
        <span *ngIf="!!profile.name && profile.name !== ' '">{{
          profile.name
        }}</span>
        <span *ngIf="!profile.name || profile.name === ' '">{{
          profile.userName
        }}</span>
      </h1>

      <div class="page-content">
        <div class="page-attributes">
          <div class="profile-image">
            <div class="image-section">
              <img
                [src]="getProfilePicture()"
                onerror="this.src = '/assets/images/user.png';"
                class="user-image"
                [alt]="'Afbeelding reporter'"
              />
              <img
                class="reporter-logo"
                src="/assets/images/logo_tekst_streapp_reporter_03.svg"
                *ngIf="profile.isReporter"
                [alt]="'StreApp Reporter'"
              />
            </div>
            <div class="reporting-button">
              <!-- Rapporteer gebruiker -->
              <reporting-button
                [userId]="userId"
                *ngIf="isLoggedIn()"
                [buttonTitle]="'report.report_user'"
              ></reporting-button>
            </div>
          </div>

          <div class="attribute" *ngIf="profile.amountOfStreApps">
            <h2>{{ "reporters.detail.amount" | translate }}</h2>
            <span class="category">{{ profile.amountOfStreApps }}</span>
          </div>
          <div class="attribute" *ngIf="profile.profile">
            <h2>{{ "reporters.detail.profile" | translate }}</h2>
            <span class="category">{{ profile.profile }}</span>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-container>

  <ng-container *ngIf="!loadingEvents && !errorText && events.length > 0">
    <div class="row overview-items">
      <div
        class="col-xs-12 col-sm-6 col-lg-4 overview-item"
        *ngFor="let event of events"
      >
        <overview-card [event]="event"></overview-card>
      </div>
      <div class="noMoreEvents">
        <span class="noMoreEventsLabel">{{
          "page.noMoreEvents" | translate
        }}</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <div class="loading">
      <mat-progress-spinner
        class="loading-spinner"
        [diameter]="75"
        mode="indeterminate"
        color="primary"
      >
      </mat-progress-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="loadingEvents">
    <div class="loading">
      <mat-progress-spinner
        class="loading-spinner"
        [diameter]="75"
        mode="indeterminate"
        color="primary"
      >
      </mat-progress-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading && error">
    <div class="errorText">
      {{ "page.error" | translate }}
    </div>
  </ng-container>
</div>
