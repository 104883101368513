<a *ngIf="navigateToEventOnClick; else noLink" routerLink="/detail/{{ event.partitionKey }}" class="overview-card-link">
  <mat-card class="widget widget-thumbnail" [title]="event.title">
    <div class="video-blur" *ngIf="!isAdultOrLowImpact()">
      <span>{{'page.adultVideo' | translate}}</span>
    </div>
    <div class="video-preview">
      <img [ngClass]="{'photo': event.videoDurationInSeconds == 0}" [src]="getThumbSource(event.thumbnail)"
          [alt]="event.title" onerror="this.onerror = null; this.src = './assets/images/fallback-thumb.png';" />
      <div *ngIf="event.url && event.videoDurationInSeconds > 0" class="video-overlay" [title]="event.title">
        <span class="video-overlay-controls">
          <mat-icon>play_circle_filled</mat-icon><br />
        </span>
        <span class="video-overlay-duration">{{event.videoDurationInSeconds | secondsToFullTime}}</span>
      </div>
    </div>
    <div class="video-attributes">
      <div class="primary-text ellipsified">{{event.title}}</div>
      <div class="secondary-text description ellipsified ellipsified-2">{{event.description}}</div>
      <div class="datetime ellipsified">
        {{event.category}} {{event.category ? '|' : ''}} {{event.created | dateToDescription}}
      </div>
    </div>
    <div class="actions">
      <div class="streapp-label">
        <img *ngIf="event.isReporter" src="/assets/images/Label_Reporter.png" alt="StreApp Reporter" />
      </div>
    </div>
  </mat-card>
</a>

<ng-template #noLink>
  <mat-card class="widget widget-thumbnail" [title]="event.title">
    <div class="video-blur" *ngIf="!isAdultOrLowImpact()">
      <span>{{'page.adultVideo' | translate}}</span>
    </div>
    <div class="video-preview">
      <img [ngClass]="{'photo': event.videoDurationInSeconds == 0}" [src]="getThumbSource(event.thumbnail)"
          [alt]="event.title" onerror="this.onerror = null; this.src = './assets/images/fallback-thumb.png';" />
      <div *ngIf="event.url && event.videoDurationInSeconds > 0" class="video-overlay" [title]="event.title">
        <span class="video-overlay-controls">
          <mat-icon>play_circle_filled</mat-icon><br />
        </span>
        <span class="video-overlay-duration">{{event.videoDurationInSeconds | secondsToFullTime}}</span>
      </div>
    </div>
    <div class="video-attributes">
      <div class="primary-text ellipsified">{{event.title}}</div>
      <div class="secondary-text description ellipsified ellipsified-2">{{event.description}}</div>
      <div class="datetime ellipsified">
        {{event.category}} {{event.category ? '|' : ''}} {{event.created | dateToDescription}}
      </div>
    </div>
    <div class="actions">
      <div class="streapp-label">
        <img *ngIf="event.isReporter" src="/assets/images/Label_Reporter.png" alt="StreApp Reporter" />
      </div>
    </div>
  </mat-card>
</ng-template>
