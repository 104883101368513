import {
  Component,
  OnInit,
  OnDestroy,
  HostListener,
  Inject,
  PLATFORM_ID,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { StreappEvent } from '../../../models/streappevent.model';
import { StreappEventsService } from '../../../services/streapp-events.service';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { MediaItem, MediaType } from 'src/app/models/mediaitem.model';
import { ReactionService } from '../../../services/reaction.service';
import { StreappReaction } from '../../../models/streappreaction.model';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppConfigService } from '../../../services/app-config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { HelpBoxService } from 'src/app/services/help-box.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceCheckUtility, DeviceOS } from 'src/app/utilities/device-check';
import { SimpleTextDialogData } from 'src/app/components/simple-text-dialog/models/simple-text-dialog-data.model';
import { SimpleTextDialogComponent } from 'src/app/components/simple-text-dialog/simple-text-dialog.component';
import { NavigationService } from 'src/app/services/navigation-end.service';
import { Meta, Title } from '@angular/platform-browser';
import { isPlatformServer } from '@angular/common';
import { RobotsService } from 'src/app/services/robots.service';
import { isPlatformBrowser } from '@angular/common';
import { CustomEventsService } from '@piwikpro/ngx-piwik-pro';
import { Tracking } from 'src/app/utilities/tracking';
import { DeviceService } from '../../../services/device.service';

@Component({
  selector: 'app-detail-page',
  templateUrl: './detail-page.component.html',
  styleUrls: ['./detail-page.component.scss'],
})
export class DetailPageComponent implements OnInit, OnDestroy, AfterViewInit {
  isServer: boolean = false;
  event: StreappEvent;
  reactions: StreappReaction[];
  eventId: string;
  loading: boolean = true;
  error: boolean = false;
  selectedMediaItem: MediaItem;
  hasScrolled: boolean = false;
  loggedIn: boolean;
  fromApp: boolean = false;
  selectedItem: number = 0;
  isMobile: boolean = false;
  isMyStreApp: boolean = false;

  private readonly destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly streappService: StreappEventsService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly navigation: NavigationService,
    private readonly authService: AuthService,
    private readonly reactionService: ReactionService,
    private readonly dialog: MatDialog,
    private readonly configService: AppConfigService,
    private readonly helpBox: HelpBoxService,
    private readonly translate: TranslateService,
    private readonly meta: Meta,
    private readonly robots: RobotsService,
    private titleService: Title,
    private readonly customEventService: CustomEventsService,
    private readonly deviceService: DeviceService,
    private readonly changeDetection: ChangeDetectorRef,
    @Inject(PLATFORM_ID) protected _platformId: Object
  ) {
    this.isServer = isPlatformServer(_platformId);
  }

  MediaType = MediaType;

  @HostListener('window:scroll', []) onScroll(): void {
    this.hasScrolled = window.pageYOffset > 0;
  }

  ngOnInit(): void {
    this.robots.setRobots();
    this.loggedIn = this.authService.isAuthenticated();
    this.getEventIdAndLoadEvent();
    this.deviceService.isMobileView.subscribe((value) => {
      this.isMobile = value;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      try {
          (window["adsbygoogle"] = window["adsbygoogle"] || []).push({});
      } catch (e) {
        console.error("ads", e);
      }
    }, 900);
  }

  isAdultOrLowImpact(): boolean {
    if (this.event.isHighImpactEvent) {
      if (this.authService.isAuthenticated()) {
        if (this.authService.isAdult) {
          return true;
        }
      }
      return false;
    }

    return true;
  }

  ngOnDestroy(): void {
    this.meta.updateTag({
      name: 'description',
      content:
        "Een online platform dat gebruikers in staat stelt om ooggetuige video's te delen en te bekijken",
    });
    this.meta.removeTag('name="og:description"');
    this.meta.removeTag('name="og:title"');
    this.meta.removeTag('name="og:image"');
    this.meta.removeTag('name="twitter:card"');
    this.meta.removeTag('name="summary_large_image"');
    this.titleService.setTitle('StreApp');

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getEventIdAndLoadEvent(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      let id = params['id'];
      if (!this.isServer) {
        if (this.route.snapshot.queryParamMap.has('app')) {
          if (
            DeviceCheckUtility.getMobileOperatingSystem() == DeviceOS.iOS ||
            DeviceCheckUtility.getMobileOperatingSystem() == DeviceOS.Android
          ) {
            if (DeviceCheckUtility.getMobileOperatingSystem() == DeviceOS.iOS) {
              this.helpBox.openOpenApp('streapp://' + id, DeviceOS.iOS);
            }
            if (
              DeviceCheckUtility.getMobileOperatingSystem() == DeviceOS.Android
            ) {
              this.helpBox.openOpenApp(
                'intent://' +
                  id +
                  '/#Intent;package=nl.streapp;scheme=streapp;S.browser_fallback_url=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dnl.streapp;end',
                DeviceOS.Android
              );
            }
          }
        }
      }
      this.loadEventFromService(id);
    });
  }

  loadEventFromService(id: string): void {
    this.loading = true;
    this.streappService
      .getEventById(id, this.authService.authorizationHeaderValue)
      .then((data: StreappEvent) => {
        if (!data || (data.deleted && !this.authService.isAdmin)) {
          // Either event doesn't exists or user is underage and its an highimpact event
          this.router.navigate(['/']);
        }

        this.event = data;
        this.loadReactions();
        this.selectedMediaItem = this.event.mediaItems[0];

        this.meta.updateTag({
          name: 'description',
          content: data.description,
        });
        this.meta.addTag({
          name: 'og:description',
          content: data.description,
        });
        this.meta.addTag({
          name: 'og:title',
          content: data.title,
        });
        this.meta.addTag({
          name: 'og:image',
          content: data.thumbnail,
        });
        this.meta.addTag({
          name: 'twitter:card',
          content: 'summary_large_image',
        });

        this.titleService.setTitle('StreApp - ' + data.title);
        this.isMyStreApp =
          this.authService.isAuthenticated() &&
          this.event.createdBy == this.authService.userId;
        this.loading = false;
      })
      .catch((error: HttpErrorResponse) => {
        if (error.status == 401 && isPlatformBrowser(this._platformId)) {
          this.loginForEvent();
          return;
        }
        this.loading = false;
        this.error = true;
      });
  }

  selectMediaItem(item: MediaItem): void {
    this.selectedMediaItem = item;

    if (this.selectedItem === 0) {
      this.selectedItem = 1;
    } else {
      this.selectedItem = 0;
    }
    this.changeDetection.detectChanges();
  }

  loginForEvent() {
    if (!this.authService.isAuthenticated()) {
      //Send tracking info of adult content popup
      this.customEventService.trackEvent(
        Tracking.categories[2],
        Tracking.actions[6],
        'Initial Popup'
      );

      // Adult Content popup
      this.dialog
        .open<SimpleTextDialogComponent, SimpleTextDialogData>(
          SimpleTextDialogComponent,
          {
            data: {
              title: 'overview.login.popup_title',
              text: 'overview.login.popup_text',
              showButton: true,
              showCancelButton: true,
              buttonCancelText: 'overview.login.popup_cancel',
              buttonText: 'overview.login.popup_button',
            },
          }
        )
        .afterClosed()
        .subscribe((data: boolean) => {
          if (data) {
            //Send tracking info of adult content popup login redirect
            this.customEventService.trackEvent(
              Tracking.categories[2],
              Tracking.actions[6],
              'Redirect Login'
            );
            // Redirect to login page with current url to redirect back after login.
            this.router.navigate(['/login'], {
              queryParams: { redirect: this.router.url },
              replaceUrl: true,
            });
          } else {
            //Send tracking info of adult content popup cancellation
            this.customEventService.trackEvent(
              Tracking.categories[2],
              Tracking.actions[6],
              'Cancel and Redirect'
            );
            //Redirect to last page or the main page if opened in a new tab when cancelled
            this.navigation.back();
          }
        });
    }
  }

  loadReactions(): void {
    this.reactionService
      .getReactionsForEvent(
        this.event.linkedToMainStreappID
          ? this.event.linkedToMainStreappID
          : this.event.partitionKey
      )
      .then((data: StreappReaction[]) => {
        this.reactions = data;
      });
  }

  addReaction(reactionText: string): void {
    this.reactionService
      .addReaction(
        this.event.linkedToMainStreappID
          ? this.event.linkedToMainStreappID
          : this.event.partitionKey,
        reactionText,
        this.authService.authorizationHeaderValue
      )
      .then((data: StreappReaction) => {
        this.reactions.unshift(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateReaction(reactionText: { text: string; id: string }): void {
    this.reactionService
      .updateReaction(
        this.event.linkedToMainStreappID
          ? this.event.linkedToMainStreappID
          : this.event.partitionKey,
        reactionText.id,
        reactionText.text,
        this.authService.authorizationHeaderValue
      )
      .then(() => {
        this.loadReactions();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  removeReaction(reactionId: string): void {
    this.reactionService
      .deleteReaction(
        this.event.linkedToMainStreappID
          ? this.event.linkedToMainStreappID
          : this.event.partitionKey,
        reactionId,
        this.authService.authorizationHeaderValue
      )
      .then(() => {
        this.loadReactions();
      });
  }

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  dialogOpen() {
    this.translate.get('help.new-event.disabled').subscribe((text) => {
      this.helpBox.openBox(text);
    });
  }

  getThumbnailUrl(item: string) {
    if (item.indexOf('streapp-processing.png') > -1) {
      return '';
    }
    return item;
  }
}
