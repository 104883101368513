import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { takeUntil, filter } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'fab',
  templateUrl: './fab.component.html',
  styleUrls: ['./fab.component.scss']
})
export class FabComponent implements OnInit {
  @Input() title: string;
  @Input() routerLink: string;
  @Input() icon: string;
  @Input() style: string;


  constructor(private readonly router: Router,
    private readonly authService: AuthService) { }

  ngOnInit(): void {
  }
}
