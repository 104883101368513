<div class="fab-buttons">
  <fab
    *ngIf="!editMode"
    [title]="'profile.button_edit'"
    [icon]="'edit'"
    [style]="'group'"
    (click)="edit()"
  ></fab>
  <fab
    *ngIf="editMode"
    [title]="'profile.button_save'"
    [icon]="'save'"
    [style]="getFabStyle()"
    (click)="save()"
  ></fab>
  <fab
    *ngIf="!editMode"
    [title]="'Uitloggen'"
    [icon]="'logout'"
    [style]="'active group'"
    (click)="signout()"
  ></fab>
</div>
<div class="desktop-width-limiter page-padding profile-edit-page">
  <profile-subnav [activeItem]="'profile'"></profile-subnav>
  <mat-card class="widget">
    <form [formGroup]="profileFormGroup">
      <div>
        <mat-form-field appearance="outline" class="size-s">
          <mat-label>{{ "profile.email_label" | translate }}</mat-label>
          <input
            #email
            matInput
            formControlName="email"
            [placeholder]="'profile.email_placeholder' | translate"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="size-s" *ngIf="reporter">
          <mat-label>{{ "profile.firstname_label" | translate }}</mat-label>
          <input
            #firstname
            matInput
            formControlName="firstname"
            [placeholder]="'profile.firstname_placeholder' | translate"
            required
            maxlength="150"
          />
          <mat-error>{{
            "profile.firstname_validation" | translate
          }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="size-s" *ngIf="reporter">
          <mat-label>{{ "profile.lastname_label" | translate }}</mat-label>
          <input
            #lastname
            matInput
            formControlName="lastname"
            [placeholder]="'profile.lastname_placeholder' | translate"
            required
            maxlength="150"
          />
          <mat-error>{{ "profile.lastname_validation" | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="size-s">
          <mat-label>{{ "profile.birthday_label" | translate }}</mat-label>
          <input
            #birthday
            matInput
            type="date"
            formControlName="birthday"
            [placeholder]="'profile.birthday_placeholder' | translate"
            required
          />
          <mat-error>{{ "profile.birthday_validation" | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="reporter" appearance="outline" class="size-s">
          <mat-label>{{ "profile.profile_label" | translate }}</mat-label>
          <textarea
            #profile
            required
            maxlength="500"
            autosize
            rows="3"
            minRows="3"
            maxRows="10"
            formControlName="profile"
            matInput
            [placeholder]="'profile.profile_placeholder' | translate"
          ></textarea>
          <mat-error>{{ "profile.profile_validation" | translate }}</mat-error>
        </mat-form-field>

        <!-- Profile Image upload section -->
        <h3>Profielfoto (max 1 Mb, aanbevolen resolutie 110x110)</h3>
        <div class="">
          <img
            class="profile-picture"
            [src]="profileUrl"
            onerror="this.src = './assets/images/user.png';"
          />
          <span
            *ngIf="editMode"
            class="remove-item"
            typeof="button"
            (click)="removePicture()"
            [title]="'profile.remove-picture' | translate"
          >
            <mat-icon>close</mat-icon>
          </span>
        </div>

        <ngx-file-drop
          *ngIf="editMode"
          (onFileDrop)="dropped($event, 'profile')"
          [multiple]="false"
          [accept]="'image/*'"
          class="size-m file-drop"
          [disabled]="profileImageFile.length > 0"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
          >
            <div
              class="file-selector"
              *ngIf="profileImageFile.length === 0"
              (click)="openFileSelector()"
            >
              <mat-icon>cloud_upload</mat-icon>
              <span class="dz-btn">{{
                "profile.dropzone_label" | translate
              }}</span>
            </div>
            <div class="file-selected" *ngIf="profileImageFile.length !== 0">
              <div
                class="single-file"
                *ngFor="let item of profileImageFile; let i = index"
              >
                <span class="upload-item-label">{{ item.relativePath }}</span>
                <span
                  class="remove-item"
                  typeof="button"
                  (click)="removeFile(i, 'profile')"
                  [title]="'general.remove' | translate | titlecase"
                >
                  <mat-icon>close</mat-icon>
                </span>
              </div>
            </div>
          </ng-template>
        </ngx-file-drop>

        <!-- Banner upload section -->
        <ng-container *ngIf="reporter">
          <h3>Banner (max 1 Mb, aanbevolen resolutie: 1450x180)</h3>
          <div class="banner-picture" *ngIf="bannerUrl">
            <img
              class="profile-picture"
              [src]="bannerUrl"
              onerror="this.src = '/assets/images/fallback-banner.jpg';"
            />
            <span
              *ngIf="editMode"
              class="remove-item"
              typeof="button"
              (click)="removeBanner()"
              [title]="'profile.remove-banner' | translate"
            >
              <mat-icon>close</mat-icon>
            </span>
          </div>

          <ngx-file-drop
            *ngIf="editMode"
            (onFileDrop)="dropped($event, 'banner')"
            [multiple]="false"
            [accept]="'image/*'"
            class="size-m file-drop"
            [disabled]="bannerFile.length > 0"
          >
            <ng-template
              ngx-file-drop-content-tmp
              let-openFileSelector="openFileSelector"
            >
              <div
                class="file-selector"
                *ngIf="bannerFile.length === 0"
                (click)="openFileSelector()"
              >
                <mat-icon>cloud_upload</mat-icon>
                <span class="dz-btn">{{
                  "profile.dropzone_label" | translate
                }}</span>
              </div>
              <div class="file-selected" *ngIf="bannerFile.length !== 0">
                <div
                  class="single-file"
                  *ngFor="let item of bannerFile; let i = index"
                >
                  <span class="upload-item-label">{{ item.relativePath }}</span>
                  <span
                    class="remove-item"
                    typeof="button"
                    (click)="removeFile(i, 'banner')"
                    [title]="'general.remove' | translate | titlecase"
                  >
                    <mat-icon>close</mat-icon>
                  </span>
                </div>
              </div>
            </ng-template>
          </ngx-file-drop>
        </ng-container>
      </div>
    </form>
  </mat-card>
</div>
