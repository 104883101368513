<div class="desktop-width-limiter detail-page page-padding">
  <ng-container *ngIf="!!p2000Melding">
    <mat-card class="widget">
      <h1 class="pageTitle"><span>{{p2000Melding.title}}</span></h1>
      <div class="page-content">
        <div class="video-player-container">
          <div class="video-player">
            <img class="media-image" [src]="p2000Melding.imageUrl" alt="112 afbeelding" />
          </div>
          <div class="page-attributes ellipsified">
            <div class="attribute" *ngIf="p2000Melding.disciplineName">
              <span class="category">{{p2000Melding.disciplineName}}</span>
            </div>
            <div class="attribute" *ngIf="p2000Melding.created">
              <span class="category">{{p2000Melding.created | dateToDescription}}</span>
            </div>
          </div>
          <div class="page-attributes second-row">
            <div class="attribute image-section">
              <img class="profile-image" alt="Profielafbeelding" src="/assets/images/alarm-light-outline-white.png" height="26" width="26" />
              <div class="name-and-reporter">
                <span class="category">P2000 netwerk</span>
              </div>
            </div>
            <div class="attribute urgentie-chip"
                 [class.prio1]="p2000Melding.prio === 1"
                 [class.prio2]="p2000Melding.prio === 2"
                 [class.prio3]="p2000Melding.prio === 3">
              <mat-chip>{{p2000Melding.prio === 1 ? 'Hoge urgentie' : p2000Melding.prio === 2 ? 'Normale urgentie' : 'Lage urgentie'}}</mat-chip>
            </div>
          </div>
          <div class="description">
            <h2>{{'page.detail.description' | translate}}</h2>
            <span>{{p2000Melding.description}}</span>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-container>
</div>
