import { Component, OnInit } from '@angular/core';
import { StreappEventsService } from '../../../services/streapp-events.service';
import { AuthService } from '../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SimpleTextDialogComponent } from 'src/app/components/simple-text-dialog/simple-text-dialog.component';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { WelcomeDialogComponent } from 'src/app/components/welcome-dialog/welcome-dialog.component';
import { WelcomeDialogData } from 'src/app/components/welcome-dialog/models/welcome-dialog-data.model';
import { UserProfile } from '../../../models/profile.model';
import { LocationSettingsComponent } from '../../../components/location-settings/location-settings.component';
import { SimpleTextDialogData } from 'src/app/components/simple-text-dialog/models/simple-text-dialog-data.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userProfile: UserProfile;
  loading: boolean = true;
  errorText: string;
  editMode: boolean = false;
  originalProfileUrl: any;
  originalBannerUrl: any;
  profileUrl: any;
  bannerUrl: any;
  confirmed: boolean = false;

  profileFormGroup: UntypedFormGroup;

  reporter: boolean = false;

  profileImageFile: NgxFileDropEntry[] = [];
  bannerFile: NgxFileDropEntry[] = [];

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private readonly streappService: StreappEventsService,
    private readonly authService: AuthService,
    private readonly translate: TranslateService,
    private dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    private readonly route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.profileFormGroup = this._formBuilder.group({
      firstname: [{ value: '', disabled: true }, [Validators.maxLength(150)]],
      lastname: [{ value: '', disabled: true }, [Validators.maxLength(150)]],
      email: [{ value: '', disabled: true }],
      profile: [
        { value: '', disabled: true },
        [Validators.required, Validators.maxLength(500)],
      ],
      birthday: [{ value: '', disabled: true }],
      profileImageFile: [''],
      bannerFile: [''],
    });

    this.authService
      .getClaimData(true)
      .then((data) => {
        this.userProfile = data;

        this.profileFormGroup
          .get('firstname')
          .setValue(this.userProfile.firstName);
        this.profileFormGroup
          .get('lastname')
          .setValue(this.userProfile.lastName);
        this.profileFormGroup.get('email').setValue(this.userProfile.email);
        this.profileFormGroup
          .get('birthday')
          .setValue(this.userProfile.birthDay);
        this.reporter = this.userProfile.isReporter;
        this.streappService
          .getStreapperInfo(this.userProfile.email)
          .then((profileInfo) => {
            if (profileInfo.picture) {
              this.originalProfileUrl = this.authService.pictureUrl;
              this.profileUrl = this.authService.pictureUrl;
            }
            if (profileInfo.banner) {
              this.originalBannerUrl = profileInfo.banner;
              this.bannerUrl = profileInfo.banner;
            }
            if (this.reporter) {
              this.profileFormGroup
                .get('profile')
                .setValue(profileInfo.profile);
            }
          });

        this.route.queryParams.subscribe((params) => {
          if (params['confirmed']) {
            this.confirmed = true;

            this.dialog.open<WelcomeDialogComponent, WelcomeDialogData>(
              WelcomeDialogComponent,
              {
                data: {
                  name: this.userProfile.firstName,
                },
              }
            );
          }
        });
      })
      .catch((error) => {
        this.errorText = error;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  removeBanner(): void {
    this.authService.removeBanner().then(() => {
      this.bannerUrl = null;
      this.bannerFile = [];
      this.originalBannerUrl = null;
    });
  }

  removePicture(): void {
    this.authService.removeProfilePicture().then(() => {
      this.profileUrl = '';
      this.profileImageFile = [];
      this.originalProfileUrl = null;
    });
  }

  getFabStyle(): string {
    if (this.profileFormGroup.dirty && this.profileFormGroup.valid) {
      return 'active group';
    } else {
      return 'group';
    }
  }

  edit(): void {
    //Set fields to be enabled
    this.profileFormGroup.get('profile').enable();
    this.profileFormGroup.get('birthday').enable();

    if (this.reporter) {
      this.profileFormGroup.get('firstname').enable();
      this.profileFormGroup.get('lastname').enable();
    }

    this.editMode = true;
  }

  async save(): Promise<void> {
    var profile;
    if (this.reporter) {
      profile = {
        firstname: this.profileFormGroup.get('firstname').value,
        lastname: this.profileFormGroup.get('lastname').value,
        profile: this.profileFormGroup.get('profile').value,
        birthday: this.profileFormGroup.get('birthday').value,
      };
    } else {
      profile = {
        birthday: this.profileFormGroup.get('birthday').value,
      };
    }
    var isError: boolean = false;
    // Upload Profile Image
    var firstFile = this.profileImageFile[0];
    if (firstFile) {
      const fileEntry = firstFile.fileEntry as FileSystemFileEntry;
      await fileEntry.file(async (file: File) => {
        //Upload file and save profileUrl
        const formData = new FormData();
        formData.append('fileKey', file, firstFile.relativePath);

        try {
          await this.authService.uploadProfilePicture(formData);
        } catch (error) {
          isError = true;
          this.dialog.open<SimpleTextDialogComponent>(
            SimpleTextDialogComponent,
            {
              data: {
                title: 'Fout bij opslaan van afbeelding',
                text: 'De afbeelding is te groot. Het bestand mag maximaal 1Mb zijn.',
                showButton: false,
              },
            }
          );
        }
      });
    }
    // Upload Banner
    var secondFile = this.bannerFile[0];
    if (secondFile) {
      const fileEntry = secondFile.fileEntry as FileSystemFileEntry;
      await fileEntry.file(async (file: File) => {
        //Upload file and save bannerUrl
        const formData = new FormData();
        formData.append('fileKey', file, secondFile.relativePath);

        try {
          await this.authService.uploadBanner(formData);
        } catch (error) {
          isError = true;
          this.dialog.open<SimpleTextDialogComponent>(
            SimpleTextDialogComponent,
            {
              data: {
                title: 'Fout bij opslaan van afbeelding',
                text: 'De afbeelding is te groot. Het bestand mag maximaal 1Mb zijn.',
                showButton: false,
              },
            }
          );
        }
      });
    }

    if (isError) {
      return;
    }

    await this.authService.updateProfile(JSON.stringify(profile));

    this.profileFormGroup.get('firstname').disable();
    this.profileFormGroup.get('lastname').disable();
    this.profileFormGroup.get('profile').disable();
    this.profileFormGroup.get('birthday').disable();

    this.dialog.open<SimpleTextDialogComponent>(SimpleTextDialogComponent, {
      data: {
        title: 'profile.save.popup_title',
        text: 'profile.save.popup_text',
        showButton: false,
      },
    });

    this.authService.loginSilent().then(() => {
      this.editMode = false;
      this.profileUrl = this.authService.pictureUrl;
    });
  }

  public dropped(files: NgxFileDropEntry[], url: string): void {
    if (url === 'profile') {
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          this.profileImageFile.push(droppedFile);
          this.profileFormGroup.controls['profileImageFile'].setValue(
            droppedFile.relativePath
          );

          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          const reader = new FileReader();

          fileEntry.file((file) => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.profileUrl = reader.result;
            };
          });
        }
      }
    } else if (url === 'banner') {
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          this.bannerFile.push(droppedFile);
          this.profileFormGroup.controls['bannerFile'].setValue(
            droppedFile.relativePath
          );

          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          const reader = new FileReader();

          fileEntry.file((file) => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              this.bannerUrl = reader.result;
            };
          });
        }
      }
    }
  }

  removeFile(i: number, url: string): void {
    if (url === 'profile') {
      this.profileImageFile.splice(i, 1);
      this.profileUrl = this.originalProfileUrl;
    } else {
      this.bannerFile.splice(i, 1);
      this.bannerUrl = this.originalBannerUrl;
    }
  }

  openSettings(): void {
    this.dialog.open<LocationSettingsComponent, any>(
      LocationSettingsComponent,
      {
        data: { title: 'test titel' },
      }
    );
  }

  async signout() {
    this.dialog
      .open<SimpleTextDialogComponent, SimpleTextDialogData>(
        SimpleTextDialogComponent,
        {
          data: {
            title: 'auth.signout.popup_title',
            text: 'auth.signout.popup_text',
            showButton: true,
            showCancelButton: true,
            buttonCancelText: 'auth.signout.popup_cancel',
            buttonText: 'auth.signout.popup_button',
          },
        }
      )
      .afterClosed()
      .subscribe((data: boolean) => {
        if (data) {
          this.authService.signout();
        }
      });
  }
}
