import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Promo } from 'src/app/models/ad.model';

@Component({
  selector: 'app-promo-dialog',
  templateUrl: './promo-dialog.component.html',
  styleUrls: ['./promo-dialog.component.scss']
})
export class PromoDialogComponent implements OnInit {

  color: string;
  countdown: string;

  constructor(private readonly dialogRef: MatDialogRef<PromoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: Promo,
    private readonly translate: TranslateService) { 
    }

  ngOnInit(): void {
    this.getCountDown();
    setInterval(() => {this.getCountDown()}, 5000);
  }

  closeWithConfirm(): void {
    this.dialogRef.close(true);
  }

  close() : void {
    this.dialogRef.close(false);
  }

  getImageSource(img: string): string {
    if (this.validURL(img)) {
      // this is a photo streapp use full image;
      return img;
    } else {
      return 'data:image/jpeg;base64,' + img;
    }
  }

  private validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  getCountDown() {
    let now = new Date();
    let endDate = new Date(this.data.publishEnd);
    if (now > endDate) {
      this.translate.get('promo.ended').subscribe(text => {
        return text;
      })    
    }

    var diff = Math.abs(endDate.getTime() - now.getTime());
    var diffDays = Math.floor(diff / (1000 * 3600 * 24));
    var diffHours = Math.floor(diff / (1000 * 3600)) - diffDays * 24;
    var diffMinutes = Math.floor((Math.ceil(diff / (1000)) - (diffDays * 24 * 3600) - (diffHours * 3600)) / 60);
    if (diffHours < 0) {
      diffHours = 23 + diffHours;
    }
    var diffMinutes = endDate.getMinutes() - now.getMinutes();
    if (diffMinutes < 0) {
      diffMinutes = 60 + diffMinutes;
    }

    if (diffDays > 0 || diffHours > 8) {
      this.color = 'green';
    } else if (diffHours > 0)
    {
      this.color = 'orange';
    }
    else {
      this.color = 'red';
    }

    this.countdown = diffDays + ' dg : ' + diffHours + ' uur : ' + diffMinutes + ' min';
  }
}
