import { Component, Input } from '@angular/core';
import { Ad } from 'src/app/models/ad.model';

@Component({
  selector: 'ad-card-v2',
  templateUrl: './ad-card-v2.component.html',
  styleUrls: ['./ad-card-v2.component.scss']
})
export class AdCardV2Component {
  @Input() ad: Ad;
}
