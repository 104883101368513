import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mobile-choice-dialog',
  templateUrl: './mobile-choice-dialog.component.html',
  styleUrls: ['./mobile-choice-dialog.component.scss']
})
export class MobileChoiceDialogComponent implements OnInit {

  @Output() continueClicked: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


  continueToSite(): void {
    this.continueClicked.emit();
  }

  openAppleStore(): void {
    window.open('https://apps.apple.com/app/id1548648213', '_about');
  }

  openGoogleStore(): void {
    window.open('https://play.google.com/store/apps/details?id=nl.streapp&gl=NL', '_about');
  }
}
