import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { AdsOverviewComponent } from './ads-overview/ads-overview.component';
import { CreateAdComponent } from './create-ad/create-ad.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { PromoOverviewComponent } from './promo-overview/promo-overview.component';
import { PromoDetailComponent } from './promo-detail/promo-detail.component';
import { CreateEventModule } from '../create-event/create-event.module';



@NgModule({
  declarations: [AdsOverviewComponent, CreateAdComponent, PromoOverviewComponent, PromoDetailComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    NgxFileDropModule,
    CreateEventModule
  ]
})
export class AdsModule { }
