<ng-container *ngIf="!!event">
  <div class="page-attributes">
    <div class="attribute" *ngIf="event.category">
      <span class="category">{{ event.created | dateToDescription}}</span>
    </div>
    <div class="attribute" *ngIf="event.category">
      <span class="label">{{'page.detail.category' | translate}}</span>
      <span class="category">{{event.category}} {{ !!event.subcategory ? '- ' + event.subcategory : ''}}</span>
    </div>
    <div class="attribute" *ngIf="event.tags">
      <span class="label">{{'page.detail.tags' | translate}}</span>
      <span class="category">{{event.tags}}</span>
    </div>
    <div class="attribute cursor-pointer image-section" *ngIf="event.createdByName" (click)="navigateToReporter()" [title]="'page.detail.navigate_to_profile' | translate">
      <img class="profile-image" alt="Profielafbeelding" [src]="getEventProfilePicture()"
           onerror="this.onerror=null;this.src='/assets/images/user.png';" height="20" width="20" />
      <div class="name-and-reporter">
        <span class="category">{{event.createdByName}}</span>
        <img class="reporter-image" alt="StreApp reporter" *ngIf="event.isReporter" src="/assets/images/tekst_streapp_reporter_grey_01.svg" />
      </div>
    </div>
  </div>
  <div class="page-attributes second-row">
    <div class="attribute" *ngIf="event.locationCity">
      <span class="label">
        <mat-icon>location_on</mat-icon>
      </span>
      <span class="category">{{ event.locationStreet }}{{ !!event.locationCity ? ', ' + event.locationCity : '' }}</span>
    </div>
  </div>
</ng-container>
