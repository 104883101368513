<ng-container *ngIf="event">
  <div class="linkedEvents">
    <div class="mainEvent">
      <h1 class="title"><span [class.active]="!event.linkedToMainStreappID">{{'page.detail.linked_event.main_event_title' | translate}}</span></h1>
      <!-- Is not selected -->
      <a *ngIf="!!event.linkedToMainStreappID" routerLink="/detail/{{event.linkedStreappEvents[0]?.partitionKey}}" class="cardLink">
      <image-block
                   [hoverText]="event.description"
                   [thumbnail]="event.linkedStreappEvents[0]?.thumbnail"
                   [created]="event.linkedStreappEvents[0]?.created"></image-block>
      </a>
      <!-- Is selected -->
      <image-block *ngIf="!event.linkedToMainStreappID"
                   [hoverText]="event.description"
                   [thumbnail]="event.thumbnail"
                   [created]="event.created"
                   [active]="true"></image-block>
    </div>
    <div class="subEvents">
      <h1 class="title"><span [class.active]="!!event.linkedToMainStreappID">{{'page.detail.linked_event.sub_event_title' | translate}}</span></h1>
      <ng-container *ngIf="!!event.linkedToMainStreappID">
        <a *ngFor="let linkedEvent of event.linkedStreappEvents[0]?.linkedStreappEvents;" routerLink="/detail/{{linkedEvent.partitionKey}}" class="cardLink">
        <image-block
                     [hoverText]="linkedEvent.description"
                     [thumbnail]="linkedEvent.thumbnail"
                     [created]="linkedEvent.created"
                     [active]="event.partitionKey === linkedEvent.partitionKey"></image-block>
        </a>
      </ng-container>
      <ng-container *ngIf="!event.linkedToMainStreappID">
        <a *ngFor="let linkedEvent of event.linkedStreappEvents;" routerLink="/detail/{{linkedEvent.partitionKey}}" class="cardLink">
        <image-block
                     [hoverText]="linkedEvent.description"
                     [thumbnail]="linkedEvent.thumbnail"
                     [created]="linkedEvent.created"
                     [active]="event.partitionKey === linkedEvent.partitionKey"></image-block>
        </a>
      </ng-container>
    </div>
  </div>
</ng-container>
