import { Injectable, Injector } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AppConfigService } from '../services/app-config.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private router: Router,
    private configService: AppConfigService,
    private injector: Injector
  ) {}

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    await this.configService.loadAppConfig();
    const authService = this.injector.get(AuthService);
    if (authService.isAuthenticated()) {
      if (route.data['roles']) {
        const roles = authService.roles;
        if (roles.includes('Admin')) {
          return true;
        }

        var found = false;
        roles.forEach((r) => {
          if (route.data['roles'].includes(r)) {
            found = true;
          }
        });
        if (!found) {
          this.router.navigate(['/access-denied']);
        }
      }
      return true;
    }

    localStorage.setItem('redirect', state.url);
    authService.login();
    return false;
  }
}
