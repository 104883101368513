import { Component, OnInit, Input } from '@angular/core';
import { StreappEvent } from 'src/app/models/streappevent.model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'overview-card-alternate-version',
  templateUrl: './overview-card-alternate-version.component.html',
  styleUrls: ['./overview-card-alternate-version.component.scss'],
})
export class OverviewCardAlternateVersionComponent implements OnInit {
  @Input() event: StreappEvent;

  multipleVideosPhotos: boolean = false;
  singlePhotoMultipleVideos: boolean = false;
  singleVideoMultiplePhotos: boolean = false;
  multiplePhotos: boolean = false;
  multipleVideos: boolean = false;
  singlePhoto: boolean = false;
  singleVideo: boolean = false;
  thumbnail: string;

  constructor(private readonly authService: AuthService) {}

  ngOnInit(): void {
    this.setThumbnail();
  }

  isAdultOrLowImpact(): boolean {
    if (this.event.isHighImpactEvent) {
      if (this.authService.isAuthenticated()) {
        if (this.authService.isAdult) {
          return true;
        }
      }
      return false;
    }

    return true;
  }

  /* Method is unused for now, but can be enabled once this info is passed through in the API */
  private setMediaTypes(): void {
    if (this.event.mediaItemTypes[1] > 1 && this.event.mediaItemTypes[0] > 1) {
      this.multipleVideosPhotos = true;
    } else if (
      this.event.mediaItemTypes[1] == 1 &&
      this.event.mediaItemTypes[0] > 1
    ) {
      this.singlePhotoMultipleVideos = true;
    } else if (
      this.event.mediaItemTypes[1] > 1 &&
      this.event.mediaItemTypes[0] == 1
    ) {
      this.singleVideoMultiplePhotos = true;
    } else if (
      this.event.mediaItemTypes[1] > 1 &&
      this.event.mediaItemTypes[0] == 0
    ) {
      this.multiplePhotos = true;
    } else if (
      this.event.mediaItemTypes[1] > 1 &&
      this.event.mediaItemTypes[0] == 0
    ) {
      this.multipleVideos = true;
    } else if (
      this.event.mediaItemTypes[1] == 1 &&
      this.event.mediaItemTypes[0] == 0
    ) {
      this.singlePhoto = true;
    } else if (
      this.event.mediaItemTypes[1] == 1 &&
      this.event.mediaItemTypes[0] == 0
    ) {
      this.singleVideo = true;
    }
  }

  private setThumbnail(): void {
    if (this.validURL(this.event.thumbnail)) {
      this.thumbnail = this.event.thumbnail;
    } else {
      this.thumbnail = 'data:image/jpeg;base64,' + this.event.thumbnail;
    }
  }

  private validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  }
}
