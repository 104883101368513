import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'scroll-up',
  templateUrl: './scroll-up.component.html',
  styleUrls: ['./scroll-up.component.scss']
})
export class ScrollUpComponent implements OnInit  {

  constructor() { }
 
  ngOnInit(): void {
  }

  get scrollBarsVisible() {
    return document.body.scrollHeight > document.body.clientHeight;
  }
  

  scrollUp() {
    window.scrollTo({ top: 0, behavior: 'smooth'});
  }

}
