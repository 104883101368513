import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyCheckbox as MatCheckbox, MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { AuthService } from '../../services/auth.service';
import { LocationService } from '../../services/location.service';

@Component({
  selector: 'app-location-settings',
  templateUrl: './location-settings.component.html',
  styleUrls: ['./location-settings.component.scss']
})
export class LocationSettingsComponent implements OnInit {

  @ViewChild('useMyLocation') useMyLocationCheckbox: MatCheckbox;
  @ViewChild('useCustomLocation') useCustomLocationCheckbox: MatCheckbox;

  showLocationSelector: boolean = false;
  location: string;
  locationName: string;
  locationError: boolean = false;
  locationRadius: number = 10;

  constructor(private readonly dialogRef: MatDialogRef<LocationSettingsComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any,
    private readonly authService: AuthService,
    private readonly locationService: LocationService) { }

  ngOnInit(): void {
    if (this.data && !!this.data.location) {
      this.showLocationSelector = true;
      this.location = this.data.location.split(';')[0] + ';' + this.data.location.split(';')[1] + ';' + this.data.location.split(';')[2];
      this.locationRadius = this.data.location.split(';')[3] || 10;
    }
  }

  close(): void {
    this.dialogRef.close(false);
  }

  myLocationChanged(value: MatCheckboxChange): void {
    this.locationError = false;
    if (value.checked) {
      // Uncheck custom location checkbox
      if (this.useCustomLocationCheckbox.checked) {
        this.useCustomLocationCheckbox.checked = false;
        this.showLocationSelector = false;
      }

      // Request user location from browser
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          const longitude = position.coords.longitude;
          const latitude = position.coords.latitude;
          this.location = longitude + ';' + latitude + ';' + this.locationRadius;
        }, () => {
          this.locationError = true;
        });
      }
    }
  }

  customLocationChanged(value: MatCheckboxChange): void {
    this.showLocationSelector = value.checked;

    if (value.checked) {
      // Uncheck custom location checkbox
      if (this.useMyLocationCheckbox.checked) {
        this.useMyLocationCheckbox.checked = false;
      }
    }
  }

  onCustomLocationChanged(event: string) {
    this.location = event;
  }

  saveLocation(): void {
    if (this.authService.isAuthenticated()) {
      // Save location in profile
      this.authService.getClaimData().then((data) => {
        let updateProfile = {
          'firstname': data.firstName,
          'lastname': data.lastName,
          'birthday': data.birthDay,
          'location': `${this.location + ';' + this.locationRadius}`
        };

        this.authService.updateProfile(JSON.stringify(updateProfile)).then((data) => {
          // We need to login to get the new token
          this.authService.loginSilent().then(() => {
            this.addLocationToServiceAndClose();
          });
        });
      });
    } else {
      this.addLocationToServiceAndClose();
    }
  }

  save(): void {
    if (!!this.location) {
      this.saveLocation();
    }
  }

  addLocationToServiceAndClose(): void {
    this.locationService.location = { lat: +this.location.split(';')[1], long: +this.location.split(';')[0], radius: this.locationRadius };
    this.dialogRef.close(this.location + ';' + this.locationRadius);
  }
}
