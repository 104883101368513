<div class="desktop-width-limiter createadd-page page-padding">
    <mat-toolbar>
        <button mat-button class="menu-item active" title="{{ 'create-ads.hover_title' | translate }}">
            <h1>{{ 'create-ads.title' | translate }}</h1>
        </button>
        <button mat-button class="menu-item" routerLink="/ads" title="{{ 'ads.hover_title' | translate }}">
            <h1>{{ 'ads.title' | translate }}</h1>
        </button>
        <button mat-button class="menu-item" routerLink="/promos" title="{{ 'ads.hover_title' | translate }}">
            <h1>{{ 'promo.title' | translate }}</h1>
        </button>
    </mat-toolbar>
        <mat-horizontal-stepper [linear]="true" #stepper>
          <mat-step [completed]="selectedAdType !== ''">
            <ng-template matStepLabel>{{'new_ad.step1.label' | translate}}</ng-template>
            <div class="input-select-type">
                <div class="input-select-row">
                    <div class="input-select-option" (click)="selectAdType('ad')">
                        <span></span>
                        <button class="bronze" type="button" mat-raised-button >
                            <span>{{ 'new_ad.bronze' | translate }}</span>
                        </button>
                    </div>
                    <div class="input-select-option" (click)="selectAdType('ad-silver')">
                        <span></span>
                        <button class="silver" type="button" mat-raised-button >
                            <span>{{ 'new_ad.silver' | translate }}</span>
                        </button>
                    </div>
                    <div class="input-select-option" (click)="selectAdType('promo')"> 
                        <span></span>
                        <button class="gold" type="button" mat-raised-button>
                            <span>{{ 'new_ad.gold' | translate }}</span>
                        </button>
                    </div>
                </div>
            </div>
          </mat-step>
          <mat-step [stepControl]="streappFormGroup">
            <!--Ad-->
            <div *ngIf="selectedAdType == 'ad' || selectedAdType == 'ad-silver'" class="row">
                <div class="col-6">
                    <form [formGroup]="streappFormGroup">
                        <ng-template matStepLabel>{{'new_ad.step2.label' | translate}}</ng-template>
                        <div class="step2">
                        <!-- Titel veld -->
                        <mat-form-field appearance="outline" class="size-m">
                            <mat-label>{{'new_ad.step2.title_label' | translate}}</mat-label>
                            <input #title matInput formControlName="title" [placeholder]="'new_ad.step2.title_placeholder' | translate" required>
                            <mat-hint align="end">{{title.value?.length || 0}} / 150</mat-hint>
                            <mat-error>{{'new_ad.step2.title_validation' | translate}}</mat-error>
                        </mat-form-field>
                
                        <!-- Beschrijving veld -->
                        <mat-form-field appearance="outline" class="size-l">
                            <mat-label>{{'new_ad.step2.description_label' | translate}}</mat-label>
                            <textarea #description required maxlength="500" autosize rows="3" minRows="3" maxRows="10" formControlName="description" matInput [placeholder]="'new_ad.step2.description_placeholder' | translate"></textarea>
                            <mat-hint align="end">{{description.value?.length || 0}} / 500</mat-hint>
                            <mat-error>{{'new_ad.step2.description_validation' | translate}}</mat-error>
                        </mat-form-field>
                
                        <!-- File drop veld -->
                        <ngx-file-drop (onFileDrop)="dropped($event)" [multiple]="false" [accept]="'image/*'" class="size-l file-drop" [disabled]="files.length > 0">
                            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                            <div class="file-selector" *ngIf="files.length === 0" (click)="openFileSelector()">
                                <mat-icon>cloud_upload</mat-icon>
                                <span class="dz-btn">{{'new_ad.step2.dropzone_label' | translate}}</span>
                            </div>
                            <div class="file-selected" *ngIf="files.length !== 0">
                                <div class="single-file" *ngFor=" let item of files; let i=index">
                                <span class="upload-item-label">{{ item.relativePath }}</span>
                                <span class="remove-item" typeof="button" (click)="removeFile(i)"
                                        [title]="'general.remove' | translate | titlecase">
                                    <mat-icon>close</mat-icon>
                                </span>
                                </div>
                            </div>
                            </ng-template>
                        </ngx-file-drop>

                        <mat-form-field appearance="outline" class="size-m">
                            <mat-label>{{'new_ad.step2.start_label' | translate}}</mat-label>
                            <input #publishstart matInput type="date" formControlName="publishstart" [placeholder]="'new_ad.step2.start_placeholder' | translate" required >
                            <mat-error>{{'new_ad.step2.start_validation' | translate}}</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="size-m">
                            <mat-label>{{'new_ad.step2.starttime_label' | translate}}</mat-label>
                            <input #publishstarttime matInput type="time" formControlName="publishstarttime" [placeholder]="'new_ad.step2.starttime_placeholder' | translate" required >
                            <mat-error>{{'new_ad.step2.starttime_validation' | translate}}</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="size-m">
                            <mat-label>{{'new_ad.step2.end_label' | translate}}</mat-label>
                            <input #publishend matInput type="date" formControlName="publishend" [placeholder]="'new_ad.step2.end_placeholder' | translate" required >
                            <mat-error>{{'new_ad.step2.end_validation' | translate}}</mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="size-m">
                            <mat-label>{{'new_ad.step2.endtime_label' | translate}}</mat-label>
                            <input #publishendtime matInput type="time" formControlName="publishendtime" [placeholder]="'new_ad.step2.endtime_placeholder' | translate" required >
                            <mat-error>{{'new_ad.step2.endtime_validation' | translate}}</mat-error>
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline" class="size-m">
                            <mat-label>{{'new_ad.step2.url_label' | translate}}</mat-label>
                            <input #url matInput formControlName="url" [placeholder]="'new_ad.step2.url_placeholder' | translate" required>
                            <mat-hint align="end">{{url.value?.length || 0}} / 1000</mat-hint>
                            <mat-error>{{'new_ad.step2.url_validation' | translate}}</mat-error>
                        </mat-form-field>

                        <div *ngIf="selectedAdType == 'ad-silver'" class="location-selector size-m">
                            <h3>{{'new_ad.step2.location_placeholder' | translate}}</h3>
                            <location-selector-azure (mapClicked)="setLocation($event)" [title]="streappFormGroup.get('title')?.value"></location-selector-azure>
                        </div>
                
                        <div class="button-actions">
                            <button mat-stroked-button matStepperPrevious>
                            <mat-icon>keyboard_arrow_left</mat-icon>
                            {{'general.previous' | translate | titlecase}}
                            </button>
                            <button mat-raised-button matStepperNext class="primary-button" [disabled]="!streappFormGroup.valid">
                            {{'general.next' | translate | titlecase}}
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            </button>
                        </div>
                        </div>
                    </form>
                </div>
                <div class="col-4 overview-item">
                    <ad-card [ad]="previewAd"></ad-card>
                </div>
            </div>

            <!-- PROMO -->
            <div *ngIf="selectedAdType == 'promo'" class="row">
                <div class="col-6">
                    <form [formGroup]="streappFormGroup">
                        <ng-template matStepLabel>{{'new_ad.step2.label' | translate}}</ng-template>
                        <div class="step2">
                            <h2>Gegevens card</h2>
                            <!-- Titel veld -->
                            <mat-form-field appearance="outline" class="size-m">
                                <mat-label>{{'new_ad.step2.title_label' | translate}}</mat-label>
                                <input #title matInput formControlName="title" [placeholder]="'new_ad.step2.title_placeholder' | translate" required>
                                <mat-hint align="end">{{title.value?.length || 0}} / 150</mat-hint>
                                <mat-error>{{'new_ad.step2.title_validation' | translate}}</mat-error>
                            </mat-form-field>
                    
                            <!-- Beschrijving veld -->
                            <mat-form-field appearance="outline" class="size-l">
                                <mat-label>{{'new_ad.step2.description_label' | translate}}</mat-label>
                                <textarea #description required maxlength="500" autosize rows="3" minRows="3" maxRows="10" formControlName="description" matInput [placeholder]="'new_ad.step2.description_placeholder' | translate"></textarea>
                                <mat-hint align="end">{{description.value?.length || 0}} / 500</mat-hint>
                                <mat-error>{{'new_ad.step2.description_validation' | translate}}</mat-error>
                            </mat-form-field>
                    
                            <!-- File drop veld -->
                            <ngx-file-drop (onFileDrop)="dropped($event)" [multiple]="false" [accept]="'image/*'" class="size-l file-drop" [disabled]="files.length > 0">
                                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                <div class="file-selector" *ngIf="files.length === 0" (click)="openFileSelector()">
                                    <mat-icon>cloud_upload</mat-icon>
                                    <span class="dz-btn">{{'new_ad.step2.dropzone_label' | translate}}</span>
                                </div>
                                <div class="file-selected" *ngIf="files.length !== 0">
                                    <div class="single-file" *ngFor=" let item of files; let i=index">
                                    <span class="upload-item-label">{{ item.relativePath }}</span>
                                    <span class="remove-item" typeof="button" (click)="removeFile(i)"
                                            [title]="'general.remove' | translate | titlecase">
                                        <mat-icon>close</mat-icon>
                                    </span>
                                    </div>
                                </div>
                                </ng-template>
                            </ngx-file-drop>

                            <mat-form-field appearance="outline" class="size-m">
                                <mat-label>{{'new_ad.step2.start_label' | translate}}</mat-label>
                                <input #publishstart matInput type="date" formControlName="publishstart" [placeholder]="'new_ad.step2.start_placeholder' | translate" required >
                                <mat-error>{{'new_ad.step2.start_validation' | translate}}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="size-m">
                                <mat-label>{{'new_ad.step2.starttime_label' | translate}}</mat-label>
                                <input #publishstarttime matInput type="time" formControlName="publishstarttime" [placeholder]="'new_ad.step2.starttime_placeholder' | translate" required >
                                <mat-error>{{'new_ad.step2.starttime_validation' | translate}}</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="size-m">
                                <mat-label>{{'new_ad.step2.end_label' | translate}}</mat-label>
                                <input #publishend matInput type="date" formControlName="publishend" [placeholder]="'new_ad.step2.end_placeholder' | translate" required >
                                <mat-error>{{'new_ad.step2.end_validation' | translate}}</mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="size-m">
                                <mat-label>{{'new_ad.step2.endtime_label' | translate}}</mat-label>
                                <input #publishendtime matInput type="time" formControlName="publishendtime" [placeholder]="'new_ad.step2.endtime_placeholder' | translate" required >
                                <mat-error>{{'new_ad.step2.endtime_validation' | translate}}</mat-error>
                            </mat-form-field>
                            
                            

                            <h2>Gegevens Advertentiepagina</h2>

                            <mat-form-field appearance="outline" class="size-m">
                                <mat-label>{{'new_ad.step2.pagetitle_label' | translate}}</mat-label>
                                <input #pagetitle matInput formControlName="pagetitle" [placeholder]="'new_ad.step2.pagetitle_placeholder' | translate" required>
                                <mat-hint align="end">{{pagetitle.value?.length || 0}} / 150</mat-hint>
                                <mat-error>{{'new_ad.step2.pagetitle_validation' | translate}}</mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="size-l">
                                <mat-label>{{'new_ad.step2.pagedescription_label' | translate}}</mat-label>
                                <textarea #pagedescription required maxlength="500" autosize rows="3" minRows="3" maxRows="10" formControlName="pagedescription" matInput [placeholder]="'new_ad.step2.pagedescription_placeholder' | translate"></textarea>
                                <mat-hint align="end">{{pagedescription.value?.length || 0}} / 500</mat-hint>
                                <mat-error>{{'new_ad.step2.pagedescription_validation' | translate}}</mat-error>
                            </mat-form-field>

                            <ngx-file-drop (onFileDrop)="droppedPage($event)" [multiple]="false" [accept]="'image/*,video/*'" class="size-l file-drop" [disabled]="pagefiles.length > 0">
                                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                                <div class="file-selector" *ngIf="pagefiles.length === 0" (click)="openFileSelector()">
                                    <mat-icon>cloud_upload</mat-icon>
                                    <span class="dz-btn">{{'new_ad.step2.dropzone_label' | translate}}</span>
                                </div>
                                <div class="file-selected" *ngIf="pagefiles.length !== 0">
                                    <div class="single-file" *ngFor=" let item of pagefiles; let i=index">
                                    <span class="upload-item-label">{{ item.relativePath }}</span>
                                    <span class="remove-item" typeof="button" (click)="removeFilePage(i)"
                                            [title]="'general.remove' | translate | titlecase">
                                        <mat-icon>close</mat-icon>
                                    </span>
                                    </div>
                                </div>
                                </ng-template>
                            </ngx-file-drop>

                            <mat-form-field appearance="outline" class="size-m">
                                <mat-label>{{'new_ad.step2.url_label' | translate}}</mat-label>
                                <input #url matInput formControlName="url" [placeholder]="'new_ad.step2.url_placeholder' | translate" required>
                                <mat-hint align="end">{{url.value?.length || 0}} / 1000</mat-hint>
                                <mat-error>{{'new_ad.step2.url_validation' | translate}}</mat-error>
                            </mat-form-field>
                            
                            <mat-form-field appearance="outline" class="size-m">
                                <mat-label>{{'new_ad.step2.pagebutton_label' | translate}}</mat-label>
                                <input #pagebutton matInput formControlName="pagebutton" [placeholder]="'new_ad.step2.pagebutton_placeholder' | translate" required>
                                <mat-hint align="end">{{pagebutton.value?.length || 0}} / 150</mat-hint>
                                <mat-error>{{'new_ad.step2.pagebutton_validation' | translate}}</mat-error>
                            </mat-form-field>

                            <h2>Aanmelden</h2>

                            <mat-checkbox #signup formControlName="signup" matInput></mat-checkbox>

                            <mat-form-field appearance="outline" class="size-l">
                                <mat-label>{{'new_ad.step2.signupdescription_label' | translate}}</mat-label>
                                <textarea #signupdescription maxlength="1500" autosize rows="5" minRows="5" maxRows="15" formControlName="signupdescription" matInput [placeholder]="'new_ad.step2.signupdescription_label' | translate"></textarea>
                                <mat-hint align="end">{{signupdescription.value?.length || 0}} / 1500</mat-hint>
                                <mat-error>{{'new_ad.step2.signupdescription_label' | translate}}</mat-error>
                            </mat-form-field>

                            <div class="location-selector size-m">
                                <h3>{{'new_ad.step2.location_placeholder' | translate}}</h3>
                                <location-selector-azure (mapClicked)="setLocation($event)" [title]="streappFormGroup.get('title')?.value"></location-selector-azure>
                            </div>

                            <div class="button-actions">
                                <button mat-stroked-button matStepperPrevious>
                                <mat-icon>keyboard_arrow_left</mat-icon>
                                {{'general.previous' | translate | titlecase}}
                                </button>
                                <button mat-raised-button matStepperNext class="primary-button" [disabled]="!streappFormGroup.valid">
                                {{'general.next' | translate | titlecase}}
                                <mat-icon>keyboard_arrow_right</mat-icon>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-4">
                    <div style="padding-top:20px">
                        <promo-card [promo]="previewPromo"></promo-card>
                    </div>
                </div>
            </div>

          </mat-step>
          <mat-step>
            <ng-template matStepLabel>{{'new_event.step3.label' | translate}}</ng-template>
            <div class="button-actions">
                <button *ngIf="selectedAdType == 'promo'" mat-raised-button (click)="createPromo()" class="primary-button publish-button" [disabled]="!streappFormGroup.valid || publishing">
                    {{'new_ad.step3.button_publish' | translate | titlecase}}
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
                <button *ngIf="selectedAdType == 'ad' || selectedAdType == 'ad-silver'" mat-raised-button (click)="createAd()" class="primary-button publish-button" [disabled]="!streappFormGroup.valid || publishing">
                    {{'new_ad.step3.button_publish' | translate | titlecase}}
                    <mat-icon>keyboard_arrow_right</mat-icon>
                </button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>


</div>