import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewEventFormComponent } from './new-event-form/new-event-form.component';
import { ComponentsModule } from '../../components/components.module';
import { EventTypeSelectorComponent } from './new-event-form/event-type-selector/event-type-selector.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { LocationSelectorAzureComponent } from './new-event-form/location-selector-azure/location-selector-azure.component';
import { SimilarEventsDialogComponent } from './new-event-form/similar-events-dialog/similar-events-dialog.component';
import { AddContentButtonComponent } from './add-content-button/add-content-button.component';
import { AddContentFormComponent } from './add-content-form/add-content-form.component';
import { PriceDialogComponent } from './new-event-form/price-dialog/price-dialog.component';
import { TwoDigitDecimalNumberDirective } from 'src/app/directives/two-digit-decimal-number.directive';
import { EditContentFormComponent } from './edit-content-form/edit-content-form.component';
import { EditContentButtonComponent } from './edit-content-button/edit-content-button.component';

@NgModule({
  declarations: [
    NewEventFormComponent,
    EventTypeSelectorComponent,
    LocationSelectorAzureComponent,
    SimilarEventsDialogComponent,
    AddContentButtonComponent,
    AddContentFormComponent,
    EditContentButtonComponent,
    EditContentFormComponent,
    PriceDialogComponent,
    TwoDigitDecimalNumberDirective
  ],
  imports: [CommonModule, ComponentsModule, NgxFileDropModule],
  exports: [
    NewEventFormComponent,
    LocationSelectorAzureComponent,
    AddContentButtonComponent,
    AddContentFormComponent,
    EditContentButtonComponent,
    EditContentFormComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CreateEventModule {}
