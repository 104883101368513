<div class="dialog report-dialog" *ngIf="!hasReported">
  <h2 mat-dialog-title class="header">
    {{ 'report.dialog_title' | translate }}
    <button class="close-ref" mat-icon-button mat-dialog-close [title]="'general.close' | translate | titlecase">
      <mat-icon>clear</mat-icon>
    </button>
  </h2>
  <div class="profile-content" mat-dialog-content>
    <span *ngIf="reportingEvent">{{'report.dialog_intro_event' | translate}}</span>
    <span *ngIf="reportingUser">{{'report.dialog_intro_user' | translate}}</span>
    <span *ngIf="reportingReaction">{{'report.dialog_intro_reaction' | translate}}</span>

    <mat-radio-group [(ngModel)]="selectedReason" class="radio-group">
      <mat-radio-button class="radio-button" *ngFor="let reason of reasons" [value]="reason">
        {{reason}}
      </mat-radio-button>
    </mat-radio-group>

    <form [formGroup]="reportFormGroup" *ngIf="selectedReason === 'Anders, namelijk:'">
      <mat-form-field appearance="outline" class="size-l">
        <mat-label>{{'report.message_label' | translate}}</mat-label>
        <textarea #message required maxlength="500" autosize rows="3" minRows="3" maxRows="10" matInput formControlName="message" [placeholder]="'report.message_placeholder' | translate"></textarea>
        <mat-hint align="end">{{message.value?.length || 0}} / 500</mat-hint>
        <mat-error>{{'report.message_validation' | translate}}</mat-error>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions class="dialog-buttons">
    <button class="close-ref" mat-flat-button mat-dialog-close [title]="'general.cancel' | translate">
      {{ 'general.cancel' | translate }}
    </button>
    <button class="close-ref" [disabled]="!selectedReason || (selectedReason === 'Anders, namelijk:' && !reportFormGroup.valid)" [color]="'accent'" mat-flat-button [title]="'feedback.send' | translate" (click)="report()">
      {{ 'feedback.send' | translate }}
    </button>
  </div>
</div>
<div class="dialog" *ngIf="hasReported">
  <h2 mat-dialog-title class="header">
    {{ 'report.dialog_title' | translate }}
    <button class="close-ref" mat-icon-button mat-dialog-close [title]="'general.close' | translate | titlecase">
      <mat-icon>clear</mat-icon>
    </button>
  </h2>
  <div class="profile-content" mat-dialog-content>
    {{ 'report.report_sent' | translate }}
  </div>
</div>
