<div class="row overview-actions filter-options">
  <div class="col-12">
    <button mat-button [title]="'overview.show_filter' | translate"
            [ngClass]="{'active': filterActive, 'filterOpen': filterOpen}" class="action-item" (click)="openFilter();">
      <mat-icon>filter_list</mat-icon>
      <span>{{ 'overview.filter' | translate }}</span>
    </button>
    <button *ngIf="showSearch" [title]="'overview.search' | translate" [ngClass]="{'active': searchOpen}" mat-button (click)="openSearch()" class="action-item">
      <mat-icon>search</mat-icon>
      <span>{{ 'overview.search' | translate }}</span>
    </button>
    <button *ngIf="showLocationSelector" title="{{ 'overview.settings' | translate }}" mat-button (click)="changeLocation()" class="action-item">
      <mat-icon>settings</mat-icon>
      <span>{{ 'overview.settings' | translate }}</span>
    </button>
    </div>
</div>
<div #filterbox class="filter" [hidden]="!filterOpen">
  <div class="col-xs-12 col-sm-6 col-lg-4 filter-box">
    <h2 mat-dialog-title class="">
      &nbsp;
      <button class="close-ref" mat-icon-button [title]="'general.close' | translate | titlecase" (click)="closeFilter()">
        <mat-icon>clear</mat-icon>
      </button>
    </h2>
    <div class="filterButtons">
      <button mat-button class="filter-item" [class.active]="reporterFilterActive" [title]="'filter.reporters' | translate" (click)="applyReportersFilter()">
        <mat-icon>photo_camera</mat-icon>
        <span>{{'filter.reporters' | translate}}</span>
      </button>
      <button mat-button class="filter-item" [title]="'filter.category' | translate" (click)="filterCatergoryClick()">
        <mat-icon> category</mat-icon>
        <span>{{'filter.category' | translate}}</span>
      </button>
      <div class="filtercontent" [hidden]="!categoryOpen">
        <mat-list>
          <mat-list-item *ngFor="let category of categories" (click)="waitOrApplyFilter()">
            <mat-checkbox #categoryFilterItem>{{ category.title }}</mat-checkbox>
          </mat-list-item>
        </mat-list>
      </div>
      <div class="filter-action-buttons">
        <button mat-flat-button color="accent" [disabled]="!filterActive && !reporterFilterActive" (click)="clearFilters()">
          wissen
        </button>
      </div>
    </div>
  </div>
</div>
<div #searchbox class="filter" [hidden]="!searchOpen">
  <div class="col-xs-12 col-sm-6 col-lg-4 filter-box">
    <h2 mat-dialog-title class="">
      &nbsp;
      <button class="close-ref" mat-icon-button [title]="'general.close' | translate | titlecase" (click)="closeSearch()">
        <mat-icon>clear</mat-icon>
      </button>
    </h2>
    <div class="filterButtons">
      <h4>{{'filter.go_to' | translate}}</h4>
      <button mat-button class="filter-item" [class.active]="false" [title]="'filter.my_location' | translate" (click)="goToMyLocation()">
        <mat-icon>location_on</mat-icon>
        <span>{{'filter.my_location' | translate}}</span>
      </button>
      <button mat-button class="filter-item" [title]="'filter.search_location' | translate" (click)="searchLocationClicked()">
        <mat-icon>navigation</mat-icon>
        <span>{{'filter.search_location' | translate}}</span>
      </button>
      <div class="filtercontent search" [hidden]="!searchFieldOpen">
        <location-search-field (locationChanged)="locationChanged($event)" [includePOI]="true"></location-search-field>
      </div>
    </div>
  </div>
</div>
