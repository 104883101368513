import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StreApperInfo } from 'src/app/models/streapper-info.model';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';

@Component({
  selector: 'app-reporters',
  templateUrl: './reporters.component.html',
  styleUrls: ['./reporters.component.scss']
})
export class ReportersComponent implements OnInit {

  loading: boolean = true;
  errorText: string;
  streappers: StreApperInfo[] = [];

  constructor(private readonly router: Router,
    private readonly streappService: StreappEventsService,
    private readonly authService: AuthService,
    ) { }

  ngOnInit(): void {

    this.streappService.getStreAppReporters().then(reporters => {
      this.streappers = reporters;
      this.loading = false;
    });
  }

}
