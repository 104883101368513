import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailPageComponent } from './detail-page/detail-page.component';
import { ComponentsModule } from '../../components/components.module';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { ReactionComponent } from './reaction/reaction.component';
import { PageAttributesComponent } from './detail-page/page-attributes/page-attributes.component';
import { LinkedEventsComponent } from './detail-page/linked-events/linked-events.component';
import { RelatedEventsComponent } from './detail-page/related-events/related-events.component';
import { P2000DetailPageComponent } from './p2000-detail-page/p2000-detail-page.component';
import { CreateEventModule } from '../create-event/create-event.module';


@NgModule({
  declarations: [DetailPageComponent,
    VideoPlayerComponent,
    ReactionComponent,
    PageAttributesComponent,
    LinkedEventsComponent,
    RelatedEventsComponent,
    P2000DetailPageComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    CreateEventModule
  ], exports: [
    DetailPageComponent,
    VideoPlayerComponent,
    ReactionComponent,
    P2000DetailPageComponent
  ]
})
export class DetailModule { }
