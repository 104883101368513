import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  private baseApiUrl: string;

  constructor(private httpService: HttpClient,
    private configService: AppConfigService) {
    this.baseApiUrl = this.configService.getConfig().baseApiUrl;
  }

  submitReport(eventId: string, reason: string, token: string, isUserReport: boolean = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      });

      var reportData = {
        'eventID': eventId,
        'reason': reason
      };

      this.httpService.post(`${this.baseApiUrl}Reporting/${isUserReport ? 'User' : 'Event'}`, reportData, { headers: headers, responseType: 'text' }).subscribe((result) => {
        resolve(true);
      }), error => {
        reject(error);
      }
    });
  }

  submitReactionReport(eventId: string, reactionId: string, reason: string, token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      });

      var reportData = {
        'eventID': eventId,
        'reactionID': reactionId,
        'reason': reason
      };

      this.httpService.post(`${this.baseApiUrl}Reporting/Reaction`, reportData, { headers: headers, responseType: 'text' }).subscribe((result) => {
        resolve(true);
      }), error => {
        reject(error);
      }
    });
  }
}
