<div class="desktop-width-limiter detail-page page-padding">
  <ng-container *ngIf="!loading && !error && profile">
    <div class="page-content">
      <div class="page-attributes">
        <div class="profile-image">
          <div class="banner-section">
            <img
              class="banner"
              [src]="profile.banner"
              onerror="this.src='/assets/images/fallback-banner.png';"
              [alt]="'Banner'"
            />
            <div class="image-section">
              <div class="user-image-holder">
                <img
                  [src]="getProfilePicture()"
                  onerror="this.src = '/assets/images/user.png';"
                  class="user-image"
                  [alt]="'Afbeelding reporter'"
                />
              </div>
              <span
                class="username"
                *ngIf="!!profile.name && profile.name !== ' '"
                >{{ profile.name }}</span
              >
              <span
                class="username"
                *ngIf="!profile.name || profile.name === ' '"
                >{{ profile.userName }}</span
              >
              <img
                class="reporter-logo"
                src="/assets/images/streapp-reporter-white-gold.png"
                [alt]="'StreApp Reporter'"
              />
            </div>
          </div>
        </div>
        <div class="info">
          <!-- About Me Section -->
          <div
            class="about-me-section-spacing col-12 col-sm-6 col-lg-8 col-xl-9"
          >
            <mat-card class="widget info-left">
              <div class="about-me">
                <h1 class="title">{{ "profile.about_me" | translate }}</h1>
                <span class="text" *ngIf="profile.profile">
                  {{ profile.profile }}
                </span>
                <span class="text" *ngIf="!profile.profile">
                  {{ "profile.about_me_missing" | translate }}
                </span>
              </div>
            </mat-card>
          </div>
          <div class="info-right col-12 col-sm-6 col-lg-4 col-xl-3">
            <!-- Geplaatste Streapps -->
            <div class="total-streapps-holder" *ngIf="profile.amountOfStreApps">
              <div class="total-streapps">
                <span class="number">{{ profile.amountOfStreApps }}</span>
                <span class="description">{{
                  "profile.streapps_posted" | translate
                }}</span>
              </div>
            </div>
            <div class="info-buttons">
              <!-- Rapporteer gebruiker -->
              <div class="report-button">
                <button
                  mat-icon-button
                  *ngIf="isLoggedIn()"
                  (click)="openReportingDialog()"
                >
                  <mat-icon>warning</mat-icon>
                  <span>{{ "report.report_profile" | translate }}</span>
                </button>
                <button
                  mat-icon-button
                  *ngIf="!isLoggedIn()"
                  (click)="openLoginPrompt()"
                >
                  <mat-icon>warning</mat-icon>
                  <span>{{ "report.report_profile" | translate }}</span>
                </button>
              </div>
              <!-- Deel profiel -->
              <div class="share-button">
                <button mat-icon-button (click)="share()">
                  <mat-icon>share</mat-icon>
                  <span *ngIf="!copied">{{ "profile.share" | translate }}</span>
                  <span *ngIf="copied">{{
                    "profile.share-copied" | translate
                  }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-reporter-group">
          <div class="filter-reporter">
            <button
              mat-button
              (click)="setFilter('showAll')"
              [class.selected]="currentFilter == 'showAll'"
            >
              {{ "filter.show_all" | translate }}
            </button>
          </div>
          <ng-container *ngFor="let filter of availableEventTypes">
            <div class="filter-reporter">
              <button
                mat-button
                (click)="setFilter(filter)"
                [class.selected]="currentFilter == filter"
              >
                {{ filter ? filter : "Onbekend" }}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="!loadingEvents && !errorText && filteredEvents.length > 0"
  >
    <div class="row overview-items">
      <div
        class="col-xs-12 col-sm-8 col-md-6 col-lg-4 col-xl-3 col-xxl-3 col-xxxl-2 overview-item-alternate"
        *ngFor="let event of filteredEvents"
      >
        <overview-card-alternate-version
          [event]="event"
        ></overview-card-alternate-version>
      </div>
      <div class="noMoreEvents">
        <span class="noMoreEventsLabel">{{
          "page.noMoreEvents" | translate
        }}</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="loading || loadingEvents">
    <div class="loading">
      <mat-progress-spinner
        class="loading-spinner"
        [diameter]="75"
        mode="indeterminate"
        color="primary"
      >
      </mat-progress-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading && error">
    <div class="errorText">
      {{ "page.error" | translate }}
    </div>
  </ng-container>
</div>
