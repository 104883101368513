<div class="image-wrapper" [class.active]="active" [title]="hoverText">
  <img class="media-image" [src]="getThumbSource()" />
  <span *ngIf="isVideo">
    <mat-icon>play_circle_outline</mat-icon>
  </span>
</div>
<div class="image-attributes ellipsified">
  <span *ngIf="category">{{category}}</span>
  <span *ngIf="created">{{created | dateToDescription}}</span>
</div>
