<div class="desktop-width-limiter overview-page page-padding">
  <profile-subnav [activeItem]="'my'"></profile-subnav>

  <ng-container *ngIf="!loading && !errorText && myEvents.length > 0">
    <div class="row overview-items">
      <div class="col-xs-12 col-sm-6 col-lg-4 overview-item" *ngFor="let event of myEvents">
        <overview-card [event]="event"></overview-card>
      </div>
      <div class="noMoreEvents">
        <span class="noMoreEventsLabel">{{ 'page.noMoreEvents' | translate }}</span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading && !errorText && myEvents.length === 0">
    <div class="errorText">
      {{'page.noVideosPersonal' | translate}}
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <div class="loading">
      <mat-progress-spinner class="loading-spinner" [diameter]="250" mode="indeterminate" color="primary">
      </mat-progress-spinner>
      <div class="loading-text"><h1>Starten...</h1></div>
    </div>
  </ng-container>
</div>
