<div [class.display-none]="loading">
  <video
    id="{{ videoId }}"
    #video
    class="azuremediaplayer amp-default-skin"
    controls
    *ngIf="!rawInput"
  >
    <p class="amp-no-js">
      To view this video please enable JavaScript, and consider upgrading to a
      web browser that supports HTML5 video
    </p>
  </video>
  <video
    width="100%"
    height="400px"
    class="azuremediaplayer video-player"
    controls
    *ngIf="rawInput"
    controlsList="nodownload"
    oncontextmenu="return false;"
    #rawVideoPlayer
    (play)="onRawVideoStarted()"
    preload="metadata"
    [attr.autoplay]="autoplay ? 'autoplay' : null"
    [poster]="poster"
  >
    <source src="{{ src }}" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
