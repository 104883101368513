<div class="dialog" *ngIf="!!data">
  <h2 mat-dialog-title [ngClass]="{ reporter: data.isReporter }" class="header">
    <span class="title ellipsified" [title]="data.title">{{ data.title }}</span>
    <button
      class="close-ref"
      mat-icon-button
      mat-dialog-close
      [title]="'general.close' | translate | titlecase"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </h2>
  <div mat-dialog-content class="preview-content">
    <div class="stats">
      <div class="left">
        <span>{{ data.created | date : "dd-MM-yyyy | HH:mm" }}</span>
      </div>
      <div>
        <span>{{ data.category }}</span>
      </div>
      <div class="right"><span>&nbsp;</span></div>
    </div>
    <img class="previewImage" [src]="getImageSource()" />
  </div>
  <div mat-dialog-actions class="dialog-buttons">
    <button
      class="close-ref"
      mat-flat-button
      color="primary"
      [title]="'general.close' | translate"
      (click)="close()"
    >
      {{ "general.close" | translate }}
    </button>
    <button
      class="close-ref"
      mat-flat-button
      color="accent"
      [title]="'general.view' | translate"
      (click)="closeWithConfirm()"
    >
      {{ "general.view" | translate }}
    </button>
  </div>
</div>
