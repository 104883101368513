import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { BaseOverviewLoader } from '../overview-base/overview-base';

@Component({
  selector: 'app-overview-112',
  templateUrl: './overview-112.component.html',
  styleUrls: ['./overview-112.component.scss']
})
export class Overview112Component extends BaseOverviewLoader implements OnInit, OnDestroy {
  isMobileView: boolean;
  hasScrolled: boolean = false;
  pageTitle: string = '112';
  showLocationFeatures: boolean = false;
  selectedRegion: string = '';

  @HostListener("window:scroll", []) onScroll(): void {
    this.hasScrolled = window.pageYOffset > 0;
  }

  readonly destroy$: Subject<Boolean> = new Subject();

  ngOnInit(): void {
    this.deviceService.isMobileView.pipe(
      takeUntil(
        this.destroy$
      )).subscribe((data: boolean) => {
        this.isMobileView = data;
      });

    this.selectedRegion = this.p2000Service.selectedRegion;

    this.activeRoute.queryParams.pipe(
      takeUntil(
        this.destroy$
      )).subscribe(params => {
        if (!!params.regio) {
          this.selectedRegion = params.regio;

          if (!!this.selectedRegion) {
            this.p2000Service.selectedRegion = this.selectedRegion;
          }
        }
      });

    this.loadNewRegion();
  }

  loadNewRegion(): void {
    if (!this.selectedRegion) {
      this.loading = false;
      return;
    }

    this.router.navigate(
      [],
      {
        relativeTo: this.activeRoute,
        queryParams: { regio: this.selectedRegion },
        queryParamsHandling: 'merge'
      });

    this.loading = true;

    this.loadAdsAnd112Alerts(this.selectedRegion);
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
