import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { MyStreAppsComponent } from './my-streapps/my-streapps.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ProfileSubnavComponent } from './_components/profile-subnav/profile-subnav.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';


@NgModule({
  declarations: [ProfileComponent, MyStreAppsComponent, ProfileSubnavComponent, ProfileSettingsComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    NgxFileDropModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProfileModule { }
