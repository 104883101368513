import { LOCALE_ID, NgModule } from '@angular/core';
import localeNl from '@angular/common/locales/nl';
import { AuthenticationOptions, AuthenticationType } from 'azure-maps-control';
import { AzureMapsModule } from 'ng-azure-maps';
import { AppComponent } from './app.component';
import { AppSharedModule } from './app.shared.module';
import { NgxPiwikProModule } from '@piwikpro/ngx-piwik-pro';
import { registerLocaleData } from '@angular/common';

const data = require('../assets/data/appConfig.json');

const options: AuthenticationOptions = {
  authType: AuthenticationType.subscriptionKey,
  subscriptionKey: data.azureKey,
};
registerLocaleData(localeNl, 'nl');

@NgModule({
  providers: [{ provide: LOCALE_ID, useValue: 'nl' }],
  imports: [
    AppSharedModule,
    NgxPiwikProModule.forRoot(
      '17516a56-ffc2-4cb6-a0b1-28bff56941b4',
      'https://seriouscode.containers.piwik.pro'
    ), // e926c2ab-a2a2-4870-af3c-0736b7b48a95 <-- dev key  17516a56-ffc2-4cb6-a0b1-28bff56941b4 <-- PROD key
    AzureMapsModule.forRoot({
      authOptions: options,
    }),
  ],
  exports: [AzureMapsModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
