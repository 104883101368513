import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { LoginComponent } from './login/login.component';
import { SignedOutComponent } from './signed-out/signed-out.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { AccessDeniedComponent } from './access-denied/access-denied.component';



@NgModule({
  declarations: [AuthCallbackComponent, LoginComponent, SignedOutComponent, AccessDeniedComponent],
  imports: [
    CommonModule,
    ComponentsModule
  ]
})
export class AuthModule { }
