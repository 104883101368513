<div class="desktop-width-limiter overview-page page-padding">
    <ng-container *ngIf="!loading && !errorText && streappers.length > 0">
        <div class="row overview-items">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 col-xxxl-2 overview-item" *ngFor="let streapper of streappers">
            <reporter-card [streapper]="streapper"></reporter-card>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="loading">
        <div class="loading">
          <mat-progress-spinner class="loading-spinner" [diameter]="250" mode="indeterminate" color="primary">
          </mat-progress-spinner>
          <div class="loading-text"><h1>Laden...</h1></div>
        </div>
      </ng-container>
</div>