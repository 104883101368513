import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VersionCheckService } from './version-check-service';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig: AppConfig;

  constructor(
    private translate: TranslateService,
    private readonly versionCheckService: VersionCheckService,
    @Inject(PLATFORM_ID) protected _platformId: Object
  ) {}

  loadAppConfig(): Promise<void> {
    return new Promise(async (resolve) => {
      if (isPlatformServer(this._platformId)) {
        var fs = require('fs');
        this.appConfig = JSON.parse(
          fs.readFileSync(
            'dist/streapp-website/browser/assets/data/appConfig.json',
            'utf-8'
          )
        );
      } else {
        let config = await window.fetch('/assets/data/appConfig.json');
        this.appConfig = JSON.parse(await config.text());
      }

      // Also do version check and pre-load the translation file
      this.versionCheckService.initVersionCheck(this.appConfig.versionCheckURL);

      // this language will be used as a fallback when a translation isn't found in the current language
      this.translate.setDefaultLang('nl');

      // the lang to use, if the lang isn't available, it will use the current loader to get them
      this.translate.use('nl');
      resolve();
    });
  }

  getConfig(): AppConfig {
    return this.appConfig;
  }
}

export interface AppConfig {
  production: boolean;
  baseApiUrl: string;
  authApiUrl: string;
  hostUrl: string;
  functionsUrl: string;
  versionCheckURL: string;
  shopUrl: string;
  piwikId: string;
  azureKey: string;
}
