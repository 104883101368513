import { Component, Input } from '@angular/core';
import { StreappEvent } from '../../../../models/streappevent.model';

@Component({
  selector: 'linked-events',
  templateUrl: './linked-events.component.html',
  styleUrls: ['./linked-events.component.scss']
})
export class LinkedEventsComponent  {
  @Input() event: StreappEvent;
}
