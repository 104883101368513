<mat-card class="widget widget-thumbnail" *ngIf="!!P2000Melding" [title]="P2000Melding.title" (click)="navigateToDetail()">
  <div class="video-preview">
    <img class="photo" [src]="P2000Melding.imageUrl"
         [alt]="P2000Melding.title" />
  </div>
  <div class="card-attributes">
    <div class="secondary-text description ellipsified ellipsified-3">{{P2000Melding.title}}</div>
    <div class="datetime ellipsified">
      {{P2000Melding.created | dateToDescription}} | {{P2000Melding.region}}
    </div>
  </div>
  <div class="actions">
    <div class="streapp-label"
         [class.prio1]="P2000Melding.prio === 1"
         [class.prio2]="P2000Melding.prio === 2"
         [class.prio3]="P2000Melding.prio === 3">
      <img src="/assets/images/Label_112.png" alt="112 melding" />
    </div>
  </div>
</mat-card>

