import { isPlatformServer } from '@angular/common';
import { Component, OnDestroy, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { DeviceService } from './services/device.service';
import { RobotsService } from './services/robots.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Streapp-Website';
  visible: boolean = false;
  isMobileDevice: boolean = false;
  isMobileView: boolean = false;

  readonly destroy$: Subject<Boolean> = new Subject();

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private readonly deviceService: DeviceService,
    private readonly robots: RobotsService,
    private readonly authService: AuthService,
    @Inject(PLATFORM_ID) private _platformId: Object) {
  }

  async ngOnInit(): Promise<void> {
    this.robots.setRobots(true);
    this.router.events.pipe(
      takeUntil(this.destroy$),
      filter(events => events instanceof NavigationEnd),
      map(evt => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }))
      .pipe(
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
    ).subscribe(x => x.hidelayout === true ? this.visible = false : this.visible = true);

    this.deviceService.isMobileView.pipe(
      takeUntil(this.destroy$)
    ).subscribe((data: boolean) => {
      this.isMobileView = data;
    });

    if (isPlatformServer(this._platformId)) {
      return;
    }
    
    if (window.location.href.indexOf('/video') <= -1) {
      this.isMobileDevice = this.deviceService.isAndroidDevice;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  continueToWebsite(): void {
    this.isMobileDevice = false;
  }
}
