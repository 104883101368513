<ng-container *ngIf="!loading && !error && !!videoUrl">
  <div class="video-player">
    <video-player
      [id]="'vid1'"
      [src]="videoUrl"
      [logoClass]="isReporter ? 'streapp-reporter' : 'streapp-user'"
      [autoplay]="true"
      [width]="'100%'"
      [height]="'400px'"
      [poster]="thumbnailUrl"
      [rawInput]="videoUrl.indexOf('manifest') === -1"
    >
    </video-player>
  </div>
</ng-container>

<ng-container *ngIf="loading">
  <div class="loading">
    <mat-progress-spinner
      class="loading-spinner"
      [diameter]="75"
      mode="indeterminate"
      color="primary"
    >
    </mat-progress-spinner>
  </div>
</ng-container>
