<div class="desktop-width-limiter overview-page overview-map-page" style="padding-top: 10px">
  <overview-filters
                    (filterChanged)="loadStreappEvents($event)"
                    (changeLocationClicked)="openSettingsDialog()"
                    (myLocationClicked)="resetMapToMyLocation()"
                    (newLocationSelected)="resetMapToNewLocation($event)"
                    [showLocationSelector]="true"
                    [showSearch]="true"></overview-filters>
  <div class="overview-maps">
    <azure-map #mapelement></azure-map>
  </div>

  <ng-container *ngIf="loading">
    <div class="loading">
      <mat-progress-spinner class="loading-spinner" [diameter]="250" mode="indeterminate" color="primary">
      </mat-progress-spinner>
      <div class="loading-text"><h1>Starten...</h1></div>
    </div>
  </ng-container>

  <ng-container *ngIf="errorText">
    <div class="errorText">
      {{'page.error' | translate}}
    </div>
  </ng-container>
</div>
