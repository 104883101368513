import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-required-location-dialog',
  templateUrl: './required-location-dialog.component.html',
  styleUrls: ['./required-location-dialog.component.scss']
})
export class RequiredLocationDialogComponent {

  constructor(private readonly dialogRef: MatDialogRef<RequiredLocationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: any) { }

  save(): void {
    this.dialogRef.close(true);
  }
}
