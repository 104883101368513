import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'edit-content-button',
  templateUrl: './edit-content-button.component.html',
  styleUrl: './edit-content-button.component.scss'
})
export class EditContentButtonComponent {
  @Input() eventId: string;
  @Input() userId: string;

  constructor(private readonly router: Router) {}

  ngOnInit(): void {}

  openEditContentPage(): void {
    this.router.navigate(['/edit-content', this.eventId]);
  }
}
