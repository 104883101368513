<div class="dialog">
  <h2 mat-dialog-title class="header">
    {{ "new_event.step_price_select.dialog_label" | translate }}
  </h2>
  <div class="dialog-content">
    <div class="input-description">
      {{ "new_event.step_price_select.dialog_input_text" | translate }}
    </div>
    <div class="price-input-field">
      €
      <input
        #price
        name="price"
        class="input"
        type="textbox"
        placeholder="00.00"
        [(ngModel)]="startPrice"
        (keydown.enter)="save(+price.value)"
        appTwoDigitDecimalNumber
      />
    </div>
    <div class="description">
      {{ "new_event.step_price_select.dialog_suggestion" | translate }} <b>€</b
      >{{ data.priceSuggestion }}
    </div>
  </div>
  <div mat-dialog-actions class="dialog-buttons">
    <button class="close-ref" mat-flat-button (click)="close()">
      {{ "general.cancel" | translate }}
    </button>
    <button
      class="close-ref confirm-button"
      mat-flat-button
      (click)="save(+price.value)"
      [disabled]="price.value == data.price"
    >
      {{ "general.confirm" | translate }}
    </button>
  </div>
</div>
