import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector, APP_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule, httpLoaderFactory } from './components/components.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { NavbarComponent } from './elements/navbar/navbar.component';
import { OverviewModule } from './modules/overview/overview.module';
import { DetailModule } from './modules/detail/detail.module';
import { ProfileModule } from './modules/profile/profile.module';
import { AuthModule } from './modules/auth/auth.module';
import { CreateEventModule } from './modules/create-event/create-event.module';
import { InfoModule } from './modules/info/info.module';
import { MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/legacy-dialog';
import { ReporterModule } from './modules/reporter/reporter.module';
import { VersionCheckService } from './services/version-check-service';
import { AdsModule } from './modules/ads/ads.module';
import { VideoModule } from './modules/video/video.module';
import { AppConfigService } from './services/app-config.service';
import { StatuscodesModule } from './modules/statuscodes/statuscodes.module';
import { VideoToThumbnailService } from './services/video-to-thumbnail.service';

const appInitializerFn = (appConfig: AppConfigService) => {
  return async () => {
    return await appConfig.loadAppConfig();
  };
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    HttpClientModule,
    OverviewModule,
    AdsModule,
    InfoModule,
    ProfileModule,
    ReporterModule,
    AuthModule,
    DetailModule,
    VideoModule,
    CreateEventModule,
    StatuscodesModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    })
  ],
  providers: [
    Title,
    VideoToThumbnailService,
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AppConfigService, Injector]
    },
    VersionCheckService,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true }},
    { provide: APP_ID, useValue: 'streapp-website' },

  ],
  bootstrap: [AppComponent]
})
export class AppSharedModule { }
