import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { P2000Discipline, P2000Melding } from '../models/p2000.model';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class P2000Service {
  private baseP2000ServiceUrl: string;
  selectedRegion: string = '';
  selectedItem: P2000Melding;

  constructor(private httpService: HttpClient,
    private readonly configService: AppConfigService) {
    this.baseP2000ServiceUrl = `${this.configService.getConfig().baseApiUrl}p2000/api/message/byregion?region=`;
  }

  loadP2000Messages(region: string = 'Hollands Midden'): Promise<Array<P2000Melding>> {
    this.selectedRegion = region;
    return new Promise((resolve, reject) => {
      this.httpService.get(`${this.baseP2000ServiceUrl}${region}&Page=1&PageSize=30`).subscribe((result: Array<P2000Melding>) => {
        let p2000Messages = [...result];

        p2000Messages.forEach(x => {
          let date = new Date(x.created);
          x.created = this.convertUTCDateToLocalDate(date);
          x.imageNumber = this.randomIntFromInterval(1, 6);
          x.prio = this.getP2000Priority(x);
          x.imageUrl = this.getP2000Image(x);
          x.disciplineName = this.getDisciplineAsString(x);
        });

        resolve(result);
      }, error => {
        reject(error);
      });
    });
  }

  private convertUTCDateToLocalDate(date) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  private randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  private getP2000Image(melding: P2000Melding): string {
    if (!melding.disciplines.length) {
      return '/assets/images/fallback-thumb.png';
    }

    // If message has LifeLiner discipline, make sure to show it
    if (melding.disciplines.filter(x => x.valueOf() === P2000Discipline.LifeLiner.valueOf()).length > 0) {
      return `/assets/images/p2000/P2000_lifeliner_${melding.imageNumber <= 3 ? '1' : '2'}.jpg`;
    }

    if (melding.disciplines[0].valueOf() === P2000Discipline.FireBrigade.valueOf()) {
      return `./assets/images/p2000/P2000_brandweer_${melding.imageNumber}.jpg`;
    } else if (melding.disciplines[0].valueOf() === P2000Discipline.Ambulance.valueOf()) {
      return `./assets/images/p2000/P2000_ambulance_${melding.imageNumber}.jpg`;
    } else if (melding.disciplines[0].valueOf() === P2000Discipline.Police.valueOf()) {
      return `./assets/images/p2000/P2000_politie_${melding.imageNumber}.jpg`;
    } else if (melding.disciplines[0].valueOf() === P2000Discipline.LifeLiner.valueOf()) {
      return `/assets/images/p2000/P2000_lifeliner_${melding.imageNumber <= 3 ? '1' : '2'}.jpg`;
    } else if (melding.disciplines[0].valueOf() === P2000Discipline.BRUG.valueOf()) {
      return `/assets/images/p2000/P2000_brug_${melding.imageNumber}.jpg`;
    }

    return '/assets/images/fallback-thumb.png';
  }

  private getDisciplineAsString(melding: P2000Melding): string {
    if (!melding.disciplines.length) {
      return 'Onbekend';
    }

    // If message has LifeLiner discipline, make sure to show it
    if (melding.disciplines.filter(x => x.valueOf() === P2000Discipline.LifeLiner.valueOf()).length > 0) {
      return 'Trauma Helikopter';
    }

    if (melding.disciplines[0].valueOf() === P2000Discipline.FireBrigade.valueOf()) {
      return 'Brandweer';
    } else if (melding.disciplines[0].valueOf() === P2000Discipline.Ambulance.valueOf()) {
      return 'Ambulance';
    } else if (melding.disciplines[0].valueOf() === P2000Discipline.Police.valueOf()) {
      return 'Politie';
    } else if (melding.disciplines[0].valueOf() === P2000Discipline.LifeLiner.valueOf()) {
      return 'Trauma Helikopter';
    } else if (melding.disciplines[0].valueOf() === P2000Discipline.BRUG.valueOf()) {
      return 'Brug';
    }

    return 'Onbekend';
  }

  private getP2000Priority(melding: P2000Melding): number {
    if (!melding.description) {
      return 3;
    } else if (melding.description.toLowerCase().indexOf("normale urgentie") > -1) {
      return 2;
    } else if (melding.description.toLowerCase().indexOf("hoge urgentie") > -1) {
      return 1;
    } else if (melding.title.toLowerCase().indexOf("prio 1") > -1) {
      return 1;
    } else if (melding.title.toLowerCase().indexOf("prio 2") > -1) {
      return 2;
    } else if (melding.title.toLowerCase().indexOf("prio 3") > -1) {
      return 3;
    }

    return 3;
  }
}
