<div class="dialog promo" *ngIf="!!data">
    <h2 mat-dialog-title class="header">
        <span class="center">{{ 'ads.name' | translate}}</span>
        <button class="close-ref" mat-icon-button mat-dialog-close [title]="'general.close' | translate | titlecase">
          <mat-icon>clear</mat-icon>
        </button>
      </h2>
      <div mat-dialog-content>
        <div class="title">
          <span>{{ data.title }}</span>
        </div>
        <img class="previewImage" [src]="getImageSource(data.imageUrl)" />
      </div>
      <div mat-dialog-actions class="dialog-buttons">
        <button class="close-ref" mat-flat-button color="primary" [title]="'general.close' | translate" (click)="close()">
          {{'general.close' | translate}}
        </button>
        <button class="close-ref green" mat-flat-button [title]="'general.view' | translate" (click)="closeWithConfirm()">
          {{'general.view' | translate}}
        </button>
      </div>
</div>
