import { Component, OnInit, ViewChild, ElementRef, HostListener, ViewChildren, EventEmitter, Output, Input, ViewEncapsulation } from '@angular/core';
import { EventType } from '../../../create-event/new-event-form/_models/eventtype.model';
import { StreappEventsService } from '../../../../services/streapp-events.service';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'overview-filters',
  templateUrl: './overview-filters.component.html',
  styleUrls: ['./overview-filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OverviewFiltersComponent implements OnInit {
  @ViewChild('filterbox') filterBoxElement: ElementRef;
  @ViewChild('searchbox') searchBoxElement: ElementRef;
  @ViewChildren('categoryFilterItem') categoryFilterItems: MatCheckbox[];

  @Input() showLocationSelector: boolean = false;
  @Input() showSearch: boolean = false;
  @Output() filterChanged: EventEmitter<string> = new EventEmitter();
  @Output() changeLocationClicked: EventEmitter<void> = new EventEmitter();
  @Output() myLocationClicked: EventEmitter<void> = new EventEmitter();
  @Output() newLocationSelected: EventEmitter<string> = new EventEmitter();

  searchOpen: boolean = false;
  filterOpen: boolean = false;
  categoryOpen: boolean = false;
  searchFieldOpen: boolean = false;
  isFiltered: boolean = false;
  filterActive: boolean = false;
  reporterFilterActive: boolean = false;
  preventFirstFilterOpen: boolean = false;
  preventFirstSearchOpen: boolean = false;
  // Category Filters
  categories: EventType[];

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.filterBoxElement.nativeElement.contains(event.target)) {
      if (this.preventFirstFilterOpen) {
        this.closeFilter();
      } else {
        this.preventFirstFilterOpen = true;
      }
    }

    if (!this.searchBoxElement.nativeElement.contains(event.target)) {
      if (this.preventFirstSearchOpen) {
        this.closeSearch();
      } else {
        this.preventFirstSearchOpen = true;
      }
    }
  }

  constructor(private readonly streappService: StreappEventsService) { }

  ngOnInit(): void {
    this.loadFilterOptions();
  }

  loadFilterOptions(): void {
    this.streappService.getEventTypes().then((eventTypes: EventType[]) => {
      this.categories = eventTypes;
    });
  }

  openSearch(): void {
    this.searchOpen = true;
  }

  openFilter(): void {
    this.filterOpen = true;
  }

  closeFilter(): void {
    this.filterOpen = false;
    this.preventFirstFilterOpen = false;
  }

  closeSearch(): void {
    this.searchOpen = false;
    this.preventFirstSearchOpen = false;
  }

  filterCatergoryClick(): void {
    this.categoryOpen = !this.categoryOpen;
  }

  searchLocationClicked(): void {
    this.searchFieldOpen = !this.searchFieldOpen;
  }

  clearFilters(): void {
    this.categoryFilterItems.forEach((element, index) => {
      element.checked = false;
    });
    this.reporterFilterActive = false;
    this.applyFilter();
  }

  timeout = null;
  waitOrApplyFilter() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.applyFilter();
    }, 500);
  }

  applyFilter(): void {
    var categoriesCheck: string[] = [];
    this.categoryFilterItems.forEach((element, index) => {
      if (element.checked) {
        categoriesCheck.push(this.categories[index].title);
      }
    });

    if (categoriesCheck.length > 0 || this.reporterFilterActive) {
      this.filterActive = true;
    } else {
      this.filterActive = false;
    }
    let filter: string = '';
    if (categoriesCheck.length > 0) {
      filter = 'categories={' + categoriesCheck.join('+') + '}';
    }
    if (this.reporterFilterActive) {
      filter += 'reportersOnly=true';
    }
    this.filterChanged.emit(filter);
  }

  applyReportersFilter(): void {
    this.reporterFilterActive = !this.reporterFilterActive;
    this.applyFilter();
  }

  changeLocation(): void {
    this.changeLocationClicked.emit();
  }

  goToMyLocation(): void {
    this.closeSearch();
    this.myLocationClicked.emit();
  }

  locationChanged(newLocation: string): void {
    this.newLocationSelected.emit(newLocation);
  }
}
