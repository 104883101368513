import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';

@Component({
  selector: 'app-ads-overview',
  templateUrl: './ads-overview.component.html',
  styleUrls: ['./ads-overview.component.scss']
})
export class AdsOverviewComponent implements OnInit {

  myAds: any;
  errorText: string;
  loading: boolean = true;

  constructor(private readonly streappService: StreappEventsService,
    private readonly authService: AuthService) { }

  ngOnInit(): void {
    this.streappService.getMyAds(this.authService.authorizationHeaderValue).then((data) => {
      this.myAds = data;
    }).catch((error) => {
      this.errorText = error;
    }).finally(() => {
      this.loading = false;
    });
  }

}
