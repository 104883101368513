<a *ngIf="!!ad" href="{{ ad.url }}" target="_blank" class="ad">
  <mat-card class="widget widget-thumbnail" [title]="ad.title">
  <div class="video-preview">
    <img Class="photo" [src]="ad.imageUrl ? ad.imageUrl : './assets/images/fallback-thumb.png'"
         [alt]="ad.title"
         onerror="this.onerror = null; this.src = './assets/images/fallback-thumb.png';" />
  </div>
  <div class="video-attributes">
    <div class="primary-text ellipsified">{{ad.title}}</div>
    <div class="secondary-text description ellipsified ellipsified-2">{{ad.description}}</div>
  </div>
  <div class="actions">
    <div class="streapp-label">
      <img src="/assets/images/Label_Ad.png" alt="Advertentie" />
    </div>
  </div>
  </mat-card>
</a>
