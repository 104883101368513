<div class="dialog settings">
  <h2 mat-dialog-title class="header">
    <span class="grow">{{ 'location_settings.title' | translate}}</span>
    <button class="close-ref" mat-icon-button mat-dialog-close [title]="'general.close' | translate | titlecase">
      <mat-icon>clear</mat-icon>
    </button>
  </h2>
  <div mat-dialog-content>
    <div class="title">
      <span>{{'location_settings.introduction' | translate }}</span>
    </div>
    <div class="checkbox-area">
      <mat-checkbox #useMyLocation (change)="myLocationChanged($event)">{{ 'location_settings.use_my_location' | translate }}</mat-checkbox>
      <mat-checkbox #useCustomLocation [(ngModel)]="showLocationSelector" (change)="customLocationChanged($event)">{{ 'location_settings.use_custom_location' | translate }}</mat-checkbox>
    </div>
    <div class="location-picker" *ngIf="showLocationSelector">
      <location-search-field [data]="location" (locationChanged)="onCustomLocationChanged($event)"></location-search-field>
    </div>
    <div class="location-error" *ngIf="locationError">
      {{'location_settings.no_permissions' | translate}}
    </div>
    <div class="location-radius" *ngIf="location || (useMyLocation.checked && !useCustomLocation.checked)">
      <div class="title">
        {{'location_settings.radius_intro' | translate}}
      </div>
      <div class="slider">
        <span>1 km</span>
        <mat-slider min="1" max="20" [(ngModel)]="locationRadius" thumbLabel tickInterval="1"></mat-slider>
        <span>20 km</span>
      </div>
    </div>
  </div>
  <div mat-dialog-actions class="dialog-buttons">
    <button class="close-ref" mat-flat-button mat-dialog-close [title]="'general.cancel' | translate">
      {{ 'general.cancel' | translate }}
    </button>
    <button class="close-ref" mat-flat-button [title]="'new_event.step5.button_publish' | translate" [disabled]="!location || (!useMyLocation.checked && !useCustomLocation.checked)"
            [color]="!!location ? 'accent' : 'primary'" (click)="save()">
      {{ 'new_event.step5.button_publish' | translate }}
    </button>
  </div>
</div>
