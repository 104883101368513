import { Component, Input } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReportingDialogComponent } from './reporting-dialog/reporting-dialog.component';

@Component({
  selector: 'reporting-button',
  templateUrl: './reporting-button.component.html',
  styleUrls: ['./reporting-button.component.scss']
})
export class ReportingButtonComponent {
  @Input() eventId: string;
  @Input() userId: string;
  @Input() buttonTitle: string = 'button_title';
  @Input() showText: boolean = true;

  constructor(private readonly dialog: MatDialog) { }

  openReportingDialog(): void {
    this.dialog.open<ReportingDialogComponent, any>(ReportingDialogComponent, {
      data: {
        eventId: this.eventId,
        userId: this.userId
      }
    });
  }
}
