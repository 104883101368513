import { Component, Input } from '@angular/core';
import { Ad } from 'src/app/models/ad.model';


@Component({
  selector: 'ad-card',
  templateUrl: './ad-card.component.html',
  styleUrls: ['./ad-card.component.scss']
})
export class AdCardComponent {
  @Input() ad: Ad;
}
