import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { StreappEvent } from 'src/app/models/streappevent.model';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';

@Component({
  selector: 'app-my-streapps',
  templateUrl: './my-streapps.component.html',
  styleUrls: ['./my-streapps.component.scss']
})
export class MyStreAppsComponent implements OnInit {

  myEvents: Array<StreappEvent> = [];
  loading: boolean = true;
  errorText: string;

  constructor(private readonly authService: AuthService,
    private readonly streappService: StreappEventsService,) { }

  ngOnInit(): void {
    this.loadStreappEvents();
  }

  loadStreappEvents(): void {
    this.streappService.getMyEvents(this.authService.authorizationHeaderValue).then((data) => {
      this.myEvents = data;
    }).catch((error) => {
      this.errorText = error;
    }).finally(() => {
      this.loading = false;
    });
  }
}
