import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ADS, PROMOS, StreappEventsService } from '../../../services/streapp-events.service';
import { AuthService } from '../../../services/auth.service';
import { HelpBoxService } from '../../../services/help-box.service';
import { TranslateService } from '@ngx-translate/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LocationService } from '../../../services/location.service';
import { LocationSettingsComponent } from '../../../components/location-settings/location-settings.component';
import { takeUntil } from 'rxjs/operators';
import { RequiredLocationDialogComponent } from '../../../components/location-settings/required-location-dialog/required-location-dialog.component';
import { StreappEvent } from '../../../models/streappevent.model';
import { AdDialogComponent } from '../../../components/ad-dialog/ad-dialog.component';
import { PromoDialogComponent } from '../../../components/promo-dialog/promo-dialog.component';
import { P2000Melding } from '../../../models/p2000.model';
import { P2000Service } from '../../../services/p2000.service';
import { DeviceService } from 'src/app/services/device.service';
import { isPlatformServer } from '@angular/common';
import { Ad, Promo } from 'src/app/models/ad.model';

@Component({
  template: ''
})
export abstract class BaseOverviewLoader {
  readonly destroy$: Subject<Boolean> = new Subject();


  loading: boolean = true;
  isServer: boolean = false;
  errorText: string;

  locationSet$: Subject<boolean> = new Subject<boolean>();
  currentLocation: { lat: number, long: number };
  currentLocationRadius: number;
  currentLocationFriendlyName: string;
  mapLocation: { lat: number, long: number };

  allEvents: Array<StreappEvent> = [];
  all112Events: Array<P2000Melding> = [];
  allAds: Array<any> = [];
  locationAds: Array<any> = [];
  allAdIndex: Array<Ad> = [];
  allMapMarkers: Array<any> = [];
  promo: Promo;
  promos: Array<Promo>;
  locationPromos: Array<any> = [];
  randAdIndex = 0;

  constructor(protected readonly router: Router,
    protected readonly streappService: StreappEventsService,
    protected readonly authService: AuthService,
    protected readonly helpBox: HelpBoxService,
    protected readonly translate: TranslateService,
    protected readonly dialog: MatDialog,
    protected readonly locationService: LocationService,
    @Inject(PLATFORM_ID) protected _platformId: Object,
    protected readonly deviceService: DeviceService,
    protected readonly p2000Service: P2000Service,
    protected readonly activeRoute: ActivatedRoute) {
      this.isServer = isPlatformServer(_platformId);
}

  get loggedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  checkForKnownLocation(): void {
    if (this.authService.isAuthenticated()) {
      this.authService.getClaimData().then((data) => {
        if (!!data.location) {
          // Load streapps based on this location, load map based on this location. 
          this.currentLocation = {
            long: +data.location.split(';')[0],
            lat: +data.location.split(';')[1]
          };

          this.locationSet$.next(true);

          this.mapLocation = {
            long: +data.location.split(';')[0],
            lat: +data.location.split(';')[1]
          };

          this.currentLocationFriendlyName = data.location;
          this.currentLocationRadius = +data.location.split(';')[3] || 10;

          this.loadAddsAndEvents();
        }
        else {
          this.openSettingsDialog();
        }
      });
    } else {
      if (!!this.locationService.location) {
        this.currentLocation = {
          long: this.locationService.location.long,
          lat: this.locationService.location.lat
        };

        this.mapLocation = {
          long: this.locationService.location.long,
          lat: this.locationService.location.lat
        };

        this.locationSet$.next(true);

        this.currentLocationRadius = this.locationService.location.radius;

        this.loadAddsAndEvents();
      } else {
        this.openSettingsDialog();
      }
    }
  }

  openSettingsDialog(): void {
    this.dialog.open<LocationSettingsComponent, any>(LocationSettingsComponent, {
      data: { location: this.currentLocationFriendlyName }
    }).afterClosed().pipe(takeUntil(this.destroy$)).subscribe(async (data: string) => {
      if (!!data) {
        this.currentLocation = {
          long: +data.split(';')[0],
          lat: +data.split(';')[1]
        };
        this.mapLocation = {
          long: +data.split(';')[0],
          lat: +data.split(';')[1]
        };
        this.locationSet$.next(true);

        this.currentLocationFriendlyName = data;
        this.currentLocationRadius = +data.split(';')[3] || 10;

        await this.loadAddsAndEvents();
      } else if (!this.currentLocation) {
        this.showSettingRequiredDialog();
      }
    });
  }

  showSettingRequiredDialog(): void {
    this.dialog.open<RequiredLocationDialogComponent, any>(RequiredLocationDialogComponent, {}
    ).afterClosed().pipe(takeUntil(this.destroy$)).subscribe((data: boolean) => {
      if (!!data) {
        this.openSettingsDialog();
      } else {
        // Otherwise navigate to home
        this.router.navigate(['/']);
      }
    });
  }

  async loadAddsAndEvents(): Promise<void> {
    await Promise.all([
      this.loadAds(),
      this.loadPromo(),
      this.loadStreappEvents()]
    );

    this.loading = false;
  }

  async loadAdsAnd112Alerts(region: string): Promise<void> {
    await Promise.all([
      this.loadAds(),
      this.load112Messages(region)
    ]);

    this.loading = false;
  }

  loadAds(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.streappService.getAds(ADS).then((data) => {
        this.allAds = data;
        this.locationAds = this.allAds.filter(ad => ad.latitude !== 0 && ad.longtitude !== 0).map((item: any) => {
          return {
            position: {
              lat: item.latitude,
              lng: item.longtitude,
            },
            label: {
              color: 'white',
              text: item.title,
            },
            title: item.description || item.title,
            event: item
          }
        });
        resolve();
      }).catch((error) => {
        this.errorText = error;
        reject(error);
      });
    });
  }

  loadPromo(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.streappService.getPromos(PROMOS).then((data) => {
        if (data && data.length > 0) {
          this.promos = data;
          this.promo = data[0];
          this.locationPromos = this.promos.filter(ad => ad.latitude !== 0 && ad.longtitude !== 0).map((item: any) => {
            return {
              position: {
                lat: item.latitude,
                lng: item.longtitude,
              },
              label: {
                color: 'white',
                text: item.title,
              },
              title: item.description || item.title,
              event: item
            }
          });

        }
        resolve();
      }).catch((error) => {
        this.errorText = error;
        reject(error);
      });
    });
  }

  loadStreappEvents(filter: string = ''): Promise<void> {
    return new Promise((resolve, reject) => {
      this.streappService.getEventsByUrl(
        this.router.url, filter,
        this.authService.authorizationHeaderValue,
        this.mapLocation?.lat || 0,
        this.mapLocation?.long || 0,
        this.currentLocationRadius || 10
      ).then((data) => {
        var totalEvents = data.length;
        var complete: boolean = false;
        let filledAds = 0;
        this.allAdIndex = [];

        while (!complete && data.length > 15) {
          var randomItem = Math.floor(Math.random() * totalEvents);

          if (randomItem > 10 && randomItem < totalEvents - 2) {
            if (!this.allAdIndex[randomItem] && !this.allAdIndex[randomItem + 1] && !this.allAdIndex[randomItem - 1]) {
              filledAds = filledAds + 1;
              this.allAdIndex[randomItem] = this.getRandomAd();
            }
          }

          if (filledAds == Math.floor(totalEvents / 7)) {
            complete = true;
          }
        }

        this.allEvents = data;
        this.mapMarkerData();

        resolve();

      }).catch((error) => {
        this.errorText = error;
        reject(error);
      });
    });
  }

  load112Messages(region: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.p2000Service.loadP2000Messages(region).then((data) => {
        var totalEvents = data.length;

        let filledAds = 0;
        this.allAdIndex = [];
        var complete: boolean = false;

        while (!complete && data.length > 15) {
          var randomItem = Math.floor(Math.random() * totalEvents);
          if (randomItem > 10 && randomItem < totalEvents - 2) {
            if (!this.allAdIndex[randomItem] && !this.allAdIndex[randomItem + 1] && !this.allAdIndex[randomItem - 1]) {
              filledAds = filledAds + 1;
              this.allAdIndex[randomItem] = this.getRandomAd();
            }
          }

          if (filledAds == Math.floor(totalEvents / 7)) {
            complete = true;
          }
        }

        this.all112Events = data;
        resolve();
      }).catch((error) => {
        this.errorText = error;
        reject(error);
      })
    });
  }

  getRandomAd() {
    return this.allAds[Math.floor(Math.random() * this.allAds.length)];
  }

  mapMarkerData(): void {
    this.allMapMarkers = this.allEvents.map((item: StreappEvent) => {
      return {
        position: {
          lat: item.latitude,
          lng: item.longtitude,
        },
        label: {
          color: 'white',
          text: item.title,
        },
        title: item.title || item.description,
        event: item
      }
    });

    // TODO filter multiple markers
  }

  getCountDown(promo): string {
    let now = new Date();
    let endDate = new Date(promo.publishEnd);
    if (now > endDate) {
      this.translate.get('promo.ended')
        .pipe(takeUntil(this.destroy$))
        .subscribe(text => {
          return text;
        })
    }

    var diff = Math.abs(endDate.getTime() - now.getTime());
    var diffDays = Math.floor(diff / (1000 * 3600 * 24));
    var diffHours = Math.floor(diff / (1000 * 3600)) - diffDays * 24;
    var diffMinutes = Math.floor((Math.ceil(diff / (1000)) - (diffDays * 24 * 3600) - (diffHours * 3600)) / 60);
    if (diffHours < 0) {
      diffHours = 23 + diffHours;
    }
    var diffMinutes = endDate.getMinutes() - now.getMinutes();
    if (diffMinutes < 0) {
      diffMinutes = 60 + diffMinutes;
    }

    return diffDays + ' dg : ' + diffHours + ' uur : ' + diffMinutes + ' min';
  }

  adClicked(ad: any): void {
    if (!ad.pageTitle) {
      this.dialog.open<AdDialogComponent, any>(AdDialogComponent, {
        data: ad
      }).afterClosed().pipe(takeUntil(this.destroy$)).subscribe((data: boolean) => {
        if (data) {
          window.open(ad.url, '_blank');
        }
      });

    } else {
      this.dialog.open<PromoDialogComponent, any>(PromoDialogComponent, {
        data: ad
      }).afterClosed().pipe(takeUntil(this.destroy$)).subscribe((data: boolean) => {
        if (data) {
          this.router.navigate(['/promo/', ad.id, 'default']);
        }
      });
    }
  }

  dialogOpen() {
    this.translate.get('help.new-event.disabled').pipe(takeUntil(this.destroy$)).subscribe(text => {
      this.helpBox.openBox(text);
    });
  }
}
