<div
  class="desktop-width-limiter detail-page page-padding"
  [class.d-none]="isServer"
>
  <ng-container *ngIf="!loading && !error && event">
    <mat-card class="widget">
      <h1 class="pageTitle">
        <span>{{ event.title }}</span>
      </h1>
      <div class="page-content">
        <div class="video-player-container">
          <div class="video-player">
            <download-button
              [downloadLink]="this.selectedMediaItem.url"
              *ngIf="
                isMyStreApp && selectedMediaItem.mediaType == MediaType.Image
              "
              [buttonTitle]="'download.download_selectedMediaItem'"
            ></download-button>
            <video-player
              *ngIf="
                selectedMediaItem &&
                selectedMediaItem.mediaType == MediaType.Video &&
                !selectedMediaItem.url
              "
              [id]="'vid1'"
              [src]=""
              [autoplay]="false"
              [width]="'100%'"
              [height]="isMobile ? '250px' : '400px'"
            >
              {{ selectedMediaItem | json }}
            </video-player>
            <!-- Below element is inserted twice so make sure change detection properly triggers when switching from video -->
            <video-player
              *ngIf="
                selectedMediaItem &&
                selectedMediaItem.mediaType == MediaType.Video &&
                selectedMediaItem.url &&
                selectedItem === 0
              "
              [id]="'vid1'"
              [videoId]="selectedMediaItem.partitionKey.replace('.', '_')"
              [src]="selectedMediaItem.url"
              [autoplay]="false"
              [width]="'100%'"
              [height]="isMobile ? '250px' : '400px'"
              [poster]="getThumbnailUrl(selectedMediaItem.thumbnail)"
              [isHighImpactEvent]="event.isHighImpactEvent"
              [rawInput]="selectedMediaItem.url.indexOf('manifest') === -1"
            >
              {{ selectedMediaItem | json }}
            </video-player>
            <video-player
              *ngIf="
                selectedMediaItem &&
                selectedMediaItem.mediaType == MediaType.Video &&
                selectedMediaItem.url &&
                selectedItem === 1
              "
              [id]="'vid1'"
              [videoId]="selectedMediaItem.partitionKey.replace('.', '_')"
              [src]="selectedMediaItem.url"
              [autoplay]="false"
              [width]="'100%'"
              [height]="isMobile ? '250px' : '400px'"
              [poster]="getThumbnailUrl(selectedMediaItem.thumbnail)"
              [isHighImpactEvent]="event.isHighImpactEvent"
              [rawInput]="selectedMediaItem.url.indexOf('manifest') === -1"
            >
              {{ selectedMediaItem | json }}
            </video-player>
            <!-- Video still processing error text -->
            <span class="processing-text" *ngIf="!selectedMediaItem.url">{{
              "page.detail.processing" | translate
            }}</span>
            <img
              class="media-image"
              *ngIf="
                selectedMediaItem &&
                selectedMediaItem.mediaType == MediaType.Image
              "
              [src]="selectedMediaItem.url"
            />
          </div>
          <page-attributes [event]="event"></page-attributes>
          <div class="ads-horizontal">
            <ins class="adsbygoogle"
              style="display:block"
              data-ad-client="ca-pub-1807328328271021"
              data-ad-slot="7063841727"
              data-ad-format="horizontal"
              data-full-width-responsive="true"></ins>
          </div>
          <image-carousel
            [mediaItemArray]="event.mediaItems"
            [selectedMediaItem]="selectedMediaItem"
            (mediaItemClicked)="selectMediaItem($event)"
            *ngIf="event.mediaItems.length > 1"
          ></image-carousel>
          <div class="description">
            <h2>{{ "page.detail.description" | translate }}</h2>
            <span>{{ event.description }}</span>
          </div>
          <reaction
            [items]="reactions"
            (addReaction)="addReaction($event)"
            (removeReaction)="removeReaction($event)"
            (updateReaction)="updateReaction($event)"
            [eventId]="event.partitionKey"
          ></reaction>
        </div>
        <div class="report-container">
          <!-- Rapporteer StreApp -->
          <add-content-button
            *ngIf="isMyStreApp"
            [eventId]="event.partitionKey"
          ></add-content-button>
          <edit-content-button
            *ngIf="isMyStreApp"
            [eventId]="event.partitionKey"
          ></edit-content-button>
          <reporting-button
            [eventId]="event.partitionKey"
            *ngIf="isLoggedIn() && !isMyStreApp"
            [buttonTitle]="'report.report_event'"
          ></reporting-button>
        </div>
        <div class="linked-events">
          <!-- Hier komen gekoppelde streapps, of relevante streapps -->
          <linked-events
            *ngIf="event.linkedStreappEvents?.length > 0"
            [event]="event"
          ></linked-events>
          <related-events
            *ngIf="event.linkedStreappEvents?.length === 0"
            [event]="event"
          ></related-events>
        </div>
      </div>
    </mat-card>
    <scroll-up *ngIf="hasScrolled"></scroll-up>
  </ng-container>

  <ng-container *ngIf="loading">
    <div class="loading">
      <mat-progress-spinner
        class="loading-spinner"
        [diameter]="75"
        mode="indeterminate"
        color="primary"
      >
      </mat-progress-spinner>
    </div>
  </ng-container>

  <ng-container *ngIf="!loading && error">
    <div class="errorText">
      {{ "page.error" | translate }}
    </div>
  </ng-container>
</div>
