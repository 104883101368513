import { Component, OnInit, Input, Output, EventEmitter, Injector, Type, Inject, PLATFORM_ID } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { distinctUntilChanged, filter, debounceTime } from 'rxjs/operators';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { HttpClient } from '@angular/common/http';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'location-search-field',
  templateUrl: './location-search-field.component.html',
  styleUrls: ['./location-search-field.component.scss']
})
export class LocationSearchFieldComponent implements OnInit {
  @Input() data: string;
  @Input() includePOI: boolean = false;

  location: string;
  locationName: string;
  locationFormGroup: UntypedFormGroup;
  availableOptions: Array<{ name: string, value: string }> = [];

  @Output() locationChanged: EventEmitter<string> = new EventEmitter();

  private searchService: any;
  private extendedPostalCodes: any;

  constructor(private readonly formBuilder: UntypedFormBuilder,
    httpClient: HttpClient,
    @Inject(PLATFORM_ID) protected _platformId: Object
    ) { 
      if (isPlatformServer(this._platformId)) {
        return;
      }
        
      import( 'ng-azure-maps').then(azure => {
        const options: any = {
          authType: 'subscriptionKey',
          subscriptionKey: 'SROjwQ2cy75Xj0GHK5hcjAoPMSAGa7s3ozx1C9mm0RQ'
        };
        azure.setAzureMapsConfiguration({
          authOptions: options
        });
        this.searchService = new azure.SearchService(httpClient);
        
        this.extendedPostalCodes = azure.ExtendedPostalCodes;
      })
    }

  ngOnInit(): void {
    this.locationFormGroup = this.formBuilder.group({
      customLocation: ['', [Validators.required, Validators.maxLength(150)]]
    });

    if (this.data) {
      this.locationFormGroup.controls['customLocation'].setValue(this.data.split(';')[2]);
      this.location = this.data;
    }

    this.locationFormGroup.get('customLocation')!.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      filter((name) => !!name)
    ).subscribe((value) => {
      this.getOptions(value);
    });
  }

  getOptions(value: string): void {
    if (this.includePOI) {
      this.searchFuzzy(value);
    } else {
      this.searchAddress(value);
    }
  }

  searchAddress(value: string): void {
    this.searchService.searchAddress(value, {
      countrySet: ['NL'],
      extendedPostalCodesFor: [this.extendedPostalCodes.Streets],
      radius: 100000
    }).subscribe(results => {
      this.availableOptions = [];
      if (results.summary?.totalResults > 0) {
        this.availableOptions = results.results.map((result) => {
          return {
            name: result.address.freeformAddress, value: `${result.position.lon};${result.position.lat}`
          }
        });
      }

      if (this.availableOptions.length === 0) {
        this.availableOptions.push({ name: 'Geen locatie gevonden die voldoet', value: null });
      }
    });
  }

  searchFuzzy(value: string): void {
    this.searchService.searchFuzzy(value, {
      countrySet: ['NL'],
      extendedPostalCodesFor: [this.extendedPostalCodes.Streets],
      radius: 100000
    }).subscribe(results => {
      this.availableOptions = [];
      if (results.summary?.totalResults > 0) {
        this.availableOptions = results.results.map((result) => {
          return {
            name: result.type === 'POI' ? `${result.poi.name}, ${result.address.freeformAddress}` : result.address.freeformAddress,
            value: `${result.position.lon};${result.position.lat}`
          }
        });
      }

      if (this.availableOptions.length === 0) {
        this.availableOptions.push({ name: 'Geen locatie gevonden die voldoet', value: null });
      }
    });
  }

  selectOption(event: MatAutocompleteSelectedEvent) {
    this.location = event.option.value;
    this.locationName = event.option.viewValue;

    this.locationChanged.emit(`${this.location};${this.locationName}`);
  }

  displayFn(value?: string) {
    return value ? (!!this.availableOptions.find(_ => _.value === value)?.name ? this.availableOptions.find(_ => _.value === value)?.name : value) : value;
  }
}
