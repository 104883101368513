import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { StreappEventsService } from 'src/app/services/streapp-events.service';
import { ProfileDialogData } from './models/profile-dialog-data.model';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss'],
})
export class ProfileDialogComponent implements OnInit {
  reporter: boolean = false;
  picture: SafeUrl;
  name: string;
  amount: Number;

  constructor(
    private readonly dialogRef: MatDialogRef<ProfileDialogComponent>,
    private readonly streappService: StreappEventsService,
    private domSanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public readonly data: ProfileDialogData
  ) {}

  ngOnInit(): void {
    if (!this.data.createdBy) {
      this.reporter = false;
    } else {
      this.streappService.getStreapperInfo(this.data.createdBy).then((info) => {
        this.name = info.name;
        this.amount = info.amountOfStreApps;
        this.reporter = info.isReporter;
        if (info.picture) {
          if (info.picture.startsWith('http')) {
            this.picture = info.picture;
          } else {
            this.picture = this.domSanitizer.bypassSecurityTrustUrl(
              'data:image/*;base64,' + info.picture
            );
          }
        }
      });
    }
  }

  closeWithConfirm(): void {
    this.dialogRef.close(true);
  }

  close(): void {
    this.dialogRef.close(false);
  }

  getProfilePicture() {
    if (this.picture) {
      return this.picture;
    }

    return '/assets/images/no-profile-image.png';
  }
}
