export interface P2000Melding {
  title: string;
  description: string;
  created: Date;
  disciplines: P2000Discipline[];
  disciplineName?: string;
  region: string;
  imageNumber?: number;
  prio?: number;
  imageUrl?: string;
}

export enum P2000Discipline {
  FireBrigade = 0,
  Ambulance = 1,
  Police = 2,
  KNRM = 3,
  BRUG = 4,
  LifeLiner = 5
}
