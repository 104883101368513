import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'image-block',
  templateUrl: './image-block.component.html',
  styleUrls: ['./image-block.component.scss']
})
export class ImageBlockComponent implements OnInit {
  @Input() thumbnail: string;
  @Input() active: boolean;
  @Input() hoverText: string;
  @Input() category: string;
  @Input() created: Date;

  isVideo: boolean;

  constructor() { }

  ngOnInit(): void {
    // Video thumbnails are stored as base64 images, image thumbnails are URL's
    this.isVideo = !this.validURL(this.thumbnail);
  }

  getThumbSource(): string {
    if (this.validURL(this.thumbnail)) {
      return this.thumbnail;
    } else {
      return 'data:image/jpeg;base64,' + this.thumbnail;
    }
  }

  private validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }
}
