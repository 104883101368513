<div
  class="desktop-width-limiter add-content-page page-padding"
  *ngIf="!loading"
>
  <mat-stepper [linear]="true" orientation="horizontal" #stepper>
    <ng-template matStepperIcon="edit" let-index="index">
      {{ index + 1 }}
    </ng-template>
    <mat-step
      [completed]="
        preUploadedMediaItems.length > 0 || preUploadedShopMediaItems.length > 0
      "
    >
      <ng-template matStepLabel>{{
        "new_event.step_media_upload.label" | translate
      }}</ng-template>
      <h2 *ngIf="isReporter">
        {{ "new_event.step_media_upload.streapp_title" | translate }}
      </h2>
      <!-- File drop veld -->
      <div class="upload-field">
        <div
          class="image-description"
          *ngIf="!!linkedEvent"
          [innerHTML]="'new_event.step4.description_comment' | translate"
        ></div>
        <ngx-file-drop
          (onFileDrop)="dropped($event, 'none')"
          [multiple]="true"
          [accept]="'video/*,image/*'"
          class="size-m file-drop"
          [disabled]="preUploadedMediaItems.length >= 10"
        >
          <ng-template
            ngx-file-drop-content-tmp
            let-openFileSelector="openFileSelector"
          >
            <div
              class="file-selector"
              *ngIf="preUploadedMediaItems.length < 10"
              (click)="openFileSelector()"
            >
              <span class="upload-field-text">{{
                "new_event.step4.dropzone_label" | translate
              }}</span>
            </div>
            <div
              class="max-files-selected"
              *ngIf="preUploadedMediaItems.length >= 10"
            >
              <span class="dz-btn">{{
                "new_event.step4.dropzone_full_label" | translate
              }}</span>
            </div>
          </ng-template>
        </ngx-file-drop>
        <!-- File counter with maximum of 10 files -->
        <div class="totalFilesUploaded">
          {{ preUploadedMediaItems.length }} / 10
        </div>
      </div>
      <div class="preview-content">
        <div class="uploadDescriptionField">
          {{ "new_event.step_media_upload.upload_description" | translate }}
        </div>
        <!-- Filepreview Holders -->
        <div class="preview-holder">
          <div
            class="preview"
            *ngFor="let item of numSequence(10); let i = index"
          >
            <ng-container *ngIf="preUploadedMediaItems[i]">
              <img
                class="preview-image-holder"
                [src]="preUploadedMediaItems[i].previewFile"
                onerror="this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='"
                alt="uploaded-image"
              />
              <div class="darkenImage"></div>
              <span
                class="remove-item"
                typeof="button"
                (click)="removeFile(i, 'none')"
                [title]="'general.remove' | translate | titlecase"
              >
                <mat-icon>delete</mat-icon>
              </span>
            </ng-container>
            <ng-container *ngIf="!preUploadedMediaItems[i]">
              <img
                class="preview-image-holder-empty"
                src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              />
            </ng-container>
          </div>
        </div>
      </div>
      <!-- Filepreview Holders Shop-only -->
      <ng-container *ngIf="isReporter">
        <h2 class="shop-only-content-title">
          {{ "new_event.step_media_upload.shop_only_title" | translate }}
        </h2>
        <!-- File drop veld -->
        <div class="upload-field">
          <div
            class="image-description"
            *ngIf="!!linkedEvent"
            [innerHTML]="'new_event.step4.description_comment' | translate"
          ></div>
          <ngx-file-drop
            (onFileDrop)="dropped($event, 'shop')"
            [multiple]="true"
            [accept]="'video/*,image/*'"
            class="size-m file-drop"
            [disabled]="preUploadedShopMediaItems.length >= 10"
          >
            <ng-template
              ngx-file-drop-content-tmp
              let-openFileSelector="openFileSelector"
            >
              <div
                class="file-selector"
                *ngIf="preUploadedShopMediaItems.length < 10"
                (click)="openFileSelector()"
              >
                <span class="upload-field-text">{{
                  "new_event.step4.dropzone_label" | translate
                }}</span>
              </div>
              <div
                class="max-files-selected"
                *ngIf="preUploadedShopMediaItems.length >= 10"
              >
                <span class="dz-btn">{{
                  "new_event.step4.dropzone_full_label" | translate
                }}</span>
              </div>
            </ng-template>
          </ngx-file-drop>
          <!-- File counter with maximum of 10 files -->
          <div class="totalFilesUploaded">
            {{ preUploadedShopMediaItems.length }} / 10
          </div>
        </div>
        <div class="preview-content">
          <div class="uploadDescriptionField">
            {{
              "new_event.step_media_upload.upload_description_shop" | translate
            }}
          </div>
          <div class="preview-holder">
            <div
              class="preview"
              *ngFor="let item of numSequence(10); let i = index"
            >
              <ng-container *ngIf="preUploadedShopMediaItems[i]">
                <img
                  class="preview-image-holder"
                  [src]="preUploadedShopMediaItems[i].previewFile"
                  onerror="this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='"
                  alt="uploaded-image"
                />
                <div class="darkenImage"></div>
                <span
                  class="remove-item"
                  typeof="button"
                  (click)="removeFile(i, 'shop')"
                  [title]="'general.remove' | translate | titlecase"
                >
                  <mat-icon>delete</mat-icon>
                </span>
              </ng-container>
              <ng-container *ngIf="!preUploadedShopMediaItems[i]">
                <img
                  class="preview-image-holder-empty"
                  src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                />
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="button-actions">
        <button
          class="primary-button nextStep"
          mat-raised-button
          matStepperNext
          [disabled]="
            preUploadedMediaItems.length == 0 &&
            preUploadedShopMediaItems.length == 0
          "
        >
          {{ "general.next" | translate | titlecase }}
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </mat-step>
    <ng-container *ngIf="isReporter">
      <mat-step>
        <ng-template matStepLabel>{{
          "new_event.step_price_select.label" | translate
        }}</ng-template>
        <!-- All uploaded files with prices/suggested prices -->
        <ng-container *ngIf="preUploadedMediaItems.length > 0">
          <h2 class="price-title">
            {{ "new_event.step_media_upload.streapp_title" | translate }}
          </h2>
          <div class="price-suggestion-holder">
            <div
              class="price-suggestion-item"
              *ngFor="let item of preUploadedMediaItems; let i = index"
              [ngStyle]="{
                'background-image': 'url(' + item.previewFile + ')'
              }"
              (click)="openPriceSelectionWindow(i, 'none')"
            >
              <button
                class="price-suggestion-button"
                type="button"
                mat-raised-button
                (click)="openPriceSelectionWindow(i, 'none')"
              >
                <span *ngIf="!item.priceChanged"
                  >{{
                    "new_event.step_price_select.suggestion_label" | translate
                  }}
                  {{ item.suggestedPrice }} <b>€</b></span
                >
                <span *ngIf="item.priceChanged">
                  {{ item.price }} <b>€</b></span
                >
              </button>
            </div>
            <div class="advice-description">
              {{ "new_event.step_price_select.advice_description" | translate }}
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="preUploadedShopMediaItems.length > 0">
          <h2 class="price-title shop-only-content-title">
            {{ "new_event.step_media_upload.shop_only_title" | translate }}
          </h2>
          <div class="price-suggestion-holder">
            <!-- Shop only items -->
            <div
              class="price-suggestion-item"
              *ngFor="let item of preUploadedShopMediaItems; let i = index"
              [ngStyle]="{
                'background-image': 'url(' + item.previewFile + ')'
              }"
              (click)="openPriceSelectionWindow(i, 'shop')"
            >
              <button
                class="price-suggestion-button"
                type="button"
                mat-raised-button
                (click)="openPriceSelectionWindow(i, 'shop')"
              >
                <span *ngIf="!item.priceChanged"
                  >{{
                    "new_event.step_price_select.suggestion_label" | translate
                  }}
                  {{ item.suggestedPrice }} <b>€</b></span
                >
                <span *ngIf="item.priceChanged">
                  {{ item.price }} <b>€</b></span
                >
              </button>
            </div>
            <div class="advice-description">
              {{ "new_event.step_price_select.advice_shop" | translate }}
            </div>
          </div>
        </ng-container>
        <div class="button-actions">
          <button class="previousStep" mat-stroked-button matStepperPrevious>
            <mat-icon>keyboard_arrow_left</mat-icon>
            {{ "general.previous" | translate | titlecase }}
          </button>
          <button
            class="primary-button nextStep confirm"
            mat-raised-button
            matStepperNext
          >
            {{ "general.confirm" | translate | titlecase }}
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </mat-step>
    </ng-container>
    <mat-step>
      <ng-template matStepLabel>{{
        "new_event.add_content_page_content" | translate
      }}</ng-template>
      <form [formGroup]="streappFormGroup">
        <div class="step4">
          <!-- Existing content in streapp -->
          <div class="existing-content">
            <span class="existing-content-title">{{
              isReporter
                ? ("new_event.add_content_page_existing_content" | translate)
                : ("new_event.add_content_page_existing_content_normal"
                  | translate)
            }}</span>
            <image-carousel
              [mediaItemArray]="event.mediaItems"
              *ngIf="event.mediaItems.length > 0"
            ></image-carousel>

            <!-- New Streapp Only Content -->
            <ng-container *ngIf="preUploadedMediaItems.length > 0">
              <span class="new-content-title">
                {{ "new_event.add_content_page_new_content" | translate }}
              </span>
              <div class="preview-holder">
                <div class="preview" *ngFor="let item of preUploadedMediaItems">
                  <img
                    class="preview-image-holder"
                    [src]="item.previewFile"
                    onerror="this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='"
                    alt="uploaded-image"
                  />
                  <mat-icon class="check-circle-outline" *ngIf="item.finished"
                    >check_circle_outline</mat-icon
                  >
                </div>
              </div>
            </ng-container>

            <!-- New Shop Only Content -->
            <ng-container *ngIf="preUploadedShopMediaItems.length > 0">
              <span class="new-content-title">
                {{ "new_event.add_content_page_new_shop_content" | translate }}
              </span>
              <div class="preview-holder">
                <div
                  class="preview"
                  *ngFor="let item of preUploadedShopMediaItems"
                >
                  <img
                    class="preview-image-holder"
                    [src]="item.previewFile"
                    onerror="this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='"
                    alt="uploaded-image"
                  />
                  <mat-icon class="check-circle-outline" *ngIf="item.finished"
                    >check_circle_outline</mat-icon
                  >
                </div>
              </div>
            </ng-container>
          </div>

          <div class="button-actions">
            <button class="previousStep" mat-stroked-button (click)="cancel()">
              {{ "general.cancel" | translate | titlecase }}
            </button>
            <button
              id="submit"
              mat-raised-button
              (click)="publish()"
              class="primary-button"
              [disabled]="!streappFormGroup.valid || publishing"
              *ngIf="!publishing"
            >
              {{ "new_event.step5.button_publish" | translate | titlecase }}
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <span *ngIf="publishing" class="publishing-text">
              <mat-progress-spinner
                class="loading-spinner"
                [diameter]="30"
                mode="indeterminate"
              ></mat-progress-spinner>
              <span
                >{{ "new_event.busy_adding_content" | translate }}
                {{ getProgress() }}</span
              >
            </span>
          </div>
        </div>
      </form>
    </mat-step>
  </mat-stepper>
</div>
