import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AuthService } from '../../../services/auth.service';
import { StreappReaction } from '../../../models/streappreaction.model';

@Component({
  selector: 'reaction',
  templateUrl: './reaction.component.html',
  styleUrls: ['./reaction.component.scss']
})
export class ReactionComponent implements OnInit {
  @Input() items: Array<StreappReaction>;
  @Input() loading: boolean;
  @Input() reactionsAllowed: boolean = true;
  @Input() exportAllowed: boolean = false;
  @Input() eventId: string;

  @Output() exportReactions: EventEmitter<void> = new EventEmitter();
  @Output() addReaction: EventEmitter<string> = new EventEmitter();
  @Output() updateReaction: EventEmitter<{ text: string, id: string }> = new EventEmitter();
  @Output() removeReaction: EventEmitter<string> = new EventEmitter();
  @Output() loadMore: EventEmitter<{}> = new EventEmitter();

  user: string;
  editableItem: string;

  reactionForm: UntypedFormGroup = new UntypedFormGroup({
    text: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(1000),
      Validators.minLength(3)
    ])
  });

  reactionReplyForm: UntypedFormGroup = new UntypedFormGroup({
    text: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(1000),
      Validators.minLength(3)
    ])
  });

  constructor(private readonly sanitizer: DomSanitizer,
    private readonly authService: AuthService) {
  }

  ngOnInit() {
    this.user = this.authService.userId;
  }

  profileImageUrl(uuid: string): string {
    return '/assets/images/user.png';
  }

  sanitizedText(message: string): SafeHtml | any {
    return this.sanitizer.bypassSecurityTrustHtml(this.stripHtmlChars(message).replace(/\r?\n/g, '<br />'));
  }

  stripHtmlChars(text): string {
    const regex: any = /(<([^>]+)>)/ig;
    return text.replace(regex, '');
  }

  editItem(itemId: string): void {
    this.editableItem = itemId;
  }

  formSubmit(): void {
    this.addReaction.emit(this.reactionForm.controls.text.value);
    this.reactionForm.reset();
  }

  deleteReaction(id: string): void {
    this.removeReaction.emit(id);
  }

  addEnter(event): void {
    event.preventDefault();
    this.reactionForm.controls['text'].patchValue(this.reactionForm.controls.text.value + '\n');
  }

  doLoadMore(): void {
    this.loadMore.emit(true);
  }

  export(): void {
    this.exportReactions.emit();
  }

  editReaction(): void {    
    this.updateReaction.emit({ text: this.reactionReplyForm.controls.text.value, id: this.editableItem });
    this.editableItem = null;
  }

  cancelUpdate(): void {
    this.editableItem = null;
  }

  isLoggedIn(): boolean {
    return this.authService.isAuthenticated();
  }
} 
