import { Component, Input } from '@angular/core';
import { StreappEvent } from '../../../../models/streappevent.model';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'page-attributes',
  templateUrl: './page-attributes.component.html',
  styleUrls: ['./page-attributes.component.scss'],
})
export class PageAttributesComponent {
  @Input() event: StreappEvent;

  constructor(
    private readonly router: Router,
    private domSanitizer: DomSanitizer
  ) {}

  navigateToReporter(): void {
    if (this.event.isReporter) {
      this.router.navigate(['/reporters/detail'], {
        queryParams: { id: this.event.createdBy },
      });
    } else {
      this.router.navigate(['/users/detail'], {
        queryParams: { id: this.event.createdBy },
      });
    }
  }

  getEventProfilePicture() {
    if (this.event.createdByImage) {
      if (this.event.createdByImage.startsWith('http')) {
        return this.event.createdByImage;
      }
      return this.domSanitizer.bypassSecurityTrustUrl(
        'data:image/*;base64,' + this.event.createdByImage
      );
    }
  }
}
