import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss']
})
export class DownloadButtonComponent implements OnInit {
  @Input() downloadLink : string;
  @Input() buttonTitle: string = 'button_title';

  constructor() { }

  ngOnInit(): void {
  }

  download() : void {
    window.open(this.downloadLink, '_blank');
  }

}
