import { Pipe, PipeTransform } from "@angular/core";
import { formatDate } from "@angular/common";

@Pipe({
  name: 'dateToDescription'
})
export class DateToDescriptionPipe implements PipeTransform {
  transform(value: string) {
    let d = new Date(value);
    let now = new Date();
    let previousDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()-1)
    let seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));

    let minutes = Math.round(Math.abs(seconds / 60));
    let hours = Math.round(Math.abs(minutes / 60));
    let days = Math.round(Math.abs(hours / 24));
    let months = Math.round(Math.abs(days / 30.416));
    let years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return 'een paar seconden geleden';
    } else if (seconds <= 90) {
      return 'een minuut geleden';
    } else if (minutes <= 45) {
      return minutes + ' minuten geleden';
    } else if (minutes <= 90) {
      return 'een uur geleden';
    } else if ((hours <= 23 && days == 0) || (days == 1 && hours <= 12)) {
      return hours + ' uur geleden';
    } else if (days == 1 && (d > previousDay)) {
      return 'gisteren';
    } else if (days <= 25) {
      return days + ' dagen geleden';
    } else if (days <= 45) {
      return 'vorige maand';
    } else if (days <= 345) {
      return months + ' maanden geleden';
    } else if (days <= 545) {
      return 'een jaar geleden';
    } else { // (days > 545)
      return years + ' jaar geleden';
    }
  }
}

