import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserManager, UserManagerSettings, User } from 'oidc-client-ts';
import { BehaviorSubject, throwError } from 'rxjs';
import { StreappEventsService } from './streapp-events.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserProfile } from '../models/profile.model';
import { AppConfigService } from './app-config.service';
import { isPlatformServer } from '@angular/common';
import { profile } from 'console';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseApiUrl: string;
  private authUrl: string;
  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();

  private manager;
  private user: User | null;
  private userImageUrl: SafeUrl;

  userProfile: UserProfile;

  constructor(
    private http: HttpClient,
    private readonly configService: AppConfigService,
    private readonly streappService: StreappEventsService,
    private readonly domSanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    this.baseApiUrl = this.configService.getConfig().baseApiUrl;
    this.authUrl = this.configService.getConfig().authApiUrl;

    this.manager = new UserManager(this.getClientSettings());

    this.manager.getUser().then((user) => {
      this.user = user;
      this._authNavStatusSource.subscribe(() => {
        if (this.email && !this.userImageUrl) {
          this.streappService
            .getStreapperInfo(this.email)
            .then((profileInfo) => {
              if (profileInfo.picture) {
                if (profileInfo.picture.startsWith('http')) {
                  this.userImageUrl =
                    profileInfo.picture + '?t=' + new Date().getTime();
                } else {
                  this.userImageUrl = this.domSanitizer.bypassSecurityTrustUrl(
                    'data:image/*;base64,' + profileInfo.picture
                  );
                }
              }
            });
        }
      });

      this._authNavStatusSource.next(this.isAuthenticated());
    });
  }

  getClaimData(forceUpdate: boolean = false): Promise<UserProfile> {
    if (!!this.userProfile && !forceUpdate) {
      return Promise.resolve(this.userProfile);
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authorizationHeaderValue,
      }),
    };

    return new Promise((resolve, reject) => {
      this.http.get(`${this.baseApiUrl}account/claims`, httpOptions).subscribe(
        (result: any) => {
          const profileInfo: UserProfile = {
            firstName: result.find(
              (k) =>
                k.type ===
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'
            )?.value,
            lastName: result.find(
              (k) =>
                k.type ===
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'
            )?.value,
            userName: result.find((k) => k.type === 'preferred_username')
              ?.value,
            email: result.find(
              (k) =>
                k.type ===
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'
            )?.value,
            birthDay: result
              .find(
                (k) =>
                  k.type ==
                  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/dateofbirth'
              )
              ?.value.substring(0, 10),
            isReporter:
              result.find((k) => k.type === 'reporter').value == 'true',
            location: result.find((k) => k.type === 'location').value,
            userId: result.find((k) => k.type === 'id')?.value,
          };
          this.userProfile = profileInfo;
          resolve(profileInfo);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  updateProfile(formData): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.authorizationHeaderValue,
      }),
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.authApiUrl}/api/account/update`, formData, httpOptions)
        .subscribe(
          (result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  uploadProfilePicture(formData): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.authorizationHeaderValue,
      }),
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.authApiUrl}/api/account/picture`, formData, httpOptions)
        .subscribe(
          (result: any) => {
            this.userImageUrl =
              this.userImageUrl + '?t=' + new Date().getTime();
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  uploadBanner(formData): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.authorizationHeaderValue,
      }),
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.authApiUrl}/api/account/banner`, formData, httpOptions)
        .subscribe(
          (result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  removeBanner(): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.authorizationHeaderValue,
      }),
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.authApiUrl}/api/account/remove-banner`, null, httpOptions)
        .subscribe(
          (result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  removeProfilePicture(): Promise<void> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.authorizationHeaderValue,
      }),
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.authApiUrl}/api/account/remove-picture`,
          null,
          httpOptions
        )
        .subscribe(
          (result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  get authApiUrl(): string {
    return this.authUrl;
  }

  login(): Promise<void> {
    return this.manager.signinRedirect();
  }

  async loginSilent() {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    this.user = await this.manager.signinSilent();
    this._authNavStatusSource.next(this.isAuthenticated());
  }

  completePopup() {
    this.manager.signinPopupCallback();
  }

  async completeAuthentication() {
    this.user = await this.manager.signinRedirectCallback();
    this._authNavStatusSource.next(this.isAuthenticated());
  }

  async completeSilentAuthentication() {
    this.user = await this.manager.signinSilentCallback();
    this._authNavStatusSource.next(this.isAuthenticated());
  }

  isAuthenticated(): boolean {
    return this.user != null && !this.user.expired;
  }

  get authorizationHeaderValue(): string {
    if (!this.user || this.user.expired) {
      return '';
    }
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  get name(): string {
    return this.user != null ? this.user.profile.given_name : '';
  }

  get userId(): string {
    return this.user != null ? (this.user.profile.id as string) : '';
  }

  get email(): string {
    return this.user != null ? this.user.profile.email : '';
  }

  get userName(): string {
    return this.user != null ? this.user.profile.preferred_username : '';
  }

  get pictureUrl(): SafeUrl | string {
    return this.userImageUrl || './assets/images/user.png';
  }

  get isReporter(): boolean {
    if (this.user == null || this.user.expired) {
      return false;
    }
    return this.user.profile.reporter as boolean;
  }

  get isAds(): boolean {
    if (this.user == null || this.user.expired) {
      return false;
    }
    if (Array.isArray(this.user.profile.role)) {
      if (this.user.profile.role.includes('Ads')) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.user.profile.role === 'Ads') {
        return true;
      } else {
        return false;
      }
    }
  }

  get isShop(): boolean {
    if (this.user == null || this.user.expired) {
      return false;
    }
    if (Array.isArray(this.user.profile.role)) {
      return this.user.profile.role.includes('Shop');
    } else {
      return this.user.profile.role === 'Shop';
    }
  }

  get isAdmin(): boolean {
    if (this.user == null || this.user.expired) {
      return false;
    }
    if (Array.isArray(this.user.profile.role)) {
      return this.user.profile.role.includes('Admin');
    } else {
      return this.user.profile.role === 'Admin';
    }
  }

  get roles(): string[] {
    if (!this.isAuthenticated()) {
      return [''];
    }

    if (this.user == null || this.user.expired) {
      return [''];
    } else {
      if (!Array.isArray(this.user.profile.role)) {
        return [this.user.profile.role as string];
      } else {
        return this.user.profile.role;
      }
    }
  }

  get isAdult(): boolean {
    var date = new Date();
    date.setFullYear(new Date().getFullYear() - 16);
    return this.user != null
      ? new Date(this.user.profile.birthdate) <= date
      : false;
  }

  async signout(): Promise<void> {
    await this.manager.signoutRedirect();
  }

  protected handleError(error: any) {
    var applicationError = error.headers.get('Application-Error');

    // either application-error in header or model error in body
    if (applicationError) {
      return throwError(applicationError);
    }

    var modelStateErrors: string = '';

    // for now just concatenate the error descriptions, alternative we could simply pass the entire error response upstream
    for (var key in error.error) {
      if (error.error[key])
        modelStateErrors += error.error[key].description + '\n';
    }

    modelStateErrors = modelStateErrors = '' ? null : modelStateErrors;
    return throwError(modelStateErrors || 'Server error');
  }

  getClientSettings(): UserManagerSettings {
    return {
      authority: this.configService.getConfig().authApiUrl,
      client_id: 'angular',
      redirect_uri: this.configService.getConfig().hostUrl + 'auth-callback',
      post_logout_redirect_uri:
        this.configService.getConfig().hostUrl + 'signed-out',
      response_type: 'code',
      scope: 'openid profile',
      filterProtocolClaims: true,
      loadUserInfo: true,
      automaticSilentRenew: true,
      silent_redirect_uri:
        this.configService.getConfig().hostUrl + 'silent-refresh.html',
    };
  }
}
