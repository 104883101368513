<div
  class="desktop-width-limiter edit-content-page page-padding"
  *ngIf="!loading"
>
  <mat-stepper [linear]="true" orientation="horizontal" #stepper>
    <ng-template matStepperIcon="edit" let-index="index">
      {{ index + 1 }}
    </ng-template>
    <mat-step>
      <ng-template matStepLabel>{{
        "new_event.step3.label" | translate
      }}</ng-template>

      <div class="step3">
        <div class="location-selector size-m">
          <location-selector-azure
            [defaultLocation]="[event.longtitude, event.latitude]"
            (mapClicked)="setLocation($event)"
            (locationChanged)="setLocationFriendlyName($event)"
          ></location-selector-azure>
        </div>

        <div class="button-actions">
          <button
            class="primary-button nextStep"
            mat-raised-button
            matStepperNext
          >
            {{ "general.next" | translate | titlecase }}
            <mat-icon>keyboard_arrow_right</mat-icon>
          </button>
        </div>
      </div>
    </mat-step>
    <mat-step [stepControl]="streappFormGroup">
      <ng-template matStepLabel>{{
        "new_event.edit.data_header" | translate
      }}</ng-template>
      <div class="formContent">
        <h2>{{ "new_event.step4.data_header" | translate }}</h2>
        <form [formGroup]="streappFormGroup">
          <!-- Titel veld -->
          <mat-form-field appearance="outline" class="size-s">
            <mat-label>{{
              "new_event.step4.titel_label" | translate
            }}</mat-label>
            <input
              id="title"
              #titel
              matInput
              formControlName="titel"
              [placeholder]="'new_event.step4.titel_placeholder' | translate"
              required
            />
            <mat-hint align="end"
              >{{ titel.value?.length || 0 }} / 150</mat-hint
            >
            <mat-error>{{
              "new_event.step4.titel_validation" | translate
            }}</mat-error>
          </mat-form-field>
          <!-- Beschrijving veld -->
          <mat-form-field appearance="outline" class="size-m">
            <mat-label>{{
              "new_event.step4.description_label" | translate
            }}</mat-label>
            <textarea
              id="description"
              #description
              required
              maxlength="5000"
              autosize
              rows="3"
              minRows="3"
              maxRows="10"
              formControlName="beschrijving"
              matInput
              [placeholder]="
                'new_event.step4.description_placeholder' | translate
              "
            ></textarea>
            <mat-hint align="end"
              >{{ description.value?.length || 0 }} / 5000</mat-hint
            >
            <mat-error>{{
              "new_event.step4.description_validation" | translate
            }}</mat-error>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            class="size-s"
            *ngIf="!linkedEvent"
          >
            <mat-label>{{
              "new_event.step4.date_label" | translate
            }}</mat-label>
            <input
              [min]="minDate"
              [max]="maxDate"
              type="datetime-local"
              matInput
              name="datumTijd"
              formControlName="datumTijd"
              required
            />
            <mat-error>{{
              "new_event.step4.date_validation" | translate
            }}</mat-error>
          </mat-form-field>
          <!-- Tags veld -->
          <mat-form-field
            appearance="outline"
            class="size-s"
            *ngIf="!linkedEvent"
          >
            <mat-label>{{
              "new_event.step4.tags_label" | translate
            }}</mat-label>
            <input
              id="tags"
              #tags
              matInput
              formControlName="tags"
              [placeholder]="'new_event.step4.tags_placeholder' | translate"
            />
            <mat-hint align="end">{{ tags.value?.length || 0 }} / 200</mat-hint>
            <mat-error>{{
              "new_event.step4.tags_validation" | translate
            }}</mat-error>
          </mat-form-field>

          <h2>{{ "new_event.step4.heftigEvent_header" | translate }}</h2>
          <mat-form-field appearance="outline" class="size-xs">
            <mat-select
              id="adultContent"
              formControlName="heftigEvent"
              [placeholder]="
                'new_event.step4.heftigEvent_placeholder' | translate
              "
              required
            >
              <mat-option [value]="'ja'"> Ja </mat-option>
              <mat-option [value]="'nee'"> Nee </mat-option>
            </mat-select>
            <mat-error>{{
              "new_event.step4.heftigEvent_validation" | translate
            }}</mat-error>
          </mat-form-field>

          <ng-container *ngIf="showAdditionalQuestions">
            <h2>{{ "new_event.step4.gewonden_header" | translate }}</h2>
            <mat-form-field appearance="outline" class="size-xs">
              <mat-select
                id="wounded"
                formControlName="gewonden"
                [placeholder]="
                  'new_event.step4.gewonden_placeholder' | translate
                "
                required
              >
                <mat-option [value]="'ja'"> Ja </mat-option>
                <mat-option [value]="'nee'"> Nee </mat-option>
                <mat-option [value]="'onbekend'"> Weet ik niet </mat-option>
              </mat-select>
              <mat-error>{{
                "new_event.step4.gewonden_validation" | translate
              }}</mat-error>
            </mat-form-field>

            <h2>
              {{ "new_event.step4.hulpdiensten_header" | translate }}
              <button
                type="button"
                mat-icon-button
                color="primary"
                [matTooltip]="'new_event.step4.hulpdiensten_help' | translate"
                matTooltipPosition="above"
              >
                <mat-icon>info_outline</mat-icon>
              </button>
            </h2>
            <mat-form-field appearance="outline" class="size-xs">
              <mat-select
                id="emergencyServices"
                formControlName="hulpdiensten"
                [placeholder]="
                  'new_event.step4.hulpdiensten_placeholder' | translate
                "
                required
              >
                <mat-option [value]="'ja'"> Ja </mat-option>
                <mat-option [value]="'nee'"> Nee </mat-option>
              </mat-select>
              <mat-error>{{
                "new_event.step4.hulpdiensten_validation" | translate
              }}</mat-error>
            </mat-form-field>
            <h3
              class="warning-hulpdiensten"
              *ngIf="streappFormGroup.get('hulpdiensten')?.value === 'nee'"
            >
              {{ "new_event.step4.hulpdiensten_warning" | translate }}
            </h3>
          </ng-container>
        </form>
      </div>
      <div class="button-actions">
        <button class="previousStep" mat-stroked-button matStepperPrevious>
          <mat-icon>keyboard_arrow_left</mat-icon>
          {{ "general.previous" | translate | titlecase }}
        </button>
        <button
          class="primary-button nextStep"
          mat-raised-button
          matStepperNext
          [disabled]="!streappFormGroup.valid"
        >
          {{ "general.next" | translate | titlecase }}
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>{{
        "new_event.edit.content_preview_header" | translate
      }}</ng-template>
      <div class="content-comparison-block">
        <!-- Current Content -->
        <div class="current-content-block">
          <h2>
            {{ "new_event.edit.existing_content" | translate }}
          </h2>
          <div class="content-container">
            <!-- title -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.titel_label" | translate }}
              </div>
              <div class="individual-content-value">
                {{ originalStreappContent.title }}
              </div>
            </div>
            <!-- description -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.description_label" | translate }}
              </div>
              <div class="individual-content-value">
                {{ originalStreappContent.description }}
              </div>
            </div>
            <!-- datetime -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.date_label" | translate }}
              </div>
              <div class="individual-content-value">
                {{ originalStreappContent.dateTime | dateToDescription }}
              </div>
            </div>
            <!-- tags -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.tags_label" | translate }}:
              </div>
              <div class="individual-content-value">
                {{ originalStreappContent.tags }}
              </div>
            </div>
            <!-- coordinates -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step3.coordinates_label" | translate }}:
              </div>
              <div class="individual-content-value">
                {{ originalStreappContent.location }}
              </div>
            </div>
            <!-- location -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step3.location_label" | translate }}:
              </div>
              <div class="individual-content-value">
                {{ originalStreappContent.locationStreet }},
                {{ originalStreappContent.locationCity }}
              </div>
            </div>
            <!-- heftig -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.heftigEvent_header" | translate }}
              </div>
              <div class="individual-content-value">
                {{ originalStreappContent.isHighImpact }}
              </div>
            </div>
            <!-- injuries -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.gewonden_header" | translate }}
              </div>
              <div class="individual-content-value">
                {{ originalStreappContent.injuriesPresent }}
              </div>
            </div>
            <!-- emergency services -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.hulpdiensten_header" | translate }}
              </div>
              <div class="individual-content-value">
                {{ originalStreappContent.emergencyServicesPresent }}
              </div>
            </div>
          </div>
        </div>
        <!-- Changed Content -->
        <div class="changed-content-block">
          <h2>
            {{ "new_event.edit.new_content" | translate }}
          </h2>
          <div class="content-container">
            <!-- title -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.titel_label" | translate }}
              </div>
              <div class="individual-content-value">
                {{ streappFormGroup.get("titel")?.value }}
              </div>
            </div>
            <!-- description -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.description_label" | translate }}
              </div>
              <div class="individual-content-value">
                {{ streappFormGroup.get("beschrijving")?.value }}
              </div>
            </div>
            <!-- datetime -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.date_label" | translate }}
              </div>
              <div class="individual-content-value">
                {{
                  streappFormGroup.get("datumTijd")?.value | dateToDescription
                }}
              </div>
            </div>
            <!-- tags -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.tags_label" | translate }}:
              </div>
              <div class="individual-content-value">
                {{ streappFormGroup.get("tags")?.value }}
              </div>
            </div>
            <!-- coordinates -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step3.coordinates_label" | translate }}:
              </div>
              <div class="individual-content-value">
                {{ streappFormGroup.get("location")?.value }}
              </div>
            </div>
            <!-- location -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step3.location_label" | translate }}:
              </div>
              <div class="individual-content-value">
                {{ selectedLocation }}
              </div>
            </div>
            <!-- heftig -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.heftigEvent_header" | translate }}
              </div>
              <div class="individual-content-value">
                {{ streappFormGroup.get("heftigEvent")?.value }}
              </div>
            </div>
            <!-- injuries -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.gewonden_header" | translate }}
              </div>
              <div class="individual-content-value">
                {{ streappFormGroup.get("gewonden")?.value }}
              </div>
            </div>
            <!-- emergency services -->
            <div class="individual-content-container">
              <div class="individual-content-title">
                {{ "new_event.step4.hulpdiensten_header" | translate }}
              </div>
              <div class="individual-content-value">
                {{ streappFormGroup.get("hulpdiensten")?.value }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-actions">
        <button
          class="previousStep"
          mat-stroked-button
          matStepperPrevious
          *ngIf="!publishing"
        >
          <mat-icon>keyboard_arrow_left</mat-icon>
          {{ "general.previous" | translate | titlecase }}
        </button>
        <button
          id="submit"
          mat-raised-button
          (click)="publish()"
          class="primary-button"
          [disabled]="!streappFormGroup.valid || publishing"
          *ngIf="!publishing"
        >
          {{ "new_event.step5.button_publish" | translate | titlecase }}
          <mat-icon>keyboard_arrow_right</mat-icon>
        </button>
        <span *ngIf="publishing" class="publishing-text">
          <mat-progress-spinner
            class="loading-spinner"
            [diameter]="30"
            mode="indeterminate"
          ></mat-progress-spinner>
          <span>{{ "new_event.edit.updating" | translate }}</span>
        </span>
      </div>
    </mat-step>
  </mat-stepper>
</div>
