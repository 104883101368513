import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ComponentsModule,
  httpLoaderFactory,
} from 'src/app/components/components.module';
import { ReportersComponent } from './reporters/reporters.component';
import { ReporterCardComponent } from './reporters/reporter-card/reporter-card.component';
import { ReporterComponent } from './reporter/reporter.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { UserComponent } from './user/user.component';

@NgModule({
  declarations: [
    ReportersComponent,
    ReporterCardComponent,
    ReporterComponent,
    UserComponent,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [TranslateModule],
})
export class ReporterModule {}
