import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { WelcomeDialogData } from './models/welcome-dialog-data.model';

@Component({
  selector: 'app-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss']
})
export class WelcomeDialogComponent implements OnInit {

  title: string;
  welcomeText: string;

  constructor(private readonly dialogRef: MatDialogRef<WelcomeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: WelcomeDialogData) { }

  ngOnInit(): void {
  }

  closeWithConfirm(): void {
    this.dialogRef.close(true);
  }

  close() : void {
    this.dialogRef.close(false);
  }
}
