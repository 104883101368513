import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEvent } from '../../models/streappevent.model';

@Component({
  selector: 'overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss']
})
export class OverviewCardComponent {
  @Input() event: StreappEvent;
  @Input() navigateToEventOnClick: boolean = true;

  fav: boolean;

  constructor(
    private readonly authService: AuthService) { }

  isAdultOrLowImpact(): boolean {
    if(this.event.isHighImpactEvent) {
      if (this.authService.isAuthenticated()) {
        if (this.authService.isAdult) {
          return true;
        } 
      }
      return false;
    }

    return true;
  }

  getThumbSource(thumb: string): string {
    if (this.validURL(thumb)) {
      return thumb;
    } else {
      return 'data:image/jpeg;base64,' + thumb;
    }
  }

  private validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }
}
