import { Component, Input } from '@angular/core';
import { StreappEvent } from '../../../../models/streappevent.model';
import { StreappEventsService } from '../../../../services/streapp-events.service';

@Component({
  selector: 'related-events',
  templateUrl: './related-events.component.html',
  styleUrls: ['./related-events.component.scss']
})
export class RelatedEventsComponent {
  @Input() set event(value: StreappEvent) {
    this._event = value;
    this.init();
  }

  get event(): StreappEvent {
    return this._event;
  }

  relatedByCategory: StreappEvent[];
  relatedByDistance: StreappEvent[];
  _event: StreappEvent;

  constructor(private readonly streappService: StreappEventsService) {
  }

  init(): void {
    this.streappService.getSimilarEvents(this.event.partitionKey, this.event.category, this.event.subcategory, this.event.longtitude, this.event.latitude).then((similarEvents: StreappEvent[]) => {
      this.relatedByCategory = similarEvents.filter(x => x.category === this.event.category);
      this.relatedByDistance = similarEvents.filter(x => x.category !== this.event.category);
    });
  }
}
