import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondsToFullTimePipe } from './seconds-to-full-time.pipe';
import { DateToDescriptionPipe } from './date-to-description.pipe';

@NgModule({
  declarations: [
    SecondsToFullTimePipe,
    DateToDescriptionPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SecondsToFullTimePipe,
    DateToDescriptionPipe
  ]
})
export class PipesModule { }
