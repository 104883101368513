import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';
import { StreappEvent } from 'src/app/models/streappevent.model';
import { AuthService } from 'src/app/services/auth.service';
import { StreappEventsService } from 'src/app/services/streapp-events.service';
import { EventType } from '../new-event-form/_models/eventtype.model';
import { formatDate } from '@angular/common';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-edit-content-form',
  templateUrl: './edit-content-form.component.html',
  styleUrls: ['./edit-content-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditContentFormComponent implements OnInit, OnDestroy {
  publishing: boolean = false;
  streappFormGroup: UntypedFormGroup;
  finishedUploading: boolean = false;
  loading: boolean = true;
  event: StreappEvent;
  isReporter: boolean;
  datumTijd: string;
  showAdditionalQuestions: boolean = false;
  availableEventTypes: Array<EventType> = [];
  eventCategory: EventType;
  minDate: string;
  maxDate: string;
  selectedLatLng: any;
  originalStreappContent: {
    title: string;
    description: string;
    tags: string;
    dateTime: string;
    location: string;
    locationStreet: string;
    locationCity: string;
    isHighImpact: string;
    injuriesPresent: string;
    emergencyServicesPresent: string;
  } = {
    title: '',
    description: '',
    tags: '',
    dateTime: '',
    location: '',
    locationStreet: '',
    locationCity: '',
    isHighImpact: '',
    injuriesPresent: '',
    emergencyServicesPresent: '',
  };
  selectedLocation: string;

  private readonly destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly _formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly streappService: StreappEventsService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly materialSnackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getEventIdAndLoadEvent();

    this.streappService.getEventTypes().then((data) => {
      this.availableEventTypes = data;
    });

    this.isReporter = this.authService.isReporter;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  setupFormGroup(): void {
    this.streappFormGroup = this._formBuilder.group({
      titel: [
        this.event.title,
        [Validators.required, Validators.maxLength(150)],
      ],
      beschrijving: [
        this.event.description,
        [Validators.required, Validators.maxLength(5000)],
      ],
      datumTijd: [
        formatDate(new Date(this.event.created), 'yyyy-MM-ddTHH:mm', 'en'),
      ],
      tags: [this.event.tags, Validators.maxLength(200)],
      location: [
        `${this.event.latitude} , ${this.event.longtitude}`,
        Validators.required,
      ],
      hulpdiensten: [this.event.emergencyServicesPresent, Validators.required],
      gewonden: [this.event.injuriesPresent, Validators.required],
      heftigEvent: [this.event.isHighImpactEvent, Validators.required],
    });

    //Save old values to show as comparison later
    this.originalStreappContent.title = this.event.title;
    this.originalStreappContent.description = this.event.description;
    this.originalStreappContent.dateTime = formatDate(
      new Date(this.event.created),
      'yyyy-MM-ddTHH:mm',
      'en'
    );
    this.originalStreappContent.tags = this.event.tags;
    this.originalStreappContent.location = `${this.event.latitude} , ${this.event.longtitude}`;
    this.selectedLatLng = [this.event.longtitude, this.event.latitude];
    this.originalStreappContent.locationStreet = this.event.locationStreet;
    this.originalStreappContent.locationCity = this.event.locationCity;
    this.originalStreappContent.isHighImpact = this.event.isHighImpactEvent
      ? 'ja'
      : 'nee';
    this.originalStreappContent.emergencyServicesPresent = this.event
      .emergencyServicesPresent
      ? 'ja'
      : 'nee';
    //update injuriespresent later in function

    this.streappFormGroup.controls['heftigEvent'].valueChanges.subscribe(
      (value) => {
        this.showAdditionalQuestionsForShockingEvent(value);
      }
    );

    this.streappFormGroup.get('datumTijd').valueChanges.subscribe((value) => {
      this.validateInputDate(value);
    });

    if (this.event.isHighImpactEvent) {
      //Fill in mat select AdultContent based on data
      this.streappFormGroup.get('heftigEvent').setValue('ja');
    } else {
      this.streappFormGroup.get('heftigEvent').setValue('nee');
    }

    if (this.event.emergencyServicesPresent) {
      //Fill in mat select EmergencyServices based on data
      this.streappFormGroup.get('hulpdiensten').setValue('ja');
    } else {
      this.streappFormGroup.get('hulpdiensten').setValue('nee');
    }

    switch (
      this.event.injuriesPresent //Fill in mat select Injuries based on data (String is Case sensitive)
    ) {
      case 'Ja':
        this.streappFormGroup.get('gewonden').setValue('ja');
        this.originalStreappContent.injuriesPresent = 'ja';
        break;

      case 'Nee':
        this.streappFormGroup.get('gewonden').setValue('nee');
        this.originalStreappContent.injuriesPresent = 'nee';
        break;

      default:
        this.streappFormGroup.get('gewonden').setValue('onbekend');
        this.originalStreappContent.injuriesPresent = 'onbekend';
        break;
    }
  }

  getEventIdAndLoadEvent(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      let id = params['id'];
      this.loadEventFromService(id);
    });
  }

  loadEventFromService(id: string): void {
    this.loading = true;
    this.streappService
      .getEventById(id, this.authService.authorizationHeaderValue)
      .then((data: StreappEvent) => {
        if (!data) {
          // Either event doesn't exists or user is underage and its an highimpact event
          this.router.navigate(['/']);
        }

        this.event = data;

        // Fill form with current data
        this.setupFormGroup();
        // Set Min and Max Dates
        this.initDateTimes();

        if (this.event.createdBy !== this.authService.userId) {
          // User is not the owner of this event
          this.router.navigate(['/']);
        }

        this.loading = false;
      })
      .catch((error: HttpErrorResponse) => {
        this.loading = false;
      });
  }

  showAdditionalQuestionsForShockingEvent(heftigEvent: string): void {
    if (heftigEvent === 'ja') {
      this.showAdditionalQuestions = true;
      this.toggleAdditionalQuestionsValidation(true);
    } else {
      // this.isCategoryHighImpact() //Misschien onnodig, vraag na
      this.toggleAdditionalQuestionsValidation(this.showAdditionalQuestions);
    }
  }

  toggleAdditionalQuestionsValidation(enableRequiredValidation): void {
    if (enableRequiredValidation) {
      this.streappFormGroup
        .get('hulpdiensten')
        .setValidators([Validators.required]);
      this.streappFormGroup
        .get('gewonden')
        .setValidators([Validators.required]);
    } else {
      this.streappFormGroup.get('hulpdiensten').setValidators(null);
      this.streappFormGroup.get('gewonden').setValidators(null);
    }

    this.streappFormGroup.get('hulpdiensten').updateValueAndValidity();
    this.streappFormGroup.get('gewonden').updateValueAndValidity();
  }

  validateInputDate(value: string): void {
    const created = moment(value)
      .utcOffset(new Date().getTimezoneOffset())
      .toDate();
    const min = new Date(this.event.created);
    min.setDate(min.getDate() - 2);

    if (created > new Date() || created < min) {
      this.streappFormGroup.controls['datumTijd'].setErrors({
        incorrect: true,
      });
    } else {
      this.streappFormGroup.controls['datumTijd'].setErrors(null);
    }
  }

  initDateTimes(): void {
    const max = new Date();
    this.maxDate = formatDate(max, 'yyyy-MM-dd', 'en');
    this.maxDate += 'T' + formatDate(new Date(), 'HH:mm', 'en');

    const min = new Date(this.event.created);
    min.setDate(min.getDate() - 2);
    this.minDate = formatDate(min, 'yyyy-MM-dd', 'en');
    this.minDate += 'T' + formatDate(new Date(), 'HH:mm', 'en');
  }

  setLocation(location: any) {
    this.streappFormGroup
      .get('location')
      .setValue(`${location[1]} , ${location[0]}`); //Lat, Long
    this.selectedLatLng = location;
  }

  setLocationFriendlyName(location: string): void {
    this.selectedLocation = location;
  }

  eventData: any;

  private setEventData(): any {
    const created = moment(this.streappFormGroup.get('datumTijd').value)
      .utcOffset(new Date().getTimezoneOffset())
      .toDate();
    this.eventData = {
      Title: this.streappFormGroup.get('titel').value,
      Description: this.streappFormGroup.get('beschrijving').value,
      InjuriesPresent: this.streappFormGroup.get('gewonden').value,
      EmergencyServicesPresent:
        this.streappFormGroup.get('hulpdiensten').value === 'ja',
      ShockingEvent: this.streappFormGroup.get('heftigEvent').value === 'ja',
      Tags: this.streappFormGroup.get('tags').value,
      Latitude: this.selectedLatLng[1],
      Longtitude: this.selectedLatLng[0],
      CreatedOn: created,
    };
  }

  async publish(): Promise<void> {
    this.publishing = true;

    try {
      this.setEventData();
      await this.streappService.updateEvent(
        this.event.partitionKey,
        this.eventData,
        this.authService.authorizationHeaderValue
      );

      this.router.navigate(['detail', this.event.partitionKey]);
    } catch (error) {
      console.log(error);
      this.publishing = false;

      this.materialSnackbar.open(
        `Fout bij aanmaken StreApp: ${error}. Probeer het nogmaals.`,
        'Ok',
        {
          duration: 5000,
        }
      );
    }

    this.publishing = false;
  }
}
