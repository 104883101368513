<a routerLink="/promo/{{promo.id}}/default" class="promo-ad">
  <mat-card class="widget widget-thumbnail" [title]="promo.title">
    <div class="promo-banner">
      Promotie actie
    </div>
    <div class="top">
      <div class="video-preview">
        <img Class="photo" [src]="promo.imageUrl ? promo.imageUrl : './assets/images/fallback-thumb.png'"
            [alt]="promo.title"
            onerror="this.onerror = null; this.src = './assets/images/fallback-thumb.png';" />
      </div>
      <div class="video-attributes">
        <div class="countdown" [ngClass]="color">{{ countdown }}</div>
        <div class="title">{{promo.title}}</div>
      </div>
    </div>
    <div class="secondary-text description">{{promo.description}}</div>
  </mat-card>
</a>
