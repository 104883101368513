import { Component, Input, EventEmitter, Output } from '@angular/core';
import { MediaItem, MediaType } from '../../models/mediaitem.model';

@Component({
  selector: 'image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent {
  @Input() mediaItemArray: Array<MediaItem>;
  @Input() selectedMediaItem: MediaItem;

  @Output() mediaItemClicked: EventEmitter<MediaItem> = new EventEmitter();

  MediaType = MediaType;

  constructor() { }

  clickedMediaItem(image: MediaItem): void {
    this.mediaItemClicked.emit(image);
  }
}
