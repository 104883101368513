<div class="desktop-width-limiter overview-page page-padding">
    <mat-toolbar>
        <button mat-button class="menu-item" routerLink="/create-ad" title="{{ 'create-ads.hover_title' | translate }}">
            <h1>{{ 'create-ads.title' | translate }}</h1>
        </button>
        <button mat-button class="menu-item" routerLink="/ads" title="{{ 'ads.hover_title' | translate }}">
            <h1>{{ 'ads.title' | translate }}</h1>
        </button>
        <button mat-button class="menu-item active"  title="{{ 'promo.hover_title' | translate }}">
          <h1>{{ 'promo.title' | translate }}</h1>
      </button>
    </mat-toolbar>
               
    <ng-container *ngIf="!loading && !errorText && myPromos.length > 0">
        <div class="row overview-items">
            <div class="col-xs-12 col-sm-6 col-lg-4 overview-item" *ngFor="let promo of myPromos">
            <promo-card [promo]="promo"></promo-card>
            </div>
            <div class="noMoreAds">
                <span class="noMoreAdsLabel">{{ 'promo.noMorePromos' | translate }}</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!loading && !errorText && myPromos.length === 0">
        <div class="errorText">
          {{'page.noVideos' | translate}}
        </div>
      </ng-container>
    
      <ng-container *ngIf="loading">
        <div class="loading">
          <mat-progress-spinner class="loading-spinner" [diameter]="250" mode="indeterminate" color="primary">
          </mat-progress-spinner>
          <div class="loading-text"><h1>Starten...</h1></div>
        </div>
      </ng-container>

</div>