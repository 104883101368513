<div *ngIf="error" class="row justify-content-center">
    <div class="col-md-8 text-center">
          <div class="alert alert-warning" role="alert">
            Oops, there was an error, please try to <a routerLink="/">login again</a>.
          </div>
    </div>
</div>
<ng-container *ngIf='loading'>
  <div class="loading">
    <mat-progress-spinner class="loading-spinner" [diameter]="250" mode="indeterminate" color="primary">
    </mat-progress-spinner>
    <div class="loading-text"><h1>Redirecting...</h1></div>
  </div>
</ng-container>
