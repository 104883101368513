import {
  Component,
  EventEmitter,
  Output,
  Input,
  OnInit,
  Injector,
  Inject,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../../../../services/auth.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
  selector: 'location-selector-azure',
  templateUrl: './location-selector-azure.component.html',
  styleUrls: ['./location-selector-azure.component.scss'],
})
export class LocationSelectorAzureComponent implements OnInit {
  @Input() defaultLocation: number[];

  @Output() mapClicked: EventEmitter<any> = new EventEmitter<any>();
  @Output() locationChanged: EventEmitter<string> = new EventEmitter<string>();

  map: any;
  marker: any;
  loading: boolean = true;
  currentLocation: { lat: number; long: number } = {
    long: 4.5367372,
    lat: 52.0511171,
  };
  selectedLocationName: string = '';

  private searchService: any;

  @ViewChild('mapelement') mapelement;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly httpClient: HttpClient,
    @Inject(PLATFORM_ID) protected _platformId: Object
  ) {}

  async ngOnInit(): Promise<void> {
    if (isPlatformServer(this._platformId)) {
      return;
    }

    let userProfile = await this.authService.getClaimData(true);
    if (!!userProfile?.location) {
      this.currentLocation = {
        long: +userProfile?.location.split(';')[0],
        lat: +userProfile?.location.split(';')[1],
      };
    }

    if (this.defaultLocation) {
      this.currentLocation = {
        long: this.defaultLocation[0],
        lat: this.defaultLocation[1],
      };
    }

    await import('ng-azure-maps').then((azure) => {
      this.searchService = new azure.SearchService(this.httpClient);
    });

    const azurecontrol = require('azure-maps-control');
    const options = {
      authType: 'subscriptionKey',
      subscriptionKey: 'SROjwQ2cy75Xj0GHK5hcjAoPMSAGa7s3ozx1C9mm0RQ',
    };

    //atlas.Map
    this.map = require('azure-maps-control').Map;

    this.map = new azurecontrol.Map(this.mapelement.nativeElement, {
      center: [this.currentLocation.long, this.currentLocation.lat],
      zoom: 14,
      language: 'nl-NL',
      authOptions: options,
    });

    this.map.controls.add(new azurecontrol.control.ZoomControl(), {
      position: 'top-left',
    });

    this.map.controls.add(new azurecontrol.control.CompassControl(), {
      position: 'bottom-left',
    });

    this.map.events.add('click', async (event) => {
      this.marker = event.position;
      this.mapClicked.emit(event.position);
      await this.getMarkedLocationName(event);

      this.map.markers.clear();
      //Create an HTML marker and add it to the map.
      var marker = new azurecontrol.HtmlMarker({
        position: this.marker,
        htmlContent: this.getHtmlMarkerContent(),
      });

      this.map.markers.add(marker);
    });

    if (this.defaultLocation) {
      await this.getMarkedLocationName({ position: this.defaultLocation });
      this.addMarker(this.defaultLocation);
    }
  }

  private addMarker(position: number[]): void {
    console.log('addMarker', position);
    const azurecontrol = require('azure-maps-control');
    this.marker = position;
    this.mapClicked.emit(position);
    this.map.markers.clear();
    //Create an HTML marker and add it to the map.
    var marker = new azurecontrol.HtmlMarker({
      position: this.marker,
      htmlContent: this.getHtmlMarkerContent(),
    });

    this.map.markers.add(marker);
  }

  getHtmlMarkerContent(): string {
    return (
      '<div class="marker"><div class="pin bounce"><div class="marker-title"><div class="filler"></div><div class="title">' +
      this.selectedLocationName +
      '</div></div>'
    );
  }

  async onCustomLocationChanged(location: string): Promise<void> {
    const position = [+location.split(';')[0], +location.split(';')[1]];

    this.map.setCamera({
      center: position,
      type: 'fly',
    });

    this.marker = position;
    this.mapClicked.emit(position);
    await this.getMarkedLocationName({ position: position });
    const azurecontrol = require('azure-maps-control');

    this.map.markers.clear();
    //Create an HTML marker and add it to the map.
    var marker = new azurecontrol.HtmlMarker({
      position: this.marker,
      htmlContent: this.getHtmlMarkerContent(),
    });

    this.map.markers.add(marker);
  }

  //IMapEvent cannot use this on server side rendering SSR
  async getMarkedLocationName(event: any): Promise<void> {
    let results = await this.searchService
      .searchAddressReverse([event.position[1], event.position[0]], {})
      .toPromise();
    if (results.summary?.numResults > 0) {
      const result = results.addresses[0];
      this.selectedLocationName = result.address.freeformAddress;
      this.locationChanged.emit(this.selectedLocationName);
    }
  }
}
