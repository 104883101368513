<mat-sidenav-container>
  <!-- CONTENT -->
  <!-- Side navbar desktop view -->
  <mat-sidenav
    #sidenav
    *ngIf="visible && !isMobileView"
    class="nav-side"
    mode="side"
    opened="true"
    disableClose="true"
  >
    <navbar [navbarType]="'left-side'" class="navbar-desktop"></navbar>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- Top navbar desktop view -->
    <ng-container *ngIf="visible && !isMobileView">
      <navbar [navbarType]="'top'"></navbar>
    </ng-container>
    <mobile-choice-dialog
      *ngIf="isMobileDevice"
      (continueClicked)="continueToWebsite()"
    ></mobile-choice-dialog>
    <!-- Mobile view navbar -->
    <ng-container *ngIf="visible && isMobileView">
      <navbar [navbarType]="'mobile'"></navbar>
    </ng-container>
    <main
      style="flex: 1 1 auto; width: 100%; align-self: center"
      [style.padding]="isMobileView ? '10px' : ''"
    >
      <router-outlet></router-outlet>
    </main>
    <footer *ngIf="visible && !isMobileView" class="footer">
      <a href="https://www.streapp.nl" target="_blank">
        <img
          class="footer-logo"
          src="./assets/images/streapp_met_r.svg"
          alt="StreApp"
        />
      </a>
      <div class="socials">
        <div class="social-container">
          <a href="https://x.com/streapp_com" target="_blank">
            <img
              width="24"
              height="24"
              src="/assets/images/socials/x-social-media-white-icon.png"
            />
          </a>
          <a href="http://instagram.com/streapp" target="_blank">
            <img
              width="24"
              height="24"
              src="/assets/images/socials/Instagram.png"
            />
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100089259057883"
            target="_blank"
          >
            <img
              width="24"
              height="24"
              src="/assets/images/socials/Facebook.png"
            />
          </a>
        </div>
      </div>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
