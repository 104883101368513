<div *ngIf="!loading" class="desktop-width-limiter overview-page page-padding">
    <mat-toolbar *ngIf="promo.signup">
        <button mat-button class="menu-item" [ngClass]="{'active': active == 'default' }" routerLink="../default" title="{{ 'create-ads.hover_title' | translate }}">
            <h1>{{ 'promo.action' | translate }}</h1>
        </button>
        <button  mat-button class="menu-item" [ngClass]="{'active': active == 'signup' }" routerLink="../signup" title="{{ 'ads.hover_title' | translate }}">
            <h1>{{ 'promo.signup' | translate }}</h1>
        </button>
        <button mat-button class="menu-item" [ngClass]="{'active': active == 'terms' }" routerLink="../terms"  title="{{ 'promo.hover_title' | translate }}">
          <h1>{{ 'promo.terms' | translate }}</h1>
      </button>
    </mat-toolbar>
    <mat-card *ngIf="active == 'default'" class="widget">
        <div class="row">
            <div class="col-lg-7">
                <video *ngIf="!isImage" [src]="promo.pageImageUrl" autoplay controls width="100%"></video>
                <img *ngIf="isImage" class="photo" [src]="promo.pageImageUrl" />
            </div>
            <div class="col-lg-5">
                <h1 class="title">{{ promo.pageTitle }}</h1>
                <h4 class="countdown" [ngClass]="color">{{ countdown }}</h4>
                <p [innerHtml]="promo.pageDescription"></p>
                <button *ngIf="promo.pageButton" mat-raised-button class="promo-button" (click)="openUrl(promo.url)">{{ promo.pageButton }}</button>
            </div>
        </div>
    </mat-card>
    <mat-card *ngIf="active == 'signup'" class="widget">
        <div class="row">
            <div class="col-lg-12">
                <p [innerHtml]="promo.signupDescription"></p>
                <button *ngIf="!alreadySignedUp" [ngClass]="{ 'disabled': !canSignup }" mat-raised-button class="promo-button" (click)="openSignupDialog()">{{ 'promo.signup' | translate }}</button>
                <button *ngIf="alreadySignedUp" mat-raised-button class="promo-button" (click)="openSignoffDialog()">{{ 'promo.signoff' | translate }}</button>
                <span class="signofftext" *ngIf="alreadySignedUp">{{ 'promo.signoff-text' | translate }}</span>
            </div>
        </div>
    </mat-card>
    <mat-card *ngIf="active == 'terms'" class="widget">
      <p>
        Hieronder zie je de voorwaarden in het kort, mocht je de volledige voorwaarden willen lezen dan kan dat <a href="/StreApp-Algemene-voorwaarden.pdf" target="_blank">via deze link</a>. <br /><br />
        - We delen jouw persoonlijke data niet met derden.<br />
        - Meedoen kan tot een dag voor het einde van de actie, alleen via de aanmelden knop.<br />
        - Winnaar krijgt uiterlijk 24 uur na het aflopen van de actie bericht via e-mail. Niet-winnaars ontvangen geen bericht.<br />
        - Over de uitslag wordt niet gecorrespondeerd.<br />
        - Minderjarigen mogen pas meedoen na voorafgaande toestemming van hun ouders of verzorgers.<br />
        - Prijzen zijn niet inwisselbaar voor geld of andere goederen.<br />
        - Medewerkers van StreApp zijn uitgesloten van deelname.<br />
        - Deelnemers die niet voldoen aan bovengenoemde voorwaarden kunnen worden uitgesloten van deelname.<br />
        - Klachten kunnen via het contactformulier op de website worden ingediend<br />
      </p>
    </mat-card>
</div>
