import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss'],
})
export class AuthCallbackComponent implements OnInit {
  loading: boolean = true;
  error: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {}

  async ngOnInit() {
    if (isPlatformServer(this._platformId)) {
      return;
    }
    // check for error
    if (this.route.snapshot.fragment?.indexOf('error') >= 0) {
      this.error = true;
      this.loading = false;
      return;
    }
    await this.authService.completeAuthentication();

    if (localStorage.getItem('redirect')) {
      let route = localStorage.getItem('redirect');
      localStorage.removeItem('redirect');
      this.router.navigateByUrl(route);
    } else {
      this.router.navigate(['/']);
    }
  }
}
