export enum DeviceOS {
    Unknown = 0,
    iOS = 1,
    Android = 2,
    WindowsPhone = 3
}

export class DeviceCheckUtility {
    public static getMobileOperatingSystem(): DeviceOS {
        var userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return DeviceOS.WindowsPhone;
        }
    
        if (/android/i.test(userAgent)) {
            return DeviceOS.Android;
        }
    
        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
            return DeviceOS.iOS;
        }
    
        return DeviceOS.Unknown;
    }
}
  