import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Ad } from 'src/app/models/ad.model';

@Component({
  selector: 'app-ad-dialog',
  templateUrl: './ad-dialog.component.html',
  styleUrls: ['./ad-dialog.component.scss']
})
export class AdDialogComponent implements OnInit {

  color: string;

  constructor(private readonly dialogRef: MatDialogRef<AdDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: Ad,
    private readonly translate: TranslateService) { 
    }

  ngOnInit(): void {
  }

  closeWithConfirm(): void {
    this.dialogRef.close(true);
  }

  close() : void {
    this.dialogRef.close(false);
  }

  getImageSource(img: string): string {
    if (this.validURL(img)) {
      // this is a photo streapp use full image;
      return img;
    } else {
      return 'data:image/jpeg;base64,' + img;
    }
  }

  private validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }
}
