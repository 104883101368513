import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class RobotsService {

  constructor(private readonly meta: Meta,
    readonly configService: AppConfigService) {
  }

  setRobots(follow: boolean = false) {
    if (this.configService.getConfig().production) {
        if (follow) {
            this.meta.updateTag({
                name: 'robots',
                content: 'index, follow'
              });
        } else {
            this.meta.updateTag({
                name: 'robots',
                content: 'index, nofollow'
              });
        }
    }
  }
}
