<mat-card class="widget widget-thumbnail" [title]="streapper.name" (click)="navigateToProfile()">
  <div class="profile-preview">
    <img *ngIf="true" [src]="getProfilePicture()"
         [alt]="streapper.name"
         onerror="this.onerror = null; this.src = './assets/images/no-profile-image.png';" />
    <img *ngIf="false" src="./assets/images/no-profile-image.png"
         [alt]="streapper.name" />
  </div>
  <div class="profile-attributes">
    <div class="profile-top">
      <div class="reporter-title">Reporter</div>
      <div class="reporter-name ellipsified">{{streapper.name || streapper.userName}}</div>
    </div>
    <div class="profile-bottom">
      <div class="datetime ellipsified">
        <span [title]="'reporters.created' | translate">{{ 'reporters.date_created' | translate }}: {{streapper.created | date: 'dd-MM-yyyy' }}</span>
      </div>
      <div class="divide-line">
        |
      </div>
      <div class="total-streapps ellipsified">
        <span [title]="'reporters.amountOfStreApps' | translate">StreApps: {{ streapper.amountOfStreApps }}</span>
      </div>
    </div>
  </div>
</mat-card>