<div class="desktop-width-limiter page-padding info-page">
  <div class="row">
    <mat-card class="small">
      <h1>
        {{ "info_page.feedback.title" | translate }}
      </h1>
      <div class="description">
        {{ "info_page.feedback.description" | translate }}
      </div>
      <div class="card-bottom">
        <button
          mat-flat-button
          (click)="openFeedbackDialog()"
          color="primary"
          [title]="'info_page.feedback.button' | translate"
        >
          {{ "info_page.feedback.button" | translate }}
        </button>
      </div>
    </mat-card>
    <mat-card class="medium">
      <h1>
        {{ "info_page.about_us.title" | translate }}
      </h1>
      <div class="description">
        <div class="top">
          {{ "info_page.about_us.description_top" | translate }}
        </div>
        <div class="bottom">
          {{ "info_page.about_us.description_bottom" | translate }}
          <ul class="circle">
            <li>Wie Wij zijn</li>
            <li>De redenen en visie om Streapp te ontwikkelen</li>
            <li>Onze kernwaarden en Reporters</li>
            <li>Hoe je met ons kunt samenwerken</li>
          </ul>
        </div>
      </div>
      <div class="card-bottom">
        <button
          mat-flat-button
          (click)="goToAboutUsWebsite()"
          color="primary"
          [title]="'info_page.about_us.button' | translate"
        >
          {{ "info_page.about_us.button" | translate }}
        </button>
      </div>
    </mat-card>
  </div>
  <div class="row">
    <mat-card class="medium">
      <h1>
        {{ "info_page.terms.title" | translate }}
      </h1>
      <div class="description">
        <div class="top">
          {{ "info_page.terms.description" | translate }}
        </div>
        <div class="bottom">
          <ul class="dashed">
            <li>Hou het netjes.</li>
            <li>
              Geen seksistische, discriminerende of anders vervelende berichten.
            </li>
            <li>We delen jouw persoonlijke data niet met derden.</li>
            <li>Jij krijgt geld, als jouw Streapp wordt verkocht!</li>
            <li>Upload alleen content waarvan jij rechten bezit.</li>
          </ul>
        </div>
      </div>
      <div class="card-bottom">
        <button
          mat-flat-button
          (click)="downloadTerms()"
          color="primary"
          [title]="'general.download' | translate"
        >
          {{ "general.download" | translate }}
        </button>
      </div>
    </mat-card>
    <mat-card class="small">
      <h1>
        {{ "info_page.contact.title" | translate }}
      </h1>
      <div class="description">
        {{ "info_page.contact.description" | translate }}
      </div>
      <div class="card-bottom">
        <button
          mat-flat-button
          (click)="openContactDialog()"
          color="primary"
          [title]="'info_page.contact.title' | translate"
        >
          {{ "info_page.contact.title" | translate }}
        </button>
      </div>
    </mat-card>
  </div>
</div>
