import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { StreappEvent } from '../../../../models/streappevent.model';

@Component({
  selector: 'app-similar-events-dialog',
  templateUrl: './similar-events-dialog.component.html',
  styleUrls: ['./similar-events-dialog.component.scss']
})
export class SimilarEventsDialogComponent {
  constructor(public dialogRef: MatDialogRef<SimilarEventsDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public data: StreappEvent[] ) { }

  closeWithoutLinking(): void {
    this.dialogRef.close({ result: 'new', event: null });
  }

  closeWithLinking(event: StreappEvent): void {
    this.dialogRef.close({ result: 'link', event: event });
  }
}
