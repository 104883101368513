<div class="dialog welcome" *ngIf="!!data">
    <h2 mat-dialog-title class="header">
        {{ 'profile.welcome.title' | translate}} {{ data.name }}
        <button class="close-ref" mat-icon-button mat-dialog-close [title]="'general.close' | translate | titlecase">
          <mat-icon>clear</mat-icon>
        </button>
      </h2>
      <div mat-dialog-content>
        <p> {{ 'profile.welcome.body' | translate}}</p>
        <p> {{ 'profile.welcome.havefun' | translate}}</p>
      </div>
</div>
