<a routerLink="/detail/{{ event.partitionKey }}" class="overview-card-link">
  <mat-card
    class="widget widget-thumbnail overview-widget"
    [title]="event.title"
  >
    <div class="card-info">
      <div class="video-blur" *ngIf="!isAdultOrLowImpact()">
        <span>{{ "page.adultVideo" | translate }}</span>
      </div>
      <div class="video-blur" *ngIf="!event.thumbnail">
        <span>{{ "page.processing" | translate }}</span>
      </div>
      <div class="video-preview">
        <img
          [ngClass]="{ photo: event.videoDurationInSeconds == 0 }"
          [src]="thumbnail"
          [alt]="event.title"
          onerror="this.onerror = null; this.src = './assets/images/fallback-thumb.png';"
        />
        <div
          *ngIf="event.url && event.videoDurationInSeconds > 0"
          class="video-overlay"
          [title]="event.title"
        >
          <span class="video-overlay-controls">
            <mat-icon>play_circle_filled</mat-icon><br />
          </span>
          <span class="video-overlay-duration">{{
            event.videoDurationInSeconds | secondsToFullTime
          }}</span>
        </div>
      </div>
      <div class="bottom">
        <div class="video-attributes">
          <div class="toptext">
            <h1 class="ellipsified">{{ event.title }}</h1>
            <ng-container *ngIf="event.mediaItemTypes">
              <!-- Multiple Video's and Photo's -->
              <div class="mediaItems-sum" *ngIf="multipleVideosPhotos">
                {{ event.mediaItemTypes[0] }} video's |
                {{ event.mediaItemTypes[1] }} foto's
              </div>
              <!-- Multiple VIdeo's and Singular Photo -->
              <div class="mediaItems-sum" *ngIf="singlePhotoMultipleVideos">
                {{ event.mediaItemTypes[0] }} video's |
                {{ event.mediaItemTypes[1] }} foto
              </div>
              <!-- Multiple Photo's and Singular Video -->
              <div class="mediaItems-sum" *ngIf="singleVideoMultiplePhotos">
                {{ event.mediaItemTypes[0] }} video |
                {{ event.mediaItemTypes[1] }} foto's
              </div>
              <!-- Multiple Photo's -->
              <div class="mediaItems-sum" *ngIf="multiplePhotos">
                {{ event.mediaItemTypes[1] }} foto's
              </div>
              <!-- Multiple Video's -->
              <div class="mediaItems-sum" *ngIf="multipleVideos">
                {{ event.mediaItemTypes[0] }} video's
              </div>
              <!-- Singular Photo -->
              <div class="mediaItems-sum" *ngIf="singlePhoto">
                {{ event.mediaItemTypes[1] }} foto
              </div>
              <!-- Singular Video -->
              <div class="mediaItems-sum" *ngIf="singleVideo">
                {{ event.mediaItemTypes[0] }} video
              </div>
            </ng-container>
          </div>
          <div class="category-text">
            <mat-icon>category</mat-icon> {{ event.subcategory }}
            {{ event.subcategory ? "" : "" }}
          </div>
          <div class="datetime ellipsified">
            <mat-icon>calendar_today</mat-icon>
            {{ event.created | date : "dd MMMM yyyy - HH:mm" }}
          </div>
          <div class="location ellipsified ellipsified-2">
            <mat-icon>location_on</mat-icon> {{ event.locationStreet }},
            {{ event.locationCity }}
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</a>
