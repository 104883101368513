export class Tracking {

    public static categories: string[] = [
        'Create Streapp',
        'Account Creation',
        'Popup'
    ];

    public static actions: string[] = [
        'Click',
        'Submit',
        'Pageview',
        'Category_Click',
        'Subcategory_Click',
        'Upload',
        'Adult_Content',
        'Wounded',
        'Emergency_Services'
    ];
}
