import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { P2000Discipline, P2000Melding } from '../../models/p2000.model';
import { P2000Service } from '../../services/p2000.service';

@Component({
  selector: 'p2000-card',
  templateUrl: './p2000-card.component.html',
  styleUrls: ['./p2000-card.component.scss']
})
export class P2000CardComponent implements OnInit {
  @Input() P2000Melding: P2000Melding;

  constructor(private readonly router: Router,
    private readonly p2000Service: P2000Service) { }

  ngOnInit(): void {
  }

  navigateToDetail(): void {
    this.p2000Service.selectedItem = this.P2000Melding;
    this.router.navigate(['112/detail']);
  }
}
