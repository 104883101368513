import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewListComponent } from './overview-list/overview-list.component';
import { ComponentsModule } from '../../components/components.module';
import { OverviewMapComponent } from './overview-map/overview-map.component';
import { OverviewFiltersComponent } from './_components/overview-filters/overview-filters.component';
import { Overview112Component } from './overview-112/overview-112.component';

@NgModule({
  declarations: [OverviewListComponent, OverviewMapComponent, OverviewFiltersComponent, Overview112Component],
  imports: [
    CommonModule,
    ComponentsModule
  ],
  exports: [
    OverviewListComponent,
    OverviewMapComponent,
    OverviewFiltersComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class OverviewModule { }
