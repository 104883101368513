import { isPlatformBrowser } from '@angular/common';
import { Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Promo } from 'src/app/models/ad.model';
@Component({
  selector: 'promo-card',
  templateUrl: './promo-card.component.html',
  styleUrls: ['./promo-card.component.scss']
})
export class PromoCardComponent implements OnInit {
  @Input() promo: Promo;

  color: string;
  countdown: string;

  constructor(
    private readonly translate: TranslateService,
    @Inject(PLATFORM_ID) protected _platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this._platformId)) {
      this.getCountDown();
      setInterval(() => {this.getCountDown()}, 5000);
    }
  }
  
  getCountDown() {
    let now = new Date();
    let endDate = new Date(this.promo.publishEnd);
    if (now > endDate) {
      this.translate.get('promo.ended').subscribe(text => {
        return text;
      })    
    }

    var diff = Math.abs(endDate.getTime() - now.getTime());
    var diffDays = Math.floor(diff / (1000 * 3600 * 24));
    var diffHours = Math.floor(diff / (1000 * 3600)) - diffDays * 24;
    var diffMinutes = Math.floor((Math.ceil(diff / (1000)) - (diffDays * 24 * 3600) - (diffHours * 3600)) / 60);
    if (diffHours < 0) {
      diffHours = 23 + diffHours;
    }
    var diffMinutes = endDate.getMinutes() - now.getMinutes();
    if (diffMinutes < 0) {
      diffMinutes = 60 + diffMinutes;
    }

    if (diffDays > 0 || diffHours > 8) {
      this.color = 'green';
    } else if (diffHours > 0)
    {
      this.color = 'orange';
    }
    else {
      this.color = 'red';
    }

    this.countdown = diffDays + ' dg : ' + diffHours + ' uur : ' + diffMinutes + ' min';
  }
}
