import { Injectable } from '@angular/core';
import { StreappReaction } from '../models/streappreaction.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ReactionService {
  private baseApiUrl: string;

  constructor(private httpService: HttpClient,
    private readonly configService: AppConfigService) {
    this.baseApiUrl = this.configService.getConfig().baseApiUrl;
  }

  getReactionsForEvent(eventId: string): Promise<Array<StreappReaction>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return new Promise((resolve, reject) => {
      this.httpService.get(`${this.baseApiUrl}Reaction/${eventId}`, httpOptions).subscribe((result: Array<StreappReaction>) => {
        resolve(result);
      }, error => {
        reject(error);
      });
    });
  }

  addReaction(eventId: string, reactionText: string, token: string): Promise<StreappReaction> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      });

      var reactionData = {
        'RowKey': eventId,
        'Content': reactionText,
        'Created': new Date(),
        'Modified': new Date(),
        'PreviousReactionValues': []
      };

      this.httpService.post(`${this.baseApiUrl}Reaction`, reactionData, { headers: headers, responseType: 'text' }).subscribe((result) => {
        resolve(JSON.parse(result));
      }), error => {
        reject(error);
      }
    });
  }

  updateReaction(eventId: string, reactionId: string, reactionText: string, token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      });

      var reactionData = {
        'RowKey': eventId,
        'PartitionKey': reactionId,
        'Content': reactionText
      };

      this.httpService.put(`${this.baseApiUrl}Reaction`, reactionData, { headers: headers, responseType: 'text' }).subscribe((result) => {
        resolve();
      }), error => {
        reject(error);
      }
    });
  }

  deleteReaction(eventId: string, reactionId: string, token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      // Headers
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': token
      });

      this.httpService.delete(`${this.baseApiUrl}Reaction?eventId=${eventId}&reactionId=${reactionId}`,
        { headers: headers, responseType: 'text' }).subscribe(() => {
        resolve();
      }), error => {
        reject(error);
      }
    });
  }
}
