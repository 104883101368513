import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    if (!this.authService.isAuthenticated()) {
      this.route.queryParams.subscribe(params => {
        if (params['redirect']) {
          localStorage.setItem('redirect', params['redirect']);
        }
      })
      this.authService.login();
    } else {
        this.route.queryParams.subscribe(params => {
          if (params['redirect']) {
            this.router.navigateByUrl(params['redirect']);
          }
        })
    }
  }
}
