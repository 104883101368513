import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from 'express';

@Component({
  selector: 'app-four04',
  templateUrl: './four04.component.html',
  styleUrls: ['./four04.component.scss']
})
export class Four04Component implements OnInit {

  constructor(@Optional() @Inject('RESPONSE') private response: Response,
            @Inject(PLATFORM_ID) private platformId: Object,
            private readonly router: Router) {}

            
  ngOnInit(){
    if(isPlatformServer(this.platformId)){
        this.response.status(404);
    }

    this.router.navigate(['/']);
  }

}
